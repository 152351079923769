import React from "react";
import "view/style/css/solution.css";
import Nav from "view/parts/nav/nav";

const Ads = () => {
  return (
    <React.Fragment>
      <div className="content-inseide solution">
        <div className="wrap">
          {/* <div className="contentArea">
            <div className="contentTitle">
              <div data-aos="fade-up" data-aos-duration="500">
                SentriX
              </div>
            </div>
          </div>
          <Nav /> */}
          <div className="typing max-width">
            <div
              className="_typing-title  center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img
                src="/images/solution/SentriX_ADS_Black.png"
                style={{ width: "300px" }}
                alt="ads logo"
              />
            </div>
            <div
              className="_typing-text center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              내부 이상 징후 탐지 시스템
            </div>
          </div>
          <div
            className="introBox"
            style={{ padding: "50px 0" }}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="imgScroll">
              <div
                className="wrap esonImage"
                style={{ width: "unset", overflow: "hidden" }}
              >
                <img src="/images/solution/ads_01.jpg" alt="ADS 소개" />
              </div>
            </div>
          </div>
          <div className="typing max-width">
            <div
              className="_typing-title  center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              컴플라이언스를 고려한 기능 설계
            </div>
            <div
              className="_typing-text center"
              data-aos="fade-up"
              data-aos-duration="800"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              <img
                src="/images/solution/SentriX_ADS_Black.png"
                style={{ width: "200px", marginRight: "10px" }}
                alt="ads logo"
              />
              <sapn>는 국내 기업에서 요구하는</sapn>
              <img
                src="/images/solution/ads_item_01.jpg"
                style={{ width: "120px", marginRight: "10px" }}
                alt="ads item"
              />
              <span>인증기준에 부합되는 기능들을 제공합니다.</span>
            </div>
          </div>
          <div
            className="introBox"
            style={{ padding: "50px 0" }}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="imgScroll">
              <div
                className="wrap esonImage"
                style={{ width: "unset", overflow: "hidden" }}
              >
                <img src="/images/solution/ads_02.jpg" alt="ADS 소개" />
              </div>
            </div>
          </div>
          <div className="typing max-width">
            <div
              className="_typing-title  center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              이상행위 및 위협의 구분 탐지 모델
            </div>
            <div
              className="_typing-text center"
              data-aos="fade-up"
              data-aos-duration="800"
              style={{ wordWrap: "break-word" }}
            >
              <img
                src="/images/solution/SentriX_ADS_Black.png"
                style={{ width: "200px", marginRight: "10px" }}
                alt="ads logo"
              />
              는 내부자의 이상행위와 위협을 구분하여 탐지하며 유형별로 탐지된
              이벤트들을
              <br />
              인접 그룹과 타임라인 추적을 통해 추가적인 위협을 찾는 연관성
              분석과 위협헌팅을 할 수 있습니다.
            </div>
          </div>
          <div
            className="introBox"
            style={{ padding: "50px 0" }}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="imgScroll">
              <div
                className="wrap esonImage"
                style={{ width: "unset", overflow: "hidden" }}
              >
                <img src="/images/solution/ads_03.jpg" alt="ADS 소개" />
              </div>
            </div>
          </div>
          <div className="typing max-width">
            <div
              className="_typing-title  center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              자동 결재선
            </div>
            <div
              className="_typing-text center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <img
                src="/images/solution/SentriX_ADS_Black.png"
                style={{ width: "200px", marginRight: "10px" }}
                alt="ads logo"
              />
              는 결재 및 소명을 위해 자동 결재선을 통한 티켓 프로세스를
              적용하였습니다.
            </div>
          </div>
          <div
            className="introBox"
            style={{ padding: "50px 0" }}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="imgScroll">
              <div
                className="wrap esonImage"
                style={{ width: "unset", overflow: "hidden" }}
              >
                <img src="/images/solution/ads_04.jpg" alt="ADS 소개" />
              </div>
            </div>
          </div>
          <div className="typing max-width">
            <div
              className="_typing-title  center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              개인정보보호법을 준수한 티켓 프로세스
            </div>
            <div
              className="_typing-text center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <img
                src="/images/solution/SentriX_ADS_Black.png"
                style={{ width: "200px", marginRight: "10px" }}
                alt="ads logo"
              />
              는 개인정보보호법을 준수한 티켓 프로세스로 보안 사고를 최소화하고
              <br />
              관리자 설정을 통한 유연한 프로세스를 중점적으로 검토하여
              구현하였습니다.
            </div>
          </div>
          <div
            className="introBox"
            style={{ padding: "50px 0" }}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="imgScroll">
              <div
                className="wrap esonImage"
                style={{ width: "unset", overflow: "hidden" }}
              >
                <img src="/images/solution/ads_05.jpg" alt="ADS 소개" />
              </div>
            </div>
          </div>
          <div className="typing max-width">
            <div
              className="_typing-title  center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              위험도 및 고위험군 관리
            </div>
            <div
              className="_typing-text center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <img
                src="/images/solution/SentriX_ADS_Black.png"
                style={{ width: "200px", marginRight: "10px" }}
                alt="ads logo"
              />
              는 사용자정보 기반, 행위기반 위험도를 태그 및 키워드를 통해
              독립적으로 설정할 수 있으며
              <br />
              위험도 상위 사용자 및 그룹의 이상행위 티켓을 별도로 처리할 수
              있습니다.
            </div>
          </div>
          <div
            className="introBox"
            style={{ padding: "50px 0" }}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="imgScroll">
              <div
                className="wrap esonImage"
                style={{ width: "unset", overflow: "hidden" }}
              >
                <img src="/images/solution/ads_06.jpg" alt="ADS 소개" />
              </div>
            </div>
          </div>
          <div className="Link">
            문의처 : <span>sales@gaion.kr</span> (영업팀)
            <br />
            Tel : <span>02.2051.9595</span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Ads;
