import 'swiper/css';
import React from "react";
import "swiper/css/pagination";
import { Pagination } from 'swiper';
import "view/style/css/solution.css";
import Nav from "view/parts/nav/nav";
import { Swiper, SwiperSlide } from 'swiper/react';

function Vision({ props }) {
    return (
        <React.Fragment>
            <div className="content-inseide solution en">
                <div className="wrap">
                    <div className="contentArea">
                        <div className="contentTitle" style={{ "zIndex": "-1", "position": "relative" }}>
                            <div data-aos="fade-up" data-aos-duration="500">gSmart VISION</div>
                        </div>
                    </div>
                    <Nav />
                </div>
                <div className="wrap">
                    <div className="typing">
                        <div className="_typing-title center">
                            gSmart VISION collects and analyzes video<br />
                            Helping you follow up and respond in context<br />
                            It is a surveillance • control platform based on AI visual intelligence.
                        </div>
                        <div className="_typing-text center">
                            Extract necessary information from images collected through CCTVs, drones, cameras, smartphones, etc. and turn them into metadata<br />
                            labeling, AI-based real-time analysis through gSmart VISION's unique object recognition and detection technology,
                            It identifies specific objects or people and detects their actions and situations so that notifications can be delivered or responded to quickly.
                        </div>
                    </div>
                </div>
                <div className="imgScroll">
                    <div className="esonImage">
                        <img src="/images/vision_01.jpg" data-aos="fade-up" data-aos-duration="500" style={{ width: "100%" }} />
                    </div>
                </div>

                {/* 필요성 S */}
                <div className="wrap">
                    <div className="typing">
                        <div className="contentArea bar">
                            <div className="contentTitle" data-aos="fade-up" data-aos-duration="500">Need to introduce gSmart VISION​</div>
                        </div>
                        <div className="_typing-title center" data-aos="fade-up" data-aos-duration="800">
                            Through gSmart VISION, you can manage video security, life, industry, national defense, transportation, disaster prevention, etc.<br />
                            You can prevent incidents and accidents or minimize damage by responding quickly when they occur.
                        </div>
                        <div className="_typing-text center">
                            Object detection technology detects objects of interest (people, objects) in images or videos,
                            You can create deep neural networks capable of real-time object detection and configure AI training programs.​<br /><br />
                            It understands the state of a person through pose estimation technology, detects image changes through segmentation technology,
                            It also supports privacy protection through object analysis technology and blurring.
                        </div>
                    </div>
                </div>
                <div className="introBox">
                    <div className="imgScroll">
                        <div className="esonImage" data-aos="fade-up" data-aos-duration="500">
                            <img src="/images/vision_02.jpg" style={{ width: "100%" }} />
                        </div>
                    </div>
                </div>
                {/* 필요성 E */}

                {/* 차별성 S */}
                <div className="wrap">
                    <div className="typing">
                        <div className="contentArea bar">
                            <div className="contentTitle" data-aos="fade-up" data-aos-duration="500">gSmart VISION's uniqueness​</div>
                        </div>
                        <div className="_typing-title center" data-aos="fade-up" data-aos-duration="800">
                            1. ‘AI-based visual intelligence technology’ with a detection accuracy of 95%
                        </div>
                        <div className="grid two">
                            <div className="_two-item pic_01">
                                <div className="_bg"></div>
                                <div className="_two-title">
                                    A wide range of object detection technology
                                </div>
                                <div className="_two-content">
                                    A technology that detects objects of interest in an image or video<br />
                                    You can detect objects in real time through object detection technology.<br />
                                    Currently, it can detect over 40 types of objects,<br />
                                    It is under development with the goal of recognizing about 80 species in the future.<br />
                                    You can also create deep neural networks based on it.
                                </div>
                            </div>
                            <div className="_two-item pic_02">
                                <div className="_bg"></div>
                                <div className="_two-title">
                                    Detailed Instance Segmentation
                                </div>
                                <div className="_two-content">
                                    Even if an object belongs to the same class (distinction), if it is an independent object,<br />
                                    It is possible to distinguish the level that can be expressed with different colors or labels.<br />
                                    Because all objects are recognized as individual instances<br />
                                    You can also segment the shape by pixel.
                                </div>
                            </div>
                            <div className="_two-item pic_03">
                                <div className="_bg"></div>
                                <div className="_two-title">
                                    Pose Estimation Technology for Anomaly Detection
                                </div>
                                <div className="_two-content">
                                    Estimating the position of a person's joints in an image or video containing a person<br />
                                    It can recognize the posture, and through this, it can understand the condition of the person<br />
                                    It can be linked with a guidance or warning system.<br /><br />
                                </div>
                            </div>
                            <div className="_two-item pic_04">
                                <div className="_bg"></div>
                                <div className="_two-title">
                                    Section segmentation technology for efficient detection
                                </div>
                                <div className="_two-content">
                                    Detect and recognize meaningful changes among the collected image information<br />
                                    These parts can be segmented, binned and collected separately.
                                </div>
                            </div>
                            <div className="_two-item pic_05">
                                <div className="_bg"></div>
                                <div className="_two-title">
                                    Masking technology for privacy protection
                                </div>
                                <div className="_two-content">
                                    Areas that need protection, such as a person's face, in images and videos<br />
                                    It is possible to infer and detect and blur the face,<br />
                                    This prevents unauthorized collection of personal information.
                                </div>
                            </div>
                            <div className="_two-item pic_06">
                                <div className="_bg"></div>
                                <div className="_two-title">
                                    High processing speed and recognition accuracy
                                </div>
                                <div className="_two-content">
                                    World-class 135 fps, 94.2% object recognition processing speed and accuracy<br />
                                    Attribute information learning and recognition performance of 20fps, 45mAP<br />
                                    Multi-object tracking technology performance of MOTA 52%@20fps<br />
                                    80% accuracy of action recognition for 50 actions
                                </div>
                            </div>
                        </div>

                        <div className="_typing-title center" data-aos="fade-up" data-aos-duration="800">
                            2. Integrated real-time surveillance • Control monitoring function
                        </div>
                        <div className="grid two">
                            <div className="_two-item pic_07">
                                <div className="_bg"></div>
                                <div className="_two-title">
                                    Provides detailed video analysis results
                                </div>
                                <div className="_two-content">
                                    Visualization of object detection results such as people and objects<br />
                                    Provide object's annotation data<br />
                                    Blurring effect can be handled by face position and area inference<br />
                                    Posture recognition with real-time human joint estimation
                                </div>
                            </div>
                            <div className="_two-item pic_08">
                                <div className="_bg"></div>
                                <div className="_two-title">
                                    Real-time event occurrence notification for quick response
                                </div>
                                <div className="_two-content">
                                    Efficient monitoring that overcomes the monitoring limitations of control personnel<br />
                                    Provide object tracking and detection results, search results<br />
                                    Safe zone failure detection and abnormal situation event notification<br />
                                    Event notification when a specific action is detected according to the result of analyzing the human posture
                                </div>
                            </div>
                            <div className="_two-item pic_09">
                                <div className="_bg"></div>
                                <div className="_two-title">
                                    Personalized customization settings for users and businesses
                                </div>
                                <div className="_two-content">
                                    Classified data can be collected according to the type and behavior of the object to be analyzed<br />
                                    Block color can be set for each object or type<br />
                                    Upgrade support available for objects that require additional recognition
                                </div>
                            </div>
                            <div className="_two-item pic_10">
                                <div className="_bg"></div>
                                <div className="_two-title">
                                    Control center operation and upgrade support
                                </div>
                                <div className="_two-content">
                                    Real-time simultaneous analysis of 20 channels with one video analysis server<br />
                                    Continuous training support for platform utilization<br />
                                    System utilization detailed manual provided
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                {/* 차별성 E */}

                {/* 도입 효과 S */}
                <div className="wrap">
                    <div className="typing">
                        <div className="contentArea bar">
                            <div className="contentTitle" data-aos="fade-up" data-aos-duration="500">Expected effect of introducing gSmart VISION​​</div>
                        </div>
                        <div className="center">
                            <img src="/images/vision_03.png" style={{ width: "120px", marginTop: "80px" }} />
                        </div>
                        <div className="_typing-title center" data-aos="fade-up" data-aos-duration="800">
                            1. Enhancing the efficiency of monitoring and control services
                        </div>
                        <div className="_typing-text center" data-aos="fade-up" data-aos-duration="800">
                            With gSmart VSION, each person can manage more than 500 channels, more than twice as many as before.<br />
                            You can secure a significant labor cost reduction compared to the previous one while maintaining or strengthening the stability of the surveillance and control service.<br />
                            In addition, the existing infrastructure such as CCTV can be used as it is, so the construction budget can be reduced.
                        </div>

                        <div className="center">
                            <img src="/images/vision_04.png" style={{ width: "120px", marginTop: "80px" }} />
                        </div>
                        <div className="_typing-title center" data-aos="fade-up" data-aos-duration="800">
                            2. Support for securing golden time through rapid emergency response
                        </div>
                        <div className="_typing-text center" data-aos="fade-up" data-aos-duration="800">
                            A notification and message system based on 24-hour real-time surveillance and detection<br />
                            In the event of an emergency, it is possible to check and respond quickly.<br />
                            We support securing golden time to minimize damage.
                        </div>

                        <div className="center">
                            <img src="/images/vision_05.png" style={{ width: "120px", marginTop: "80px" }} />
                        </div>
                        <div className="_typing-title center" data-aos="fade-up" data-aos-duration="800">
                            3. Strengthening effectiveness through selective control
                        </div>
                        <div className="_typing-text center" data-aos="fade-up" data-aos-duration="800">
                            Through AI-based analysis technology, it is possible to selectively control abnormal or suspicious situations.<br />
                            Users can focus a little more on identifying and responding<br />
                            The reliability and accuracy of the overall control work can also be improved.
                        </div>
                    </div>

                    <div className="Link">
                        Contact : <span>sales@gaion.kr</span> (AI Solution Division, Sales Team)<br />
                        Tel : <span>02.2051.9595</span>
                    </div>
                </div>
                {/* 도입 효과 E */}

            </div >
        </React.Fragment >
    );
}

export default Vision;