import "view/style/css/main.css";
import React, { useState, useEffect } from "react";
import ReactFullpage from "@fullpage/react-fullpage";
import cx from "classnames";
import { Link } from "react-router-dom";
/*swiper*/
import { EffectFade, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
/*swiper*/

/*react Icons S*/
import * as Icons from "react-icons/vsc";
/*react Icons E*/
import { Footer } from 'view/parts/footer/footer';

import axios from "axios";
import { useMutation } from "react-query";
import { localDatetimeRenderer, doEllipsis } from "view/utils";
import sanitize from "sanitize-html";
import { useNavigate } from "react-router-dom";

import classNames from 'classnames';
import { Modal } from 'view/parts/modal/modal';
import { Privacy } from 'view/parts/main/privacy';

function Main() {
  const navigate = useNavigate();
  const [isFull, setFull] = useState(null);
  const [noticeList, setNoticeList] = useState(undefined);
  const afterLoad = (origin, destination, direction) => { };

  // const [swiper, setSwiper] = useState();
  const [isSw, setSw] = useState();
  const [open, setOpen] = useState(false);
  const [swiperIndex, setSwiperIndex] = useState(0);
  const [swiperLength, setSwiperLength] = useState(0);
  const [stopProgress, setStopProgress] = useState(false);
  const getNoticeList = async (req) => {
    const response = await axios.post(`${process.env.REACT_APP_API}/serviceCenter/gaionNotice/${req.page}`, req)
    return response
  }
  const mutation = useMutation({
    mutationFn: (req) => getNoticeList(req),
    onSuccess: (result) => {
      setNoticeList(result.data.value);
    }
  })

  useEffect(() => {
    mutation.mutate({ page: 0, value: "4" });
    return () => {
      window.fullpage_api.destroy();
    };
  }, []);

  const MainLoding = (e) => {
    e.autoplay?.stop()
    setStopProgress(true)

    setTimeout(() => {
      e.autoplay?.start()
      setStopProgress(false)
    }, 2000);
  }

  const SwiperActive = (e) => {
    setStopProgress(!stopProgress);
    if (stopProgress == true) {
      e.autoplay?.start()
      return
    }
    if (stopProgress == false) {
      e.autoplay?.stop()
      return
    }
  }

  return (
    <React.Fragment>
      <Modal className={classNames("modal_wrap", { on: open })} title={"Privacy Policy"} open={open} width={1400} setOpen={setOpen} body={<Privacy />} />
      <ReactFullpage
        controlsProps={{ className: "slide-navigation" }}
        licenseKey={process.env.REACT_APP_FULLPAGE_LICENSE}
        scrollingSpeed={1000} /* Options here */
        navigation={true}
        navigationPosition={"left"}
        navigationTooltips={[' INTRO', ' STRENGTH', ' BUSINESS', ' PROCESS', ' NOTICE']}
        showActiveTooltip={true}
        afterLoad={afterLoad}
        responsiveWidth={1000}
        scrollOverflow={true}
        render={({ state, fullpageApi }) => {
          setFull(window.fullpage_api);
          return (
            <React.Fragment>
              <ReactFullpage.Wrapper>
                <div className="section section01">
                  <Swiper
                    modules={[EffectFade, Autoplay]}
                    spaceBetween={0}
                    slidesPerView={1}
                    speed={0}
                    autoplay={{
                      delay: 5000,
                      disableOnInteraction: false,
                      pauseOnMouseEnter: false,
                    }}
                    onSwiper={(swiper) => { MainLoding(swiper); setSw(swiper); }}
                    allowTouchMove={false}
                    loop={true}
                    onSlideChange={(sw) => {
                      setSwiperLength(sw.slides.length - 2);
                      setSwiperIndex(sw.realIndex);

                    }}
                    className="changeBg"
                  >
                    <SwiperSlide>
                      <video data-keepplaying autoPlay loop muted playsInline>
                        <source src="/images/main/intro_1.mp4" type="video/mp4" className="video" />
                      </video>
                      <div className="videoBg"></div>
                      <div className="mainArea">
                        <div className="_maintop">
                          <div className="_mainIcon">
                            <img src="/images/main/people_icon.gif" />
                            <div className="_mainIcon-circle"></div>
                          </div>
                          <hr />
                          <div className="_mainLogo">
                            <img src="/images/main/logo.png" />
                            <div className="_mainLogoAnimation1"></div>
                          </div>
                          <hr />
                          <div className="_mainIcon">
                            <img src="/images/main/ai_icon.gif" />
                            <div className="_mainIcon-circle"></div>
                          </div>
                        </div>

                        <div className="_mainBottom">
                          <div className="_mainBottom-title center-animation"

                          >
                            <span>Link</span> between people & AI
                          </div>
                          <div className="_mainBottom-content center-animation">
                            Gaion is connecting people and AI technology.
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <video data-keepplaying autoPlay loop muted playsInline>
                        <source src="/images/main/intro_2.mp4" type="video/mp4" className="video" />
                      </video>
                      <div className="videoBg"></div>
                      <div className="wrap">
                        {/* content Area S */}
                        <div className="swiperTxt">
                          <div className="_swiper-title">
                            <span>Link</span> with Technological innovation,<br />
                            It becomes a reality with GAION.
                          </div>
                          <hr />
                          <div className="_swiper-txt">
                            Meet cutting-edge technologies such as AI, big data,<br />
                            and drones that seem far away, tailored to your business and needs.
                          </div>
                          {/* arrow Area S */}
                          <div className="swiperBot">
                            <Link to="/company/company">
                              <div className="_swiperBot-text">
                                See details
                              </div>
                              <div className="mainArrow">
                                <div className="_mainArw-bar"></div>
                                <div className="_mainArw-cicle">
                                  <div className="_mainArw-arrow"></div>
                                </div>
                              </div>
                            </Link>
                          </div>
                          {/* arrow Area E */}
                        </div>
                        {/* content Area E */}
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <video data-keepplaying autoPlay loop muted playsInline>
                        <source src="/images/main/intro_3.mp4" type="video/mp4" className="video" />
                      </video>
                      <div className="videoBg"></div>
                      <div className="wrap" style={{ position: "relative", zIndex: "3" }}>
                        {/* content Area S */}
                        <div className="swiperTxt">
                          <div className="_swiper-title">
                            <span>Link</span> with government business,<br />
                            A reliable support business partner
                          </div>
                          <hr />
                          <div className="_swiper-txt">
                            Gaion was recognized for its reliability and technology, and was awarded the National Data Voucher,<br />
                            Selected as an official supplier of AI voucher business for 4 consecutive years.
                          </div>
                          {/* arrow Area S */}
                          <div className="swiperBot">
                            <Link to="/company/company">
                              <div className="_swiperBot-text">
                                See details
                              </div>
                              <div className="mainArrow">
                                <div className="_mainArw-bar"></div>
                                <div className="_mainArw-cicle">
                                  <div className="_mainArw-arrow"></div>
                                </div>
                              </div>
                            </Link>
                            <img src="/images/companyLogo.png" className="mNone" />
                          </div>
                          {/* arrow Area E */}
                        </div>
                        {/* content Area E */}
                      </div>
                    </SwiperSlide>
                  </Swiper>
                  <div className="swiper-progress-container">
                    {new Array(swiperLength).fill(0).map((_, index) => (
                      <React.Fragment key={index}>
                        <div className={cx("swiper-progress-circle", {
                          startAnimation: swiperIndex == index,
                          stop: stopProgress,
                        })}
                        >
                          <div className={cx("swiper-progress-bg", {
                            startAnimation: swiperIndex == index,
                            stop: stopProgress,
                          })}
                          >
                          </div>
                        </div>
                        <div className={cx("swiper-progress", {
                          startAnimation: swiperIndex == index,
                          stop: stopProgress,
                        })}
                          key={index}>
                          <div
                            className={cx("progress", {
                              startAnimation: swiperIndex == index && stopProgress == false,
                              stop: stopProgress,
                              //restart: reStartProgress == true && swiperIndex == index
                            })}
                          ></div>
                        </div>
                        <div className={cx("swiper-progress-num", {
                          startAnimation: swiperIndex == index,
                          stop: stopProgress == true,
                        })}
                        >
                          0{index + 1}
                        </div>
                      </React.Fragment>
                    ))}
                    <div className="swiperPlay"
                      onClick={() => {
                        SwiperActive(isSw)
                      }}

                    >
                      {stopProgress == true && (
                        <React.Fragment>
                          <Icons.VscDebugStart />
                        </React.Fragment>
                      )}
                      {stopProgress == false && (
                        <React.Fragment>
                          <Icons.VscDebugPause />
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </div>
                <div className="section section02">
                  <div className="wrap">
                    <div className="mainTitle">
                      <div className="_main-title">
                        We help <span className="color">maximize</span> performance based on <span className="color">excellent technology</span>.
                      </div>
                    </div>
                    <div className="grid two">
                      <div className="_grid-item">
                        <div className="_item-content">
                          <div className="_content-title color">Recognized Reliability</div>
                          <div className="_content-hr"></div>
                          <div className="_content-text">
                            More than 500 clients including Samsung, Our Home, and Woori Bank<br />
                            Reliable partners such as Electronics and Telecommunications Research Institute, Splunk, Shinhan Card, etc.<br />
                            National AI and Big Data Voucher Provider
                          </div>
                        </div>
                        <div className="_item-icon"><img src="/images/main/section_02_01.gif" /></div>
                      </div>
                      <div className="_grid-item">
                        <div className="_item-content">
                          <div className="_content-title color">outstanding professionalism</div>
                          <div className="_content-hr"></div>
                          <div className="_content-text">
                            Have 11 self-developed patent technologies<br />
                            Transfer of 34 national patent technologies (ETRI)<br />
                            100+ in-house AI and big data experts
                          </div>
                        </div>
                        <div className="_item-icon"><img src="/images/main/section_02_02.gif" /></div>
                      </div>
                      <div className="_grid-item">
                        <div className="_item-content">
                          <div className="_content-title color">Rich know-how</div>
                          <div className="_content-hr"></div>
                          <div className="_content-text">
                            16 years of experience, the longest among technology companies such as big data and AI<br />
                            Expansion of MOU with global leading tech companies<br />
                            Broad technology adaptability, including manufacturing, finance, digital marketing, security, etc.
                          </div>
                        </div>
                        <div className="_item-icon"><img src="/images/main/section_02_03.gif" /></div>
                      </div>
                      <div className="_grid-item">
                        <div className="_item-content">
                          <div className="_content-title color">Endless challenge</div>
                          <div className="_content-hr"></div>
                          <div className="_content-text">
                            AI-based security system, export support, drone infrastructure, etc.<br />
                            Pioneering technology and business areas that benefit people<br />
                            Participated in and lead over 100 national projects
                          </div>
                        </div>
                        <div className="_item-icon"><img src="/images/main/section_02_04.gif" /></div>
                      </div>
                    </div>
                  </div>
                  <div className="bottom_logo">
                    <img src="images/main/companylogo.png" />
                  </div>

                  <div className="bg"></div>
                </div>
                <div className="section section03">
                  <div className="wrap">
                    <div className="mainTitle">
                      <div className="_main-title center">
                        Korea’s No.1 AI & Big Data Concierge, Gaion
                      </div>
                      <div className="_main-content center">
                        <span className="colorGaion bold">GAION</span> is the only company that can effectively integrate<br />
                        and provide customized platforms and solutions such as big data, AI, drones, etc.
                      </div>
                    </div>
                    <div className="grid four">
                      {/* Ai Platforms S */}
                      <div className="_grid-four-item">
                        <div className="_content-title">
                          AI<br />
                          Platforms
                        </div>
                        <div className="_content-hr"></div>
                        <div className="_content-text">
                          Building an AI-based universal technology environment
                        </div>
                        {/* arrow Area S */}
                        <Link to="/platform/ai">
                          <div className="swiperBot">
                            <div className="_swiperBot-text">
                              See details
                            </div>
                            <div className="mainArrow">
                              <div className="_mainArw-bar"></div>
                              <div className="_mainArw-cicle">
                                <div className="_mainArw-arrow"></div>
                              </div>
                            </div>
                          </div>
                        </Link>
                        {/* arrow Area E */}
                      </div>
                      {/* Ai Platforms E */}

                      {/* Ai Solutions S */}
                      <div className="_grid-four-item">
                        <div className="_content-title">AI<br />
                          Solutions</div>
                        <div className="_content-hr"></div>
                        <div className="_content-text">
                          Providing optimal solutions<br />
                          using AI and big data
                        </div>
                        {/* arrow Area S */}
                        <Link to="/bigdata/ads">
                          <div className="swiperBot">
                            <div className="_swiperBot-text">
                              See details
                            </div>
                            <div className="mainArrow">
                              <div className="_mainArw-bar"></div>
                              <div className="_mainArw-cicle">
                                <div className="_mainArw-arrow"></div>
                              </div>
                            </div>
                          </div>
                        </Link>
                        {/* arrow Area E */}
                      </div>
                      {/* Ai Solutions E */}

                      {/* Ai trade S */}
                      <div className="_grid-four-item">
                        <div className="_content-title">AI<br />
                          Trade</div>
                        <div className="_content-hr"></div>
                        <div className="_content-text">
                          Based on AI and big data<br />
                          SME export/import support
                        </div>
                        {/* arrow Area S */}
                        <Link to="/weTrade/weTrade">
                          <div className="swiperBot">
                            <div className="_swiperBot-text">
                              See details
                            </div>
                            <div className="mainArrow">
                              <div className="_mainArw-bar"></div>
                              <div className="_mainArw-cicle">
                                <div className="_mainArw-arrow"></div>
                              </div>
                            </div>
                          </div>
                        </Link>
                        {/* arrow Area E */}
                      </div>
                      {/* Ai trade E */}
                      {/* Ai Logistics S */}
                      <div className="_grid-four-item">
                        <div className="_content-title">AI Logistics<br />
                          &Mobility</div>
                        <div className="_content-hr"></div>
                        <div className="_content-text">
                          For effective drone use<br />
                          AI-based aircraft and SW development
                        </div>
                        {/* arrow Area S */}
                        <Link to="/dron/dron">
                          <div className="swiperBot">
                            <div className="_swiperBot-text">
                              See details
                            </div>
                            <div className="mainArrow">
                              <div className="_mainArw-bar"></div>
                              <div className="_mainArw-cicle">
                                <div className="_mainArw-arrow"></div>
                              </div>
                            </div>
                          </div>
                        </Link>
                        {/* arrow Area E */}
                      </div>
                      {/* Ai Logistics E */}
                    </div>
                  </div>
                  <div className="bg"></div>
                </div>
                <div className="section section04">
                  <div className="wrap">
                    <div className="mainTitle">
                      <div className="_main-title center">
                        Completely connected from market analysis to development and activation
                        Meet GAION’s <span className="color">‘AI technology connection service’</span> right now.
                      </div>
                    </div>
                    <div className="grid special">
                      <div className="_special-item">
                        <div className="_special-area">
                          <div className="_item-btn"><img src="/images/main/section_04_01.gif" /></div>
                          <div className="_item-text">Analyze</div>
                        </div>
                        <div className="_item-hr"><div className="hr"></div></div>
                        <div className="_special-area">
                          <div className="_item-btn"><img src="/images/main/section_04_02.gif" /></div>
                          <div className="_item-text">Design</div>
                        </div>
                        <div className="_item-hr"><div className="hr"></div></div>
                        <div className="_special-area">
                          <div className="_item-btn"><img src="/images/main/section_04_03.gif" /></div>
                          <div className="_item-text">Develop</div>
                        </div>
                        <div className="_item-hr"><div className="hr"></div></div>
                        <div className="_special-area">
                          <div className="_item-btn"><img src="/images/main/section_04_04.gif" /></div>
                          <div className="_item-text">Setup</div>
                        </div>
                        <div className="_item-hr"><div className="hr"></div></div>
                        <div className="_special-area">
                          <div className="_item-btn"><img src="/images/main/section_04_05.gif" /></div>
                          <div className="_item-text">Follow-up</div>
                        </div>
                        <div className="_item-hr"><div className="hr"></div></div>
                      </div>
                      <div className="_special-item">
                        <video data-keepplaying autoPlay loop muted playsInline style={{ width: "100%" }}>
                          <source src="/images/section_04_video.mp4" type="video/mp4" />
                        </video>
                      </div>
                    </div>
                  </div>
                  <div className="bg"></div>
                </div>
                <div className="section section05">
                  <div className="wrap">
                    <div className="mainTitle">
                      <div className="_main-title center">
                        Even at this moment, Gaion<br />
                        We are leaping towards <span className="color">new challenges</span> and <span className="color">innovations.</span>
                      </div>
                    </div>
                    <div className="grid four">
                      {noticeList && noticeList.content.map((element, index) => {
                        if (index < 4) return (
                          <div className="_grid-four-item" key={index}>
                            <div className="_grid-four-top">
                              <div className="_grid-four-type">공지</div>
                              <div className="_grid-four-date">{localDatetimeRenderer(element.created_date, "dateFormat")}</div>
                            </div>
                            <div className="_content-title">
                              {doEllipsis(element.announcement_title, 30)}
                            </div>
                            <div className="_content-hr"></div>
                            <div className="_content-text">
                              <div dangerouslySetInnerHTML={{
                                __html: sanitize(element.announcement_content, {
                                  allowedTags: ["p"],
                                  allowedAttributes: false,
                                })
                              }} />
                            </div>
                            {/* arrow Area S */}
                            <div className="swiperBot">
                              <div className="_swiperBot-text">
                                See details
                              </div>
                              <div className="mainArrow" onClick={() => navigate(`/company/notice/detail`, { state: { item: element, index: 3 } })}>
                                <div className="_mainArw-bar"></div>
                                <div className="_mainArw-cicle">
                                  <div className="_mainArw-arrow"></div>
                                </div>
                              </div>
                            </div>
                            {/* arrow Area E */}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  <div className="bg"></div>
                </div>
                <div className="section footer fp-auto-height">
                  <Footer MainModal={setOpen} locale={"kr"} />
                </div>
              </ReactFullpage.Wrapper>
            </React.Fragment >
          );
        }}
      />
    </React.Fragment >
  );
}

export default Main;
