import React, {
    useState,
    useEffect,
    useRef,
} from "react";
import "view/style/css/intro.css";
import MobileTab from "./MobileTab";
import classNames from "classnames";
import * as Io from "react-icons/io";
import { menuItems } from "./tabList";
import AutoHeight from "react-auto-height";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { localeInfo } from "view/utils";

function Tab(props) {
    const { currentIndex, isChange, propChange } = props;
    const ref1 = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();
    const locale = useRecoilValue(localeInfo);

    const [isDrop, setDrop] = useState(null);
    const [change, setChange] = useState(false);
    const [menuClick, setMenuClick] = useState(location.state?.index);
    const [windowWidth, setWindowWidth] = useState(window.outerWidth);

    const ROOT = document.documentElement;
    ROOT.style.setProperty('--menuClick', menuClick);

    const scrollMove = (e) => {
        const divWidth = document.querySelector(".tab").offsetWidth / menuItems(locale)[currentIndex].list.length;
        ref1.current.scrollTo({ left: e * divWidth, behavior: "smooth" });
    }

    useEffect(() => {
        window.addEventListener("resize", () => {
            setWindowWidth(window.outerWidth);
        })
        return () => {
            window.removeEventListener('resize', null);
        }
    }, [window.outerWidth]);

    useEffect(() => {
        const locationConfirm = location.state?.index ? location.state.index : 0;

        menuItems(locale)[currentIndex].list.map((element) => {
            if (location.pathname.split("/")[2] === element.link.split("/")[2]) {
                const timeSet = setInterval(() => {
                    setChange(true);
                    setMenuClick(locationConfirm)
                    clearInterval(timeSet);
                }, 50)
            }
        })
    }, [location.pathname]);

    return (
        <React.Fragment>
            <div className="background solution"
                style={{ backgroundImage: `url("${menuItems(locale)[currentIndex].bg}")` }}
            >
                <div className="bg_dark"></div>
                <div className="titleArea">
                    <div className="subTitle"
                    >
                        {menuItems(locale)[currentIndex].title !== "AI Logistics" ? menuItems(locale)[currentIndex].title : "AI Logistics & Mobility"}
                    </div>
                    <div className="subContent">
                        {menuItems(locale)[currentIndex].description}
                    </div>
                </div>
            </div>

            {location.pathname != "/company/contact" && (
                <div className="wrap">
                    {windowWidth >= 1200 ? (
                        <div className="scroll" id="tabScroll" ref={ref1}>
                            <div className="tab">
                                <ul className="tabMenu">
                                    {menuItems(locale)[currentIndex].list.map((item, i, self) => (
                                        <React.Fragment key={i}>
                                            <li
                                                style={{ borderRadius: self.length === 1 && "10px" }}
                                                onMouseEnter={() => {
                                                    setDrop(item.key)
                                                }}
                                                onMouseLeave={() => {
                                                    setDrop(null)
                                                }}
                                            >
                                                <Link to={item.link} state={{ index: i, prev: menuClick }}
                                                    className={classNames({
                                                        on: menuClick === i && change
                                                    })}
                                                    onClick={() => {
                                                        setMenuClick(i)
                                                        scrollMove(i);
                                                    }}
                                                >{item.name}
                                                    {item?.menu && (
                                                        <React.Fragment>
                                                            <Io.IoIosArrowDropdownCircle />
                                                        </React.Fragment>
                                                    )}
                                                </Link>
                                                <AutoHeight>
                                                    {item.key === isDrop && item?.menu && (
                                                        <dt>
                                                            {item?.menu?.map((_menu, j) => (
                                                                <React.Fragment key={j}>
                                                                    <dl className={classNames("subMenu",
                                                                        {
                                                                            add: isChange === _menu
                                                                        })}
                                                                        onClick={() => {
                                                                            setDrop(null);
                                                                            propChange(_menu);
                                                                            navigate('/ai/solution', { state: { index: i, prev: menuClick, sub: _menu } });
                                                                        }}
                                                                    >
                                                                        {_menu.name}
                                                                    </dl>
                                                                </React.Fragment>
                                                            ))}
                                                        </dt>
                                                    )}
                                                </AutoHeight>
                                            </li >
                                        </React.Fragment >
                                    ))
                                    }
                                </ul >
                                <div className="tabBorder">
                                    <div className={classNames("tabBg", { move: change })}
                                        style={{ width: `calc((100 / ${menuItems(locale)[currentIndex].list.length}) * 1%)`, transform: `translate(calc(${location.state?.prev} * 100%))` }}
                                    />
                                </div>
                            </div >
                        </div >
                    ) : (
                        <MobileTab currentIndex={currentIndex} />
                    )}
                </div>
            )}
        </React.Fragment >
    );
}

export default Tab;