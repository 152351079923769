import React from "react";
import "view/style/css/intro.css";
import "view/style/css/splunk.css";
import Nav from "view/parts/nav/nav";

function Splunk({ props }) {
  return (
    <React.Fragment>
      <div className="content-inseide splunk">
        <div className="wrap">
          <div className="contentArea">
            <div
              className="contentTitle"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              Splunk
            </div>
          </div>
          <Nav />
          {/* section 01 */}
          <div className="typing">
            <div
              className="_typing-title center"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              <div className="big">머신데이터 분석부터 보안까지</div>
              Splunk가 답을 드립니다.
            </div>
            <div
              className="_typing-text center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              머신데이터를 아무런 제약없이 수집,저장,분석,시각화 할 수 있는
              실시간 분산 플랫폼, Splunk
            </div>

            <div className="introBox">
              <div className="imgScroll">
                <div
                  className="wrap esonImage"
                  data-aos="fade-up"
                  data-aos-duration="800"
                >
                  <img src="/images/splunk_01.png" />
                </div>
              </div>
            </div>
            <div
              className="_typing-text center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              머신데이터 분석부터 보안까지Splunk가 답을 드립니다. 머신데이터를
              아무런 제약없이 수집, 저장, 분석,
              <br />
              시각화 할 수 있는 실시간 분산 플랫폼, Splunk. Splunk는 모든 소스의
              머신 데이터(Machine data)를
              <br />
              실시간으로 수집하고 분석하는 가장 뛰어난 운영 인텔리전스
              플랫폼입니다.
              <br />
              <br />
              사업 분야와 상관없이 모든 장비, 서버, 장치에서 정형 및 비정형
              데이터를 수집합니다. 수집된 데이터를
              <br />
              규칙에 따라 분석하고, 시각화 하여 고객이 활용할 수 있는 객관적인
              지표를 제공합니다. 매일 수십 MB에서
              <br />
              수십 TB까지 규모를 가리지 않고 바로 처리할 수 있고, 이를 통해
              사용자가 필요한 인사이트를 손쉽게
              <br />
              얻을 수 있습니다. 특히 이런 능력은 통합보안관제 시스템에서 위력을
              발휘합니다.
            </div>
            <div className="introBox">
              <div className="imgScroll">
                <div
                  className="wrap esonImage"
                  data-aos="fade-up"
                  data-aos-duration="800"
                >
                  <img src="/images/splunk_02.png" />
                </div>
              </div>
            </div>
            <div className="introBox" style={{ paddingTop: "0" }}>
              <div className="imgScroll">
                <div
                  className="wrap esonImage"
                  data-aos="fade-up"
                  data-aos-duration="800"
                >
                  <img src="/images/splunk_03.png" />
                </div>
              </div>
            </div>
          </div>
          {/* section 01 */}
        </div>
        {/* section 02 */}
        <div className="wrap">
          <div className="typing">
            <div className="contentArea bar">
              <div
                className="contentTitle"
                data-aos="fade-up"
                data-aos-duration="500"
              >
                IT 보안의 정답, Splunk
              </div>
            </div>
          </div>
          <div className="introBox">
            <div className="imgScroll">
              <div
                className="wrap esonImage"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <img src="/images/splunk_04.png" />
              </div>
            </div>
          </div>
          <div
            className="_typing-text center"
            data-aos="fade-up"
            data-aos-duration="800"
          >
            Splunk의 강력함은 보안분야에서 빛을 발합니다. 분석 중심의 SIEM으로
            데이터 유출을 막을 수 있으며,
            <br />
            사용자 및 개체 행동 분석을 통해 알려지지 않은 위협까지 막아냅니다.
            머신러닝을 활용한 위협 탐지의
            <br />
            자동화로 정확한 행동 기반의 경고를 통해 검색 시간 단축과 신속한
            검토, 해결이 가능합니다.
          </div>
        </div>
        {/* section 02 */}
        {/* section 03 */}
        <div className="wrap">
          <div className="typing">
            <div className="contentArea bar">
              <div
                className="contentTitle"
                data-aos="fade-up"
                data-aos-duration="500"
              >
                Splunk 보안 솔루션 여정
              </div>
            </div>
          </div>
          <div className="introBox">
            <div className="imgScroll">
              <div
                className="wrap esonImage"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <img src="/images/splunk_05.png" />
              </div>
            </div>
          </div>
        </div>
        {/* section 03 */}
        {/* section 04 */}
        <div className="wrap">
          <div className="typing">
            <div className="contentArea bar">
              <div
                className="contentTitle"
                data-aos="fade-up"
                data-aos-duration="500"
              >
                Splunk의 최고의 전문가 그룹, 가이온입니다.
              </div>
            </div>
            <div
              className="_typing-title center title-margin first"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              15년의 경험과 노하우로 최적의 솔루션을 제시합니다.
            </div>
            <div
              className="_typing-text center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              국책 은행을 포함한 금융권, 국가 기간 산업 공공기관, 대기업 IT
              시스템 등 수십여 개의 고객사에 Splunk로
              <br />
              정보보안 통합관제 시스템을 성공적으로 구축했습니다.
            </div>
            <div
              className="_typing-title center title-margin"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              Splunk 최우수 엘리트 파트너, 가이온
            </div>
            <div
              className="_typing-text center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              Splunk가 공인한 죄고 기술 자격증을 보유한 다수의 전문가들이
              함께합니다. 다양한 분야에서
              <br />
              통합보안관제 등의 관련 사업을 성공적으로 마무리한 경험을 가진
              Splunk 전문가와 빅데이터,
              <br />
              인공지능, 보안 전문가들이 고객에게 필요를 정확히 파악하고 최적의
              해법을 제시, 실행합니다.
            </div>
            <div
              className="_typing-title center title-margin"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              완성된 기술력, 가이온
            </div>
            <div
              className="_typing-text center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              데이터 전처리부터 각종 빅데이터 분석, 머신 러닝 등 인공지능 기반
              분석 플랫폼을 자체 개발, 출시했고
              <br />
              이 기술을 바탕으로 이상징후를 실시간으로 탐지하는 솔루션을
              개발하여 적용했습니다. 각종 국내
              <br />
              대형 금융사의 차세대 대규모 보안관제 시스템과 아키텍처를 설계하고
              구축 성공을 통해 국내 최고 수준의
              <br />
              기술력을 가지고 있음을 증명하고 있습니다.
            </div>
          </div>
          <div className="imgScroll">
            <div
              className="wrap esonImage"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <img src="/images/splunk_06.png" />
            </div>
          </div>
        </div>
        {/* section 04 */}
        {/* section 05 */}
        <div className="wrap">
          <div className="typing">
            <div className="contentArea bar">
              <div
                className="contentTitle"
                data-aos="fade-up"
                data-aos-duration="500"
              >
                Splunk Core (Enterprise / Cloud)
              </div>
            </div>
            <div
              className="_typing-title center bold"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              흩어진 데이터의 수집과 분석을 한 자리에서
              <br />
              Splunk Core (Enterprise / Cloud)
            </div>
            <div
              className="_typing-text center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              언제, 어떤 형태의 데이터도 빠짐없이 유연하게 수집
            </div>
          </div>
          <div className="imgScroll">
            <div
              className="wrap esonImage"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <img src="/images/splunk_07.png" />
            </div>
          </div>
          <div
            className="_typing-content center"
            data-aos="fade-up"
            data-aos-duration="800"
          >
            IT가 발전하면 할수록 데이터가 들어오는 채널과 종류와 분량은
            폭발적으로 늘어날 수 밖에 없습니다. Splunk Core는
            <br />
            에이전트/에이전트리스 방식으로 언제라도 어떤 채널의 데이터도
            유연하게 수집하여 Splunk 안에서 활용할 수 있습니다.
            <br />
            <br />
            또한 TB, PB의 수준의 초거대 데이터들도 유연한 횡적 확장을 통해
            실시간으로 수집할 수 있습니다.
            <br />
            <br />
            고객의 컴퓨팅 환경에 맞게 온프레미스 방식의 Splunk Enterprise,
            클라우드 방식의 Splunk Cloud 등의
            <br />
            설치 및 운영 형태 최적화도 제공합니다
          </div>
          <div className="introBox" style={{ paddingBottom: "0" }}>
            <div className="imgScroll">
              <div
                className="wrap esonImage"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <img src="/images/splunk_08.png" />
              </div>
            </div>
          </div>
          <div className="typing">
            <div
              className="_typing-text center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              강력한 머신러닝 분석과 시각화로 인사이트를 즉시 도출해 실시간으로
              알려드립니다
            </div>
          </div>
          {/* <div className="introBox" style={{ paddingTop: "0" }}>
                        <div className="imgScroll">
                            <div className="wrap esonImage" data-aos="fade-up" data-aos-duration="800">
                                <img src="/images/splunk_09.png" />
                            </div>
                        </div>
                    </div> */}
          <div
            className="_typing-content center"
            data-aos="fade-up"
            data-aos-duration="800"
          >
            Splunk Core는 머신러닝 기반의 인공지능 분석 뿐 아니라 다양한
            알고리즘과 기법을 활용한 풍부한 분석 기능을 제공합니다.
            <br />
            <br />
            또한 강력한 시각화 도구들과 사용자 중심의 유연한 대시보드를 구축할
            수 있어, 현재 벌어지고 있는 일들이 정확히 무엇을 의미하고
            <br />
            앞으로 어떻게 대응해야할 지에 대한 인사이트를 제공합니다.
            <br />
            <br />
            또한 고객의 필요에 맞게 이메일, SMS, 메신저 등 실시간 알람과 보고서
            작성 기능을 더해 빈틈 없는 서비스를 구현합니다.
            <br />
            <br />
            여기에 2,300여 개의 다양한 앱을 통해 필요한 기능을 얼마든지 추가할
            수 있습니다.
          </div>
          <div className="introBox">
            <div className="imgScroll">
              <div
                className="wrap esonImage"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <img src="/images/splunk_10.png" />
              </div>
            </div>
          </div>
        </div>
        {/* section 05 */}
        {/* section 06 */}
        <div className="wrap">
          <div className="typing">
            <div className="contentArea bar">
              <div
                className="contentTitle"
                data-aos="fade-up"
                data-aos-duration="500"
              >
                Splunk ES
              </div>
            </div>
            <div
              className="_typing-title center title-margin first"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              통합보안의 고민을 해결하는 차세대 SOC의 진수
              <br />
              Splunk ES
            </div>
            <div
              className="_typing-text center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              알려지지 않은 위협까지 식별하는 분석 기반(Analysis-Driven)
              <br />
              SIEM(Security Information & Event Managemen)
            </div>
          </div>
          <div className="imgScroll">
            <div
              className="wrap esonImage"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <img src="/images/splunk_11.png" />
            </div>
          </div>
          <div
            className="_typing-content center"
            data-aos="fade-up"
            data-aos-duration="800"
          >
            기존의 SIEM 들은 각 단위 보안 제품들의 Alert을 수집하여 경고를
            생성하는 이미 ‘알려진(Known)위협’에 대해서만
            <br />
            작동을 하는 경우가 많아 새로운 방식의 공격에 취약합니다.
            <br />
            <br />
            Splunk ES는 모든 보안 관련 데이터를 수집하여 이를 분석하는 분석
            기반(Anlysis-Driven) SIEM으로
            <br />
            ‘알려지지 않은(Unknwon) 위협’ 까지 파악합니다. 이를 통해 기존의 보안
            데이터와 일치하지 않는 위협도 파악해 대응합니다.
            <br />
            <br />
            다양한 보안 경고 연결과 실시간 심층 분석, 즉시 자동 대응까지 한
            플랫폼에서 제공
            <br />
            <br />
            위협 인텔리전스와 상관관계 룰을 구축하여 이를 기반으로 위협을 탐지,
            침해 사고를 분석 처리할 뿐 아니라 자동 대응 까지의 일련의
            <br />
            보안 관제 업무를 단일 플랫폼에서 빠르게 제공합니다. 사용자는 위협의
            발견부터 상황 시각화, 조사케이스 등록, 심층 분석,
            <br />
            즉각 대응까지를 한 눈에 살펴보면서 피해를 최소화할 수 있습니다.
          </div>
          <div className="introBox">
            <div className="imgScroll">
              <div
                className="wrap esonImage"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <img src="/images/splunk_12.png" />
              </div>
            </div>
          </div>
        </div>
        {/* section 06 */}
        {/* section 07 */}
        <div className="wrap">
          <div className="typing">
            <div className="contentArea bar">
              <div
                className="contentTitle"
                data-aos="fade-up"
                data-aos-duration="500"
              >
                가이온 Splunk SOAR
              </div>
            </div>
            <div
              className="_typing-title center title-margin first"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              통합보안관제의 모든 문제를 해결합니다.
              <br />
              가이온 보안 자동화 솔루션
            </div>
            <div
              className="_typing-text center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              가이온 Splunk SOAR
              <br />
              (Security Orchestration, Automaion and Response)
            </div>
          </div>
          <div
            className="_typing-content center"
            data-aos="fade-up"
            data-aos-duration="800"
          >
            가이온 Splunk SOAR가 정답인 이유
          </div>
          <div className="introBox" style={{ paddingBottom: "30px" }}>
            <div className="imgScroll">
              <div
                className="wrap esonImage"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <img src="/images/splunk_12.png" />
              </div>
            </div>
          </div>
          <div
            className="_typing-content center"
            data-aos="fade-up"
            data-aos-duration="800"
          >
            통합보안관제 시스템을 구축, 운영할 때 많은 문제와 만납니다. 때로는
            불필요한 경고가 많이 들어오기도 하고, 보안 운영 인력이
            <br />
            부족해 이슈 발생을 놓치기도 합니다. 늘어나는 상품과 서비스에 제대로
            따라가지 못하기도 하고, 시스템의 속도가 느려 골든타임을
            <br />
            놓치기도 합니다. 반복적인 업무와 프로세스의 부재로 효율적인 운영이
            어려운 경우도 많습니다. 가이온 Splunk SOAR는
            <br />
            이런 고객사의 모든 고민을 해결해 드립니다. 특히 기존의 보안 인프라
            SIEM(Security Information & Event Managemen)과
            <br />
            통합하여 OODA(Observe(관찰) → Orient(상황판단) → Decide(의사결정) →
            Act(실행) Loop를 구축하여 보안 업무 전반을
            <br />
            자동화 합니다. 이를 통해 보다 적은 리소스를 가지고도 빈틈 없는
            통합보안관제 시스템을 구축해 보다 안심할 수 있는
            <br />
            보안 체계를 만들어 드립니다.
          </div>
          <div className="Link">
            문의처 : <span>sales@gaion.kr</span> (영업팀)
            <br />
            Tel : <span>02.2051.9595</span>
          </div>
        </div>
        {/* section 07 */}
      </div>
    </React.Fragment>
  );
}

export default Splunk;
