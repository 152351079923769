import React from "react";
import "view/style/css/solution.css";
import Nav from "view/parts/nav/nav";

const Ads = () => {
  return (
    <React.Fragment>
      <div className="content-inseide solution">
        <div className="wrap">
          {/* <div className="contentArea">
            <div className="contentTitle">
              <div data-aos="fade-up" data-aos-duration="500">
                SentriX
              </div>
            </div>
          </div>
          <Nav /> */}
          <div className="typing max-width">
            <div
              className="_typing-title  center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img
                src="/images/solution/SentriX_ADS_Black.png"
                style={{ width: "300px" }}
                alt="ads logo"
              />
            </div>
            <div
              className="_typing-text center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              Internal abnormal work system
            </div>
          </div>
          <div
            className="introBox"
            style={{ padding: "50px 0" }}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="imgScroll">
              <div
                className="wrap esonImage"
                style={{ width: "unset", overflow: "hidden" }}
              >
                <img src="/images/solution/ads_01.jpg" alt="ADS 소개" />
              </div>
            </div>
          </div>
          <div className="typing max-width">
            <div
              className="_typing-title  center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Functional design with compliance in mind
            </div>
            <div
              className="_typing-text center"
              data-aos="fade-up"
              data-aos-duration="800"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              <img
                src="/images/solution/SentriX_ADS_Black.png"
                style={{ width: "200px", marginRight: "10px" }}
                alt="ads logo"
              />
              <sapn>
                Provides functions that meet the certification standards
                required by domestic companies.
              </sapn>
              <img
                src="/images/solution/ads_item_01.jpg"
                style={{ width: "120px", marginRight: "10px" }}
                alt="ads item"
              />
            </div>
          </div>
          <div
            className="introBox"
            style={{ padding: "50px 0" }}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="imgScroll">
              <div
                className="wrap esonImage"
                style={{ width: "unset", overflow: "hidden" }}
              >
                <img src="/images/solution/ads_02.jpg" alt="ADS 소개" />
              </div>
            </div>
          </div>
          <div className="typing max-width">
            <div
              className="_typing-title  center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Classification detection model for abnormal behavior and threats
            </div>
            <div
              className="_typing-text center"
              data-aos="fade-up"
              data-aos-duration="800"
              style={{ wordWrap: "break-word" }}
            >
              <img
                src="/images/solution/SentriX_ADS_Black.png"
                style={{ width: "200px", marginRight: "10px" }}
                alt="ads logo"
              />
              It detects and distinguishes between abnormal actions and threats
              by insiders, and can perform correlation analysis and threat
              hunting to find additional threats through adjacent groups and
              timeline tracking of events detected by type.
            </div>
          </div>
          <div
            className="introBox"
            style={{ padding: "50px 0" }}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="imgScroll">
              <div
                className="wrap esonImage"
                style={{ width: "unset", overflow: "hidden" }}
              >
                <img src="/images/solution/ads_03.jpg" alt="ADS 소개" />
              </div>
            </div>
          </div>
          <div className="typing max-width">
            <div
              className="_typing-title  center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              automatic approval line
            </div>
            <div
              className="_typing-text center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <img
                src="/images/solution/SentriX_ADS_Black.png"
                style={{ width: "200px", marginRight: "10px" }}
                alt="ads logo"
              />
              has applied a ticket process through an automatic approval line
              for payment and explanation.
            </div>
          </div>
          <div
            className="introBox"
            style={{ padding: "50px 0" }}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="imgScroll">
              <div
                className="wrap esonImage"
                style={{ width: "unset", overflow: "hidden" }}
              >
                <img src="/images/solution/ads_04.jpg" alt="ADS 소개" />
              </div>
            </div>
          </div>
          <div className="typing max-width">
            <div
              className="_typing-title  center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Ticket process compliant with personal information protection laws
            </div>
            <div
              className="_typing-text center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <img
                src="/images/solution/SentriX_ADS_Black.png"
                style={{ width: "200px", marginRight: "10px" }}
                alt="ads logo"
              />
              has minimized security incidents through a ticket process that
              complies with the Personal Information Protection Act and has
              focused on reviewing and implementing a flexible process through
              administrator settings.
            </div>
          </div>
          <div
            className="introBox"
            style={{ padding: "50px 0" }}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="imgScroll">
              <div
                className="wrap esonImage"
                style={{ width: "unset", overflow: "hidden" }}
              >
                <img src="/images/solution/ads_05.jpg" alt="ADS 소개" />
              </div>
            </div>
          </div>
          <div className="typing max-width">
            <div
              className="_typing-title  center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Risk and high-risk group management
            </div>
            <div
              className="_typing-text center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <img
                src="/images/solution/SentriX_ADS_Black.png"
                style={{ width: "200px", marginRight: "10px" }}
                alt="ads logo"
              />
              User information-based and behavior-based risk levels can be
              independently set through tags and keywords, and abnormal behavior
              tickets for high-risk users and groups can be processed
              separately.
            </div>
          </div>
          <div
            className="introBox"
            style={{ padding: "50px 0" }}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="imgScroll">
              <div
                className="wrap esonImage"
                style={{ width: "unset", overflow: "hidden" }}
              >
                <img src="/images/solution/ads_06.jpg" alt="ADS 소개" />
              </div>
            </div>
          </div>
          <div className="Link">
            Contact: <span>sales@gaion.kr</span> (Sales Team)
            <br />
            Tel : <span>02.2051.9595</span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Ads;
