import React, { useState, useCallback, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import logo from 'view/images/logo@2x.png'

function Loding() {

    const [isLoding, setLoding] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (location.pathname == "/") {
            setLoding(true);
            setTimeout(() => {
                setLoding(false);
            }, 2300);
        }
        return () => {

        };
    }, [location.pathname]);

    return (
        <React.Fragment>
            {isLoding === true && (
                <>
                    <div className="loading">
                    </div>
                    <div className="circle_Area">
                        <div className="circle left"></div>
                        <img className="circleLogo" src={logo} alt="logo" />
                        <div className="circle right"></div>
                    </div>
                </>
            )}
        </React.Fragment>
    );
}

export default Loding;