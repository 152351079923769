import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

/*page S*/
import Main from './view/pages/kr/main';
import Sub1 from './view/pages/kr/sub1/index';
import Sub2 from './view/pages/kr/sub2/index';
import Sub3 from './view/pages/kr/sub3/index';
import Sub4 from './view/pages/kr/sub4/index';
import Sub5 from './view/pages/kr/sub5/index';
import Sub6 from './view/pages/kr/sub6/index';
import MainEn from './view/pages/en/main';
import SubEn1 from './view/pages/en/sub1/index';
import SubEn2 from './view/pages/en/sub2/index';
import SubEn3 from './view/pages/en/sub3/index';
import SubEn4 from './view/pages/en/sub4/index';
import SubEn5 from './view/pages/en/sub5/index';
import SubEn6 from './view/pages/en/sub6/index';
import NotFound from "./view/pages/error/notfound";
/*page S*/
/*style S*/
import "view/style/css/font.css";
import "view/style/css/common.css";
import { RecoilRoot } from 'recoil';
import "view/style/css/subCommon.css";
import Header from 'view/parts/header/header';
import Loading from 'view/parts/main/loding';
import { Footer } from 'view/parts/footer/footer';
import FixedMenu from 'view/parts/fixedMenu/fixedMenu';
import { ScrollToTop } from 'view/parts/scrollToTop/scrollToTop';
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query';

const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient({
  queryCache: new QueryCache({
    defaultOptions: {
      queries: {
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
      }
    }
  })
});

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <BrowserRouter>
          <ScrollToTop />
          <React.Fragment>
            <section className='DefaultFont'>
              <Loading />
              <Header />
              <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/company/*" element={<Sub1 />} />
                <Route path="/platform/*" element={<Sub2 />} />
                <Route path="/bigdata/*" element={<Sub3 />} />
                <Route path="/wetrade/*" element={<Sub4 />} />
                <Route path="/dron/*" element={<Sub5 />} />
                <Route path="/voucher/*" element={<Sub6 />} />
                <Route path="/platform/voucher" element={<Sub6 />} />
                <Route path="/en" element={<MainEn />} />
                <Route path="/en/company/*" element={<SubEn1 />} />
                <Route path="/en/platform/*" element={<SubEn2 />} />
                <Route path="/en/bigdata/*" element={<SubEn3 />} />
                <Route path="/en/wetrade/*" element={<SubEn4 />} />
                <Route path="/en/dron/*" element={<SubEn5 />} />
                <Route path="/en/voucher/*" element={<SubEn6 />} />
                <Route path="/en/platform/voucher" element={<SubEn6 />} />
                <Route path="/*" element={<NotFound />} />
              </Routes>
              <FixedMenu />
              <Footer />
            </section>
          </React.Fragment>
        </BrowserRouter>
      </RecoilRoot>
    </QueryClientProvider>
  </React.StrictMode>
);
