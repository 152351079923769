import React, { useState, useCallback, useEffect, useRef } from "react";
import "view/style/css/notfound.css";
import _404 from './404';
import Wave from 'react-wavify'
function Login({ layout }) {
    return (
        <React.Fragment>
            <div className="content-inseide">
                <div className="lostPage">
                    <div className="wrap">
                        <div className="center">
                            <div className="_lost-title">
                                <_404 />
                                <div className="_404-bg">
                                    <Wave fill='#f79902'
                                        paused={false}
                                        options={{
                                            height: 40,
                                            amplitude: 15,
                                            speed: 0.4,
                                            points: 5
                                        }}
                                        className="wave"
                                    />
                                </div>
                            </div>
                            <div className="_lost-content">페이지를 찾을수 없습니다.</div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}

export default Login;