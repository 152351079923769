import AOS from "aos";
import "aos/dist/aos.css";
import Wetrade from "./wetrade";
import Tab from "view/parts/tab/tab";
import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import NotFound from "view/pages/error/notfound";

function Index() {

    useEffect(() => {
        AOS.init();
    })

    return (
        <React.Fragment>
            <Tab currentIndex={3} />
            <Routes>
                <Route path="/wetrade" element={<Wetrade />} />
                <Route path="/*" element={<NotFound />} />
            </Routes>
        </React.Fragment>
    );
}

export default Index;