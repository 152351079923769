import React from "react";
import "view/style/css/solution.css";
import Nav from "view/parts/nav/nav";

function Dron({ props }) {
    return (
        <React.Fragment>
            <div className="content-inseide solution">
                <div className="wrap">
                    <div className="contentArea">
                        <div className="contentTitle">
                            <div data-aos="fade-up" data-aos-duration="500">the Dron</div>
                        </div>
                    </div>
                    <Nav />

                    <div className="typing">
                        <div className="_typing-title center" data-aos="fade-up" data-aos-duration="500">
                            the Dron은 물류 사각지대에 택배 및 구급약품을 배달하는 드론을 위한<br />
                            운영관리 및 관제 시스템 입니다.
                        </div>

                        <div className="_typing-text center" data-aos="fade-up" data-aos-duration="800">
                            저희 가이온에서는, 4차 물류 패러다임 변화에 따른 신사업 모델로 취약지역 및 도심 퀵 서비스 대체용 드론 배송 서비스 플랫폼 구축을<br />
                            수행하고 있습니다.<br /><br />
                            운영관리 시스템은 배달할 물품 관리, 드론 정보를 관리, 드론의 이/착륙 지점인 배달점을 관리하는 기능과 물품, 드론, 배달점을<br />연결해서
                            배달 미션을 관리하고 관제에 전달해 주는 배달 요청 기능을 제공합니다. 또한 운영관제 시스템은 배달 미션을 수신하고<br /> 드론의 비행 경로를
                            생성하며, 드론의 상태와 비행 정보를 모니터링하는 기능을 제공합니다.
                        </div>
                    </div>
                </div>
                {/* section 02 S */}
                <div className="grayBox ">
                    <div className="wrap">
                        <div className="center">
                            <img src="/images/dron_01.png" className="droncenter" />
                        </div>
                        <div className="sharkGrid dron">
                            <div className="_sharkItem">
                                <img src="/images/dron_02.png" data-aos="fade-up" data-aos-duration="200" />
                            </div>
                            <div className="_sharkItem">
                                <img src="/images/dron_03.png" data-aos="fade-up" data-aos-duration="300" />
                            </div>
                            <div className="_sharkItem">
                                <img src="/images/dron_04.png" data-aos="fade-up" data-aos-duration="400" />
                            </div>
                            <div className="_sharkItem">
                                <img src="/images/dron_05.png" data-aos="fade-up" data-aos-duration="500" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* section 02 E */}
                {/* section 03 S */}
                <div className="wrap">
                    <div className="typing">
                        <div className="contentArea bar">
                            <div className="contentTitle" data-aos="fade-up" data-aos-duration="1000">편리하고 신속한 드론 택배 배송 체계 구축</div>
                        </div>
                        <div className="introBox">
                            <div className="imgScroll">
                                <div className="wrap esonImage" >
                                    <img src="/images/dron_06.png" data-aos="fade-up" data-aos-duration="500" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* section 03 E */}
                {/* section 04 S */}
                <div className="wrap">
                    <div className="typing">
                        <div className="contentArea bar">
                            <div className="contentTitle" data-aos="fade-up" data-aos-duration="1000">드론을 통한 물류 인프라 구축 </div>
                        </div>
                        <div className="introBox">
                            <div className="imgScroll">
                                <div className="wrap esonImage" >
                                    <img src="/images/dron_07.png" data-aos="fade-up" data-aos-duration="500" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* section 04 E */}
            </div >
        </React.Fragment >
    );
}

export default Dron;