import React from "react";
import "view/style/css/solution.css";
import Nav from "view/parts/nav/nav";

function Cato({ props }) {
  return (
    <React.Fragment>
      <div className="content-inseide solution">
        <div className="wrap">
          <div className="contentArea">
            <div className="contentTitle">
              <div data-aos="fade-up" data-aos-duration="500">
                Cato Networks
              </div>
            </div>
          </div>
          <Nav />
          <div className="typing max-width">
            <div
              className="_typing-title center title-margin first"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              Cato Cloud : Global Private Backbone Network & PoPs
            </div>
            <div
              className="_typing-text center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              사업환경이 빠르게 디지털화 되고 있습니다.
              <br />
              온프레미스 또는 클라우드 상의 응용프로그램과 접속해야 하고,
              <br />
              늘어나는 모바일 사용자 지원도 해야합니다.
              <br />
              <br />
              기존의 전용망(MLPS : Multi-Level Protection Scheme)과 보안장비로
              구성된 기존의
              <br />
              네트워크는 이렇게 변화하는 상황에 기민하고 능동적이며 유연하게
              대처하기 어렵습니다.
              <br />
              또한 초기 구축 비용과 운영 비용 또한 부담이 되는 게 현실입니다
              <br />
              <br />
              <br />
              Cato Network는 그런 모든 불안과 고민을 해결해드립니다.
              <br />
              자체적으로 구축한 글로벌 프라이빗 백본망은 안정적이며 유연한
              네트워크 서비스를 제공합니다.
              <br />
              특히 해외에 지사를 두고 있다면 Cato Networks의
              <br />
              <br />
              서비스가 모든 문제의 최고의 대안이 될 수 있습니다
            </div>
            <div className="introBox" style={{ paddingBottom: 0 }}>
              <div className="imgScroll">
                <div className="wrap esonImage">
                  <img src="/images/cato_01.jpg" />
                </div>
              </div>
            </div>
            <div className="introBox" style={{ paddingTop: 0 }}>
              <div className="imgScroll">
                <div className="wrap esonImage">
                  <img src="/images/cato_02.jpg" />
                </div>
              </div>
            </div>
            <div
              className="_typing-title center title-margin"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              Cato Cloud : The World’s First SASE platform
            </div>
            <div
              className="_typing-text center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              Cato는 세계 최초로 구축한 SASE 플랫폼을 기반으로
              <br />
              서비스로의 보안(Security as a Service)를 통합적으로 제공합니다.
              <br />
              따로 구매하거나 설치해야하는 어플라이언스는 필요없습니다.
              <br />
              <br />
              응용 프로그램을 인지하는 차세대 방화벽(NGFW),
              <br />
              URL 필터링이 되는 보안 웹게이트웨이(SWG),
              <br />
              표준 및 차세대 안티-멀웨어와 Cato SOC 에서 제공하는 IPS까지
              <br />
              Cato의 글로벌 프라이빗 백본 네트워크에 구축된 서비스형 보안을
              사용하시면
              <br />
              바로 사용하실 수 있습니다.
              <br />
              <br />
              모든 엔진은 완벽한 확장성을 가지고 있으며, 고객이 어플라이언스나
              포인트 솔루션을 업그레이드하거나 패치하실 필요가 없습니다.
              <br />
              네트워크와 보안을 클라우드에서 통합하는 Cato의 서비스는
              <br />
              여러분의 네트워크와 보안에 대한 걱정과 고민에 충분한 해결책이
              됩니다.
            </div>
            <div className="introBox">
              <div className="imgScroll">
                <div className="wrap esonImage">
                  <img src="/images/cato_03.jpg" />
                </div>
              </div>
            </div>
          </div>

          <div className="Link">
            문의처 : <span>sales@gaion.kr</span> (영업팀)
            <br />
            Tel : <span>02.2051.9595</span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Cato;
