import 'swiper/css';
import React from "react";
import "swiper/css/pagination";
import "view/style/css/solution.css";
import Nav from "view/parts/nav/nav";
import { ArrowButton } from "view/component/arrowBtn";

function Vision() {

    return (
        <React.Fragment>
            <div className="content-inseide solution">
                <div className="wrap">
                    <div className="contentArea">
                        <div className="contentTitle" style={{ "zIndex": "-1", "position": "relative" }}>
                            <div data-aos="fade-up" data-aos-duration="500">gSmart BRAIN</div>
                        </div>
                    </div>
                    <Nav />
                </div>
                <div className="wrap">
                    <div className="typing">
                        <div className="_typing-title center">
                            gSmart BRAIN은 언어를 이해하고 지식을 학습하여 지식을 서비스하는 AI 기술 입니다.
                        </div>
                        <div className="_typing-text center">
                            복잡한 인간 언어의 뜻을 분석하고, 문맥을 이해하며, 의미관계를 정확하게 파악해<br />
                            이를 바탕으로 사용자와 질의응답 및 대화가 가능한 소통환경을 만들어줍니다.
                        </div>
                    </div>
                </div>
                <div className="introBox" style={{ padding: "0" }}>
                    <div className="imgScroll">
                        <div className="esonImage" data-aos="fade-up" data-aos-duration="500">
                            <img src="/images/brain/brain_01.jpg" />
                        </div>
                    </div>
                </div>
                <div className="wrap">
                    <div className="typing">
                        <div className="_typing-title center">
                            gSmart BRAIN은 자연어의 단어로 이루어진 어휘, 의미를 담고 있는 문장은 물론<br />
                            생략이나 상호 참조하는 전체 언어의 맥락인 문맥까지도 이해할 수 있습니다.
                        </div>
                        <div className="_typing-text center">
                            기본적인 Symbol Preprocessing, Word Spacing, Sentence Boundary Recognition 등의 과정을 통해<br />
                            초당 약 300개의 의존구문을 처리 분석할 수 있으며,<br />
                            단위 품사 1,378,374개와 복합명사 1,320,495개를 구분하고 형태소를 분석할 수 있습니다.<br />
                            <br />
                            개체명 또한 146개의 인식 태그를 통해 전체 문장의 인식율을 최대화하는 문장단위로 최적화하고,<br />
                            위키백과/사용자 정의 신규 개체명까지 확장 시켜 인식시킬 수 있습니다.<br />
                        </div>
                    </div>
                </div>
                <div className="introBox" style={{ padding: "0" }}>
                    <div className="imgScroll">
                        <div className="esonImage" data-aos="fade-up" data-aos-duration="500">
                            <img src="/images/brain/brain_02.jpg" />
                        </div>
                    </div>
                </div>




                <div className="wrap">
                    <div className="typing">
                        <div className="contentArea bar">
                            <div className="contentTitle" data-aos="fade-up" data-aos-duration="500">gSmart BRAIN 도입의 필요성</div>
                        </div>

                        <div className="_typing-title center">
                            gSmart BRAIN은 법률, 특허, 의료,보험 등 높은 전문성과<br />
                            다량의 정보를 필요로 하는 분야에서 적절한 내용을 탐색 및 분석하고<br />
                            제공하는 과정의 정확성과 효율성을 극대화 할 수 있습니다.
                        </div>
                    </div>
                </div>

                <div className="introBox" style={{ padding: "0" }}>
                    <div className="imgScroll">
                        <div className="wrap esonImage" data-aos="fade-up" data-aos-duration="500">
                            <img src="/images/brain/brain_03.jpg" />
                        </div>
                    </div>
                </div>

                <div className="wrap">

                    <div className="_typing-title center">
                        특히 24시간 상담이 필요한 고객상담이나 응대,<br />
                        정보와 지식 제공 서비스에 효과적으로 활용되고 있습니다.
                    </div>
                    <div className="_typing-text center">
                        gSmart BRAIN은 자연어에 대한 심층이해를 바탕으로 지식을 학습하고 재생산할 수 있으며,<br />
                        우리가 일상적으로 사용하는 자연어로 질의응답을 가능하게 해줍니다.
                    </div>
                </div>

                <div className="wrap">
                    <div className="typing">
                        <div className="contentArea bar">
                            <div className="contentTitle" data-aos="fade-up" data-aos-duration="500">gSmart BRAIN만의 차별점​</div>
                        </div>

                        <div className="_typing-title center">
                            1. 한국어 특화 비지도 학습 기반, ‘한국어 언어모델(KorBERT)’ 적용
                        </div>
                        <div className="_typing-text center">
                            언어모델(LM, Language Mode)은 단어 시퀀스(문장)에 확률을 할당하는 모델로 자연어 처리를 위한 핵심 기술입니다.<br />
                            gSmart BRAIN에 활용된 KorBERT 언어모델은 라벨링 된 학습데이터가 필요 없는<br />
                            비지도 학습 기반 모델이라는 점이 큰 장점입니다.<br />
                            <br />
                            또한, 해외에서 개발된 언어모델과는 다르게 한국어에 특화되어 개발된 모델로<br />
                            한국어 이해 정확성이나 독해능력, 분류 능력이 해외에서 개발된 언어모델보다 평균 5% 이상 뛰어납니다.<br />

                        </div>
                    </div>

                    <div className="introBox" style={{ padding: "0" }}>
                        <div className="imgScroll">
                            <div className="wrap esonImage" data-aos="fade-up" data-aos-duration="500">
                                <img src="/images/brain/brain_05.png" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="wrap" style={{ marginTop: "120px" }}>
                    <div className="typing">
                        <div className="_typing-title center">
                            2. 전문 분야 한국어 ‘심층 질의응답 기술’ 탑재
                        </div>
                        <div className="_typing-text center">
                            특정 태스크를 학습한 모델을 활용해 다른 태스크에 재사용 하는 트랜스퍼 러닝(Transfer Learning)기술을 통해<br />
                            유사질문을 효과적으로 분석하고 응용하는 능력이 뛰어납니다.<br />
                            <br />

                            이 기술 덕분에 gSmart BRAIN은 질문의 유형이나 표현의 방식과는 상관없이<br />
                            필요 정보를 빠르게 탐색하고,정확한 응답을 제공할 수 있습니다.

                        </div>
                    </div>

                    <div className="introBox" style={{ padding: "0" }}>
                        <div className="imgScroll">
                            <div className="wrap esonImage" data-aos="fade-up" data-aos-duration="500">
                                <img src="/images/brain/brain_04.png" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="wrap">
                    <div className="typing">
                        <div className="contentArea bar">
                            <div className="contentTitle" data-aos="fade-up" data-aos-duration="500">gSmart BRAIN 도입의 기대효과</div>
                        </div>
                        <div className="center">
                            <img src="/images/brain/brain_06.png" style={{ width: "120px", marginTop: "80px" }} />
                        </div>
                        <div className="_typing-title center">
                            1. 한국형 AI 기술 개발의 기반 기술로 활용 가능
                        </div>
                        <div className="_typing-text center">
                            조사, 어미 변형이 다양한 한국어에 맞춤화 된 언어모델에 기반해<br />
                            향후 국내에서 활용될 서비스나 제품에 응용될 수 있는 범위가 넓습니다.<br /><br />
                            특히 gSmart BRAIN은 심화된 질의응답을 위한 한국어 이해, 추론, 분석 역량이 뛰어납니다.
                        </div>
                        <div className="center">
                            <img src="/images/brain/brain_07.png" style={{ width: "120px", marginTop: "80px" }} />
                        </div>
                        <div className="_typing-title center">
                            2. 정보 제공의 효율성과 효과성 제고
                        </div>
                        <div className="_typing-text center">
                            기억력의 한계를 뛰어넘는 방대한 양의 정보에 기반한 결론을 제시하기 때문에<br />
                            제공 정보의 정확성을 높일 수 있으며, 실수를 예방할 수 있습니다.<br /><br />
                            정보 제공의 과정도 사람보다 훨씬 빠르기 때문에 정보 검색  및 제공의 속도 또한 가속화할 수 있습니다.
                        </div>
                        <div className="center">
                            <img src="/images/brain/brain_08.png" style={{ width: "120px", marginTop: "80px" }} />
                        </div>
                        <div className="_typing-title center">
                            3. 시간, 비용, 인력 낭비 최소화
                        </div>
                        <div className="_typing-text center">
                            챗봇, FAQ 등에 활용함으로써 비용을 절약할 수 있으며,<br />
                            타사 AI 모델과는 달리 라벨링이 필요 없는 모델을 활용하기 때문에<br />
                            라벨링에 필요한 시간, 비용, 인력도 절약할 수 있습니다.
                        </div>
                    </div>

                    <div className="Link">
                        문의처 : <span>sales@gaion.kr</span> (AI솔루션부문, 영업팀)<br />
                        Tel :  <span>02.2051.9595</span>
                    </div>

                </div>

            </div>
        </React.Fragment >
    );
}

export default Vision;