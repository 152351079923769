import React from "react";
import "view/style/css/solution.css";
import Nav from "view/parts/nav/nav";
import * as IconHi from "react-icons/hi";
import { ArrowButton } from "view/component/arrowBtn";

function Wetrade({ props }) {
    return (
        <React.Fragment>
            <div className="content-inseide solution">
                <div className="wrap">

                    <div className="right">
                        <a href="https://we-trade.co.kr/" target={"_blank"}
                            style={{ fontSize: "22px", fontWeight: "600", color: "#000" }}
                        ><IconHi.HiHome style={{ fontSize: "25px", marginRight: 10, verticalAlign: "sub" }} />위트레이드 바로가기</a>
                    </div>

                    <div className="contentArea">
                        <div className="contentTitle">
                            <div data-aos="fade-up" data-aos-duration="500">WeTrade</div>
                        </div>
                    </div>
                    <Nav />
                    <div className="typing">
                        <div className="_typing-title center" data-aos="fade-up" data-aos-duration="1000">
                            오프라인 전시회와 연계한 O2O 수출입 플랫폼
                        </div>
                        <div className="_typing-text center" data-aos="fade-up" data-aos-duration="1000" >
                            해외 전시회 참가 통해 Buyer 데이터 구축 및 바이어 매칭
                        </div>
                    </div>

                    <div className="introBox" style={{ padding: "0" }} >
                        <div className="imgScroll">
                            <div className="wrap esonImage">
                                <img src="/images/wetrade_05.png" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* step 2 S */}
                <div className="grayBox">
                    <div className="wrap">
                        <div className="typing">
                            <div className="contentArea bar">
                                <div className="contentTitle" data-aos="fade-up" data-aos-duration="500" style={{ marginTop: "0" }}>AI 기반 수출입 플랫폼
                                </div>
                            </div>
                            <div className="_typing-title center" data-aos="fade-up" data-aos-duration="1000">
                                회원사의 업무량을 최소화 하는 가장 편하고 쉬운 거래지원 기능 제공
                            </div>
                            <div className="_typing-text center" data-aos="fade-up" data-aos-duration="1000" >
                                중소, 중견기업의 수출입 활동을 지원하기 위한 최적의 기능을 제공합니다.
                            </div>

                        </div>
                        <div className="grid five" >
                            <div className="_five-item">
                                <img src="/images/wetrade/wetrade_retouch_01.png" />
                                <div className="_five-text">
                                    <div className="_five-title">
                                        기업별 소개<br />페이지 제공
                                    </div>
                                    <div className="_five-content">
                                        판매기업은 별도의<br />회사 소개용 페이지 제공
                                    </div>
                                </div>
                            </div>
                            <div className="_five-item">
                                <img src="/images/wetrade/wetrade_retouch_02.png" />
                                <div className="_five-text">
                                    <div className="_five-title">매칭 알고리즘<br />제공</div>
                                    <div className="_five-content">회원이 가입될 때 마다 매칭 알고리즘을 통해 새로운 매칭 데이터 제공</div>
                                </div>
                            </div>
                            <div className="_five-item">
                                <img src="/images/wetrade/wetrade_retouch_03.png" />
                                <div className="_five-text">
                                    <div className="_five-title">CS업무<br /><br /></div>
                                    <div className="_five-content">통합 CS센터 운영을<br />통해 회원사 업무 저감</div>
                                </div>
                            </div>
                            <div className="_five-item">
                                <img src="/images/wetrade/wetrade_retouch_04.png" />
                                <div className="_five-text">
                                    <div className="_five-title">최신 데이터<br />제공</div>
                                    <div className="_five-content">상품정보 갱신, RFQ 등록 시<br />실시간으로 매칭 정보 제공</div>
                                </div>
                            </div>
                            <div className="_five-item">
                                <img src="/images/wetrade/wetrade_retouch_05.png" />
                                <div className="_five-text">
                                    <div className="_five-title">판매사<br />홍보</div>
                                    <div className="_five-content">매칭  Buyer에게 대량 메일<br />자동발송 기능 제공</div>
                                </div>
                            </div>
                            <div className="_five-item">
                                <img src="/images/wetrade/wetrade_retouch_06.png" />
                                <div className="_five-text">
                                    <div className="_five-title">통관/배송<br />업무 대행</div>
                                    <div className="_five-content">전문 기업과의 연계를  통한<br />간편한 수출입서비스</div>
                                </div>
                            </div>
                            <div className="_five-item">
                                <img src="/images/wetrade/wetrade_retouch_07.png" />
                                <div className="_five-text">
                                    <div className="_five-title">마케팅 지원<br /><br /></div>
                                    <div className="_five-content">해외 전시회, Buyer 설명회 등을<br />통한 회원사 홍보기회 제공</div>
                                </div>
                            </div>
                            <div className="_five-item">
                                <img src="/images/wetrade/wetrade_retouch_08.png" />
                                <div className="_five-text">
                                    <div className="_five-title">EVENT<br /><br /></div>
                                    <div className="_five-content">회원사별 이벤트  기능<br />제공으로  다양한  판촉 지원</div>
                                </div>
                            </div>
                            <div className="_five-item">
                                <img src="/images/wetrade/wetrade_retouch_09.png" />
                                <div className="_five-text">
                                    <div className="_five-title">3’rd Party <br />연계</div>
                                    <div className="_five-content">편의성 증대를 위한 외부 전문<br />업체소싱을 통한 편의기능 제공</div>
                                </div>
                            </div>
                            <div className="_five-item">
                                <img src="/images/wetrade/wetrade_retouch_10.png" />
                                <div className="_five-text">
                                    <div className="_five-title">판매&정산<br />시스템</div>
                                    <div className="_five-content">체계적인 판매&정산시스템으로<br />간편한 관리 가능</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* step 2 E */}


                {/* step 3 S */}
                <div className="wrap">

                </div>
                {/* step 3 E */}


                {/* step 4 S */}
                <div className="wrap">
                    <div className="typing">
                        <div className="contentArea bar">
                            <div className="contentTitle" data-aos="fade-up" data-aos-duration="500">가장 높은 사용자 편의성을 제공하는 수출입 플랫폼</div>
                        </div>
                        <div className="_typing-text center" data-aos="fade-up" data-aos-duration="1000" >
                            ETRI의 AI 솔루션과 최신 기을 접목하여 탄력적인 플랫폼 기능 확장이 가능하도록 설계
                        </div>
                    </div>
                    <div className="introBox" >
                        <div className="imgScroll">
                            <div className="wrap esonImage">
                                <img src="/images/wetrade_section_03.png" />
                            </div>
                        </div>
                    </div>

                    <div className="blueBox" >
                        <div className="_blue-left">
                            <div className="_blue-title">
                                WeTrade에 상품을 등록하고, 전 세계 바이어들과 만나보세요!
                            </div>
                            <div className="_blue-content">
                                WeTrade는 전세계의 셀러와 바이어를 연결해주고, 한번의 상품 등록으로 해외/국내<br />
                                오픈마켓에서 상품을 판매할 수 있도록 지원하고 있습니다.
                            </div>
                            <ArrowButton title={"제품 문의하기"} link={{ link: "/company/contact", state: { index: 2, prev: 1 } }} />
                        </div>
                        <div className="_blue-right">
                            <img src="/images/wetrade_02.png" />
                        </div>
                    </div>

                </div>
                {/* step 4 E */}
                {/* step 5 S */}
                <div className="wrap">
                    <div className="typing">
                        <div className="contentArea bar">
                            <div className="contentTitle" data-aos="fade-up" data-aos-duration="500">
                                회원사의 업무량을 최소화 하는 맞춤형 탐색 알고리즘 적용
                            </div>
                        </div>
                        <div className="_typing-text center" data-aos="fade-up" data-aos-duration="1000" >
                            3.5억개 실거래 데이터(B/L)와 200만개 해외기업 정보를 활용한<br />유력 거래 가망 기업정보 추천으로 상품/기업 탐색시간을 최소화
                        </div>
                    </div>
                    <div className="introBox" >
                        <div className="imgScroll">
                            <div className="wrap esonImage">
                                <img src="/images/wetrade_section_04.png" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* step 5 E */}
                {/* step 6 S */}
                <div className="wrap">
                    <div className="typing">
                        <div className="contentArea bar">
                            <div className="contentTitle" data-aos="fade-up" data-aos-duration="500">AI 기반의 Image Detection 기술을 도입하여 검색 편의성 및 큐레이션 개선
                            </div>
                        </div>
                        <div className="_typing-text center" data-aos="fade-up" data-aos-duration="1000" >
                            상품 촬영 시 수출 상품의 시장 리포트를 제공하고,<br />객체 탐지 기술 기반 이미지 검색 기능을 통해 플랫폼 내에서 유사 상품 추천
                        </div>
                    </div>
                    <div className="introBox" >
                        <div className="imgScroll">
                            <div className="wrap esonImage">
                                <img src="/images/wetrade_section_05.png" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* step 6 E */}
                {/* step 7 S */}
                <div className="wrap">
                    <div className="typing">
                        <div className="contentArea bar">
                            <div className="contentTitle" data-aos="fade-up" data-aos-duration="500">
                                XR기술이 추가된 시스템으로 현장감 있고 구체적인 협상 지원
                            </div>
                        </div>
                        <div className="_typing-text center" data-aos="fade-up" data-aos-duration="1000" >
                            Twin Reality를 활용한 상품 속성/작동성능 소개 및 다양한 커뮤니티를 기능을 제공하여<br />실제 제품을 보고 상담하는 것과 동일한 수준의 협상 환경 제공
                        </div>
                    </div>
                    <div className="introBox" >
                        <div className="imgScroll">
                            <div className="wrap esonImage">
                                <img src="/images/wetrade_section_06.png" />
                            </div>
                        </div>
                    </div>
                    <div className="typing" style={{ display: "flex", justifyContent: "center", gap: 75 }}>
                        <ArrowButton title={"위트레이드 바로가기"} link={"https://we-trade.co.kr/"} />
                        <ArrowButton title={"위트레이드몰 바로가기"} link={"https://www.wetrademall.com/"} />
                    </div>
                </div>
                {/* step 7 E */}
            </div >
        </React.Fragment >
    );
}

export default Wetrade;
