import 'swiper/css';
import React from "react";
import "swiper/css/pagination";
import "view/style/css/solution.css";
import Nav from "view/parts/nav/nav";
import { ArrowButton } from "view/component/arrowBtn";

function Vision() {

    return (
        <React.Fragment>
            <div className="content-inseide solution en">
                <div className="wrap">
                    <div className="contentArea">
                        <div className="contentTitle" style={{ "zIndex": "-1", "position": "relative" }}>
                            <div data-aos="fade-up" data-aos-duration="500">gSmart BRAIN</div>
                        </div>
                    </div>
                    <Nav />
                </div>
                <div className="wrap">
                    <div className="typing">
                        <div className="_typing-title center">
                            gSmart BRAIN is an AI technology that provides knowledge service by understanding language and learning knowledge.
                        </div>
                        <div className="_typing-text center">
                            Analyze the meaning of complex human language, understand the context, and accurately grasp the semantic relationship<br />
                            Based on this, it creates a communication environment where Q&A and conversation with users are possible.
                        </div>
                    </div>
                </div>
                <div className="introBox" style={{ padding: "0" }}>
                    <div className="imgScroll">
                        <div className="esonImage" data-aos="fade-up" data-aos-duration="500">
                            <img src="/images/brain/brain_01.jpg" />
                        </div>
                    </div>
                </div>
                <div className="wrap">
                    <div className="typing">
                        <div className="_typing-title center">
                            gSmart BRAIN provides vocabulary consisting of words in natural language, sentences containing meaning, as well as<br />
                            You can even understand the context, the context of the entire language that you cross-reference or omit.
                        </div>
                        <div className="_typing-text center">
                            Through the process of basic Symbol Preprocessing, Word Spacing, and Sentence Boundary Recognition<br />
                            It can process and analyze about 300 dependencies per second,<br />
                            You can classify 1,378,374 unit parts of speech and 1,320,495 compound nouns and analyze their morphemes.<br />
                            <br />
                            Object names are also optimized in sentence units that maximize the recognition rate of the entire sentence through 146 recognition tags,<br />
                            It can be recognized by extending Wikipedia/user-defined new entity names.<br />
                        </div>
                    </div>
                </div>
                <div className="introBox" style={{ padding: "0" }}>
                    <div className="imgScroll">
                        <div className="esonImage" data-aos="fade-up" data-aos-duration="500">
                            <img src="/images/brain/brain_02.jpg" />
                        </div>
                    </div>
                </div>




                <div className="wrap">
                    <div className="typing">
                        <div className="contentArea bar">
                            <div className="contentTitle" data-aos="fade-up" data-aos-duration="500">Need to introduce gSmart BRAIN</div>
                        </div>

                        <div className="_typing-title center">
                            gSmart BRAIN is specialized in law, patent, medical, insurance, etc.<br />
                            Explore and analyze appropriate content in fields that require a large amount of information<br />
                            You can maximize the accuracy and efficiency of the process you provide.
                        </div>
                    </div>
                </div>

                <div className="introBox" style={{ padding: "0" }}>
                    <div className="imgScroll">
                        <div className="wrap esonImage" data-aos="fade-up" data-aos-duration="500">
                            <img src="/images/brain/brain_03.jpg" />
                        </div>
                    </div>
                </div>

                <div className="wrap">

                    <div className="_typing-title center">
                        In particular, customer consultation or response that requires 24-hour consultation,<br />
                        It is effectively used for information and knowledge provision services.
                    </div>
                    <div className="_typing-text center">
                        gSmart BRAIN can learn and reproduce knowledge based on an in-depth understanding of natural language,<br />
                        It enables Q&A in the natural language we use on a daily basis.
                    </div>
                </div>

                <div className="wrap">
                    <div className="typing">
                        <div className="contentArea bar">
                            <div className="contentTitle" data-aos="fade-up" data-aos-duration="500">gSmart BRAIN's uniqueness​</div>
                        </div>

                        <div className="_typing-title center">
                            1. Application of the Korean language model (KorBERT), based on Korean language-specific unsupervised learning
                        </div>
                        <div className="_typing-text center">
                            A language model (LM, Language Mode) is a model that assigns probabilities to word sequences (sentences) and is a key technology for natural language processing.<br />
                            The KorBERT language model used in gSmart BRAIN does not require labeled training data<br />
                            Its great advantage is that it is an unsupervised learning-based model.<br />
                            <br />
                            In addition, unlike language models developed overseas, it is a model developed specifically for Korean.<br />
                            Korean language comprehension accuracy, reading comprehension ability, and classification ability are on average more than 5% better than language models developed overseas.<br />

                        </div>
                    </div>

                    <div className="introBox" style={{ padding: "0" }}>
                        <div className="imgScroll">
                            <div className="wrap esonImage" data-aos="fade-up" data-aos-duration="500">
                                <img src="/images/brain/brain_05.png" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="wrap" style={{ marginTop: "120px" }}>
                    <div className="typing">
                        <div className="_typing-title center">
                            2. Equipped with ‘in-depth question and answer technology’ in Korean language
                        </div>
                        <div className="_typing-text center">
                            Through transfer learning technology that utilizes a model that has learned a specific task and reuses it for other tasks<br />
                            The ability to effectively analyze and apply similar questions is excellent.<br />
                            <br />

                            Thanks to this technology, gSmart BRAIN does not care about the type of question or how it is expressed<br />
                            You can quickly search for the information you need and provide accurate responses.

                        </div>
                    </div>

                    <div className="introBox" style={{ padding: "0" }}>
                        <div className="imgScroll">
                            <div className="wrap esonImage" data-aos="fade-up" data-aos-duration="500">
                                <img src="/images/brain/brain_04.png" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="wrap">
                    <div className="typing">
                        <div className="contentArea bar">
                            <div className="contentTitle" data-aos="fade-up" data-aos-duration="500">Expected effects of introducing gSmart BRAIN</div>
                        </div>
                        <div className="center">
                            <img src="/images/brain/brain_06.png" style={{ width: "120px", marginTop: "80px" }} />
                        </div>
                        <div className="_typing-title center">
                            1. Can be used as a base technology for developing Korean AI technology
                        </div>
                        <div className="_typing-text center">
                            Based on a language model customized for Korean with various investigations and ending variations<br />
                            There is a wide range of applications that can be applied to services or products that will be used in Korea in the future.<br /><br />
                            In particular, gSmart BRAIN has excellent Korean language comprehension, reasoning, and analysis capabilities for in-depth Q&A.
                        </div>
                        <div className="center">
                            <img src="/images/brain/brain_07.png" style={{ width: "120px", marginTop: "80px" }} />
                        </div>
                        <div className="_typing-title center">
                            2. Improving the efficiency and effectiveness of information provision
                        </div>
                        <div className="_typing-text center">
                            <br />
                            You can increase the accuracy of the information you provide and prevent mistakes.<br /><br />
                            Since the process of providing information is also much faster than humans, the speed of information retrieval and provision can also be accelerated.
                        </div>
                        <div className="center">
                            <img src="/images/brain/brain_08.png" style={{ width: "120px", marginTop: "80px" }} />
                        </div>
                        <div className="_typing-title center">
                            3. Minimize waste of time, money and manpower
                        </div>
                        <div className="_typing-text center">
                            You can save money by using chatbots, FAQs, etc.<br />
                            Unlike other AI models, it uses a model that does not require labeling<br />
                            You can also save time, money and manpower required for labeling.
                        </div>
                    </div>

                    <div className="Link">
                        Contact : <span>sales@gaion.kr</span> (AI Solution Division, Sales Team)<br />
                        Tel : <span>02.2051.9595</span>
                    </div>

                </div>

            </div>
        </React.Fragment >
    );
}

export default Vision;