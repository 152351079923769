import "view/style/css/intro.css";
import Nav from 'view/parts/nav/nav';
import React, { useEffect, useState } from 'react';

export function CEO(props) {
    const [windowWidth, setWindowWidth] = useState(window.outerWidth);

    useEffect(() => {
        window.addEventListener("resize", () => {
            setWindowWidth(window.outerWidth);
        })
        return () => {
            window.removeEventListener('resize', null);
        }
    }, [window.outerWidth]);

    return (
        <React.Fragment>
            <div className="content-inseide overview en">
                {/* section 01 S */}
                <div className="wrap">
                    <div className="contentArea" data-aos="fade-up">
                        <div className="contentTitle">CEO's Greeting</div>
                    </div>
                    <Nav />
                    <div className="typing">
                        <div className="_typing-title center" data-aos="fade-up" data-aos-duration="500" >
                            <div className='typing_quot'>
                                <img src="/images/d_quot.svg" alt="quot" />
                                <span>Effective performance innovation with <strong>AI, big data, drones, etc., </strong><br /> Gaon is enough.</span>
                                <img src="/images/d_quot_end.svg" alt="quot" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* section 01 E */}
                {/* section 02 S */}
                <div className="wrap" style={{ position: "relative" }}>
                    <div className="ceo_intro_box">
                        <img className='ceo_img' src="/images/ceo_img.png" alt="ceoImg" style={{ transform: windowWidth <= 1400 && `translateX(-${(1400 - windowWidth) / 150 * 5}%)` }} />
                        <div className='ceo_intro'>
                            <img src="/images/logo@2x.png" alt="gaionLogo" />
                            <span>CEO Leeon</span>
                            <span style={{ fontWeight: 600, margin: "20px 0" }}>Kang Hyun-seop</span>
                            <div className='ceo_history'>
                                <div className="_circleWhite" />
                                <span data-aos="fade-up" data-aos-duration="500"> Graduated from Seoul National University's Department of Social Education</span>
                                <span data-aos="fade-up" data-aos-duration="500">Seoul National University Business School MBA completion</span>
                                <div className="_circleWhite" />
                                <span data-aos="fade-up" data-aos-duration="500">Data Services Specialist</span>
                                <span data-aos="fade-up" data-aos-duration="500">20 years of trade-related work experience</span>
                                <span data-aos="fade-up" data-aos-duration="500">Top financial security course</span>
                                <span data-aos="fade-up" data-aos-duration="500">AI Top Course</span>
                                <span data-aos="fade-up" data-aos-duration="500">KAIST CEO Course</span>
                                <span data-aos="fade-up" data-aos-duration="500">Seoul National University CEO Course</span>
                                <span data-aos="fade-up" data-aos-duration="500">SNU Biosystem Course</span>
                                <span data-aos="fade-up" data-aos-duration="500">Seoul National University FIP Course</span>
                                <span data-aos="fade-up" data-aos-duration="500">Sogang University IPO Practice MINIMBA</span>
                                <span data-aos="fade-up" data-aos-duration="500"> Sogang University Funding Consulting Course</span>
                                <div className="_circleWhite" />
                            </div>
                        </div>
                    </div>
                    <div className='ceo_shade_box' />
                </div>
                {/* section 02 E */}
                {/* section 03 S */}
                <div className='wrap' style={{ marginTop: 150 }}>
                    <div className='ceo_letter_wrap' data-aos="fade-in" data-aos-duration="500">
                        <span>The value of new technologies such as big data and AI is endless.</span>
                        <span>However, even the best technology is useless if it is not used properly.</span>
                        <span>This is easy to use, comfortable, and effective for all businesses and people.</span>
                        <span>Develops and supports platforms and solutions tailored to use technology</span>
                        <span style={{ marginBottom: 30 }}>Services are being provided.</span>
                        <span>To date, Gaion aims to become the nation's leading technology service company.</span>
                        <span>We have been taking strong steps, and AI, big data, drone delivery, etc.</span>
                        <span>We boldly challenged the commercialization of technologies that are essential for a better human life in the future.</span>
                        <span>Thanks to that, abundant technology and know-how in big data, AI, drones, etc.,</span>
                        <span style={{ marginBottom: 30 }}>I was able to gain unemployment and experience, and I am proudly recognized as one of the best AI and big data companies in the country.</span>
                        <span>Now Gaion is for the global market beyond Korea.</span>
                        <span>We strive to develop and support technology.</span>
                        <span>Give a lot of support and attention to Gaion's leap to make a better world,</span>
                        <span style={{ marginBottom: 30 }}>And please join us.</span>
                        <span style={{ marginBottom: 60 }}>Thank you.</span>
                        <span className='letter_value'>value<br /> followed by <br /> that shines onnuri </span>
                        <p data-aos="fade-up" data-aos-duration="500">Gaon Co., Ltd.</p>
                        <span data-aos="fade-up" data-aos-duration="500">CEO Kang Hyun-seop</span>
                    </div>
                </div>
                {/* section 03 E */}
            </div >
        </React.Fragment>
    );
}