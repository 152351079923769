import "view/style/css/intro.css";
import Nav from 'view/parts/nav/nav';
import React, { useEffect, useState } from 'react';

export function CEO(props) {
    const [windowWidth, setWindowWidth] = useState(window.outerWidth);

    useEffect(() => {
        window.addEventListener("resize", () => {
            setWindowWidth(window.outerWidth);
        })
        return () => {
            window.removeEventListener('resize', null);
        }
    }, [window.outerWidth]);

    return (
        <React.Fragment>
            <div className="content-inseide overview">
                {/* section 01 S */}
                <div className="wrap">
                    <div className="contentArea" data-aos="fade-up">
                        <div className="contentTitle">CEO's Greeting</div>
                    </div>
                    <Nav />
                    <div className="typing">
                        <div className="_typing-title center" data-aos="fade-up" data-aos-duration="500" >
                            <div className='typing_quot'>
                                <img src="/images/d_quot.svg" alt="quot" />
                                <span>AI, 빅데이터, 드론 등 <strong>첨단 기술을 통한 효과적 성과혁신,</strong><br /> 가이온과 함께 라면 충분히 가능합니다.</span>
                                <img src="/images/d_quot_end.svg" alt="quot" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* section 01 E */}
                {/* section 02 S */}
                <div className="wrap" style={{ position: "relative" }}>
                    <div className="ceo_intro_box">
                        <img className='ceo_img' src="/images/ceo_img.png" alt="ceoImg" style={{ transform: windowWidth <= 1400 && `translateX(-${(1400 - windowWidth) / 150 * 5}%)` }} />
                        <div className='ceo_intro'>
                            <img src="/images/logo@2x.png" alt="gaionLogo" />
                            <span>가이온 대표이사</span>
                            <span style={{ fontWeight: 600, margin: "20px 0" }}>강현섭</span>
                            <div className='ceo_history'>
                                <div className="_circleWhite" />
                                <span data-aos="fade-up" data-aos-duration="500">서울대학교 사회교육과 졸업</span>
                                <span data-aos="fade-up" data-aos-duration="500">서울대학교 경영대학원 MBA 수료</span>
                                <div className="_circleWhite" />
                                <span data-aos="fade-up" data-aos-duration="500">데이터서비스 전문가</span>
                                <span data-aos="fade-up" data-aos-duration="500">무역관련 업무 경력 20년</span>
                                <span data-aos="fade-up" data-aos-duration="500">금융보안 최고위 과정</span>
                                <span data-aos="fade-up" data-aos-duration="500">AI 최고위 과정</span>
                                <span data-aos="fade-up" data-aos-duration="500">KAIST 최고경영자 과정</span>
                                <span data-aos="fade-up" data-aos-duration="500">서울대학교 최고경영자 과정</span>
                                <span data-aos="fade-up" data-aos-duration="500">서울대 바이오 시스템 과정</span>
                                <span data-aos="fade-up" data-aos-duration="500">서울대 FIP 과정</span>
                                <span data-aos="fade-up" data-aos-duration="500">서강대 IPO 실무 MINI MBA</span>
                                <span data-aos="fade-up" data-aos-duration="500">서강대 자금조달 컨설팅 과정</span>
                                <div className="_circleWhite" />
                            </div>
                        </div>
                    </div>
                    <div className='ceo_shade_box' />
                </div>
                {/* section 02 E */}
                {/* section 03 S */}
                <div className='wrap' style={{ marginTop: 150 }}>
                    <div className='ceo_letter_wrap' data-aos="fade-in" data-aos-duration="500">
                        <span>빅데이터와 AI 등 새로운 기술의 활용 가치는 무궁무진합니다.</span>
                        <span>하지만, 아무리 좋은 기술이라도 제대로 활용하지 못하면 무용지물입니다.</span>
                        <span>가이온은 모든 기업과 사람이 쓰기 쉽고 편하면서도 효과적으로</span>
                        <span>기술을 사용할 수 있도록 맞춤화 된 플랫폼과 솔루션을 개발 및 지원하는</span>
                        <span style={{ marginBottom: 30 }}>서비스를 제공하고 있습니다.</span>
                        <span>지금까지 가이온은 국내 최고의 기술 서비스 전문 기업이 되겠다는 담대한 포부를 향해</span>
                        <span>힘찬 발걸음을 이어 왔으며, AI, 빅데이터, 드론 배송 등</span>
                        <span>앞으로 더 나은 인간의 삶을 위해 꼭 필요한 기술의 상용화에 과감히 도전했습니다.</span>
                        <span>그 덕분에 빅데이터, AI, 드론 등의 분야에서 풍부한 기술력과 노하우,</span>
                        <span style={{ marginBottom: 30 }}>실직과 경험을 쌓을 수 있었고, 국내 최고의 AI 및 빅데이터 기업 중 하나로 당당히 인정받고 있습니다.</span>
                        <span>이제 가이온은 국내를 넘어 세계 시장을 위한</span>
                        <span>기술 개발과 지원을 위해 노력하고 있습니다.</span>
                        <span>더 나은 세상을 만들기 위한 가이온의 도약에 많은 성원과 관심을,</span>
                        <span style={{ marginBottom: 30 }}>그리고 함께 해주시기를 바랍니다.</span>
                        <span style={{ marginBottom: 60 }}>감사합니다.</span>
                        <span className='letter_value'>가치를<br />이어<br />온누리에 빛나게 하는</span>
                        <p data-aos="fade-up" data-aos-duration="500">주식회사 가이온</p>
                        <span data-aos="fade-up" data-aos-duration="500">대표이사 강현섭</span>
                    </div>
                </div>
                {/* section 03 E */}
            </div >
        </React.Fragment>
    );
}