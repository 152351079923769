import React from 'react';
import classNames from 'classnames';
import "view/style/css/arrowBtn.css";
import { useNavigate } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';

export function ArrowButton(props) {
    const { title, backgroundColor, border, margin, link, isLoading = false } = props;
    const navigate = useNavigate();
    const ROOT = document.documentElement;
    ROOT.style.setProperty('--background', backgroundColor ? backgroundColor : "#1F2A80");
    ROOT.style.setProperty('--borderRad', border ? border : "10px");
    ROOT.style.setProperty('--margin', margin ? margin : 0);

    const [ref1, inView1] = useInView({ threshold: 0.9, });

    const onClickButton = () => {
        switch (typeof link) {
            case "string": window.open(link, '_blank', 'noopener,noreferrer'); break;
            case "object": navigate(link.link, { state: link.state }); break;
            case "function": link(); break;
        }
    }

    return (
        <div className="_blue-btn" >
            <button onClick={onClickButton}>
                <div className="_btn-text">{title}</div>
                {!isLoading ? (
                    <div
                        ref={ref1}
                        className={classNames("_btn-anmation", {
                            add: inView1 == true
                        })}
                    >
                        <div className="_btn-bar"></div>
                        <div className="_btn-circle">
                            <div className="_circle-arrow"></div>
                        </div>
                    </div>
                ) : (
                    <img style={{width: 20, marginLeft: 25}} src={"/images/main/loading.gif"} alt="Loading" />
                )}
            </button>
        </div>
    );
}