import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"

let TOAST_TEXT = [];
export const showToast = (text, color = "black", duration = 3000, link, newtab = false) => {
    if (TOAST_TEXT.indexOf(text) < 0) {
        TOAST_TEXT.push(text);
        const _this = Toastify({
            text: text,
            duration: duration,
            newWindow: false,
            className: "info" + (!!color ? " " + color : ""),
            newWindow: true,
            gravity: "top", //"bottom",
            position: "center", //"left",
            stopOnFocus: true, // Prevents dismissing of toast on hover
            onClick: function (e) {
                _this.hideToast();
            }, // Callback after click
            callback: () => {
                if (link != undefined && newtab != true) {
                    window.location.assign(link)
                }

                if (link != undefined && newtab == true) {
                    window.open(link);
                }
            },
        }).showToast();
        TOAST_TEXT = [];
        return _this;
    } else {
        return null
    }

}