import React from "react";
import "view/style/css/solution.css";
import Nav from "view/parts/nav/nav";

function Dron({ props }) {
    return (
        <React.Fragment>
            <div className="content-inseide solution en">
                <div className="wrap">
                    <div className="contentArea">
                        <div className="contentTitle">
                            <div data-aos="fade-up" data-aos-duration="500">the Dron</div>
                        </div>
                    </div>
                    <Nav />
                    <div className="typing">
                        <div className="_typing-title center" data-aos="fade-up" data-aos-duration="500">
                            the Dron is a drone that delivers parcels and first-aid medicines to logistics blind spots.<br />
                            It is an operation management and control system.
                        </div>
                        <div className="_typing-text center" data-aos="fade-up" data-aos-duration="800">
                            At Gaion, we are building a drone delivery service platform for alternative quick service in vulnerable areas and downtown as a new business model<br />
                            following the 4th logistics paradigm change.<br /><br />
                            The operation management system manages items to be delivered, manages drone information, and manages the delivery point, which is the take-off/landing point of the drone,<br />
                            as well as items, drones, and delivery points. It provides a delivery request function that connects and manages delivery missions and delivers them to control. In addition,<br />
                            the operation control system receives the delivery mission and It creates a drone flight path and provides a function to monitor drone status and flight information.
                        </div>
                    </div>
                </div>
                {/* section 02 S */}
                <div className="grayBox ">
                    <div className="wrap">
                        <div className="center">
                            <img src="/images/dron_01.png" className="droncenter" />
                        </div>
                        <div className="sharkGrid dron">
                            <div className="_sharkItem">
                                <img src="/images/dron_02.png" data-aos="fade-up" data-aos-duration="200" />
                            </div>
                            <div className="_sharkItem">
                                <img src="/images/dron_03.png" data-aos="fade-up" data-aos-duration="300" />
                            </div>
                            <div className="_sharkItem">
                                <img src="/images/dron_04.png" data-aos="fade-up" data-aos-duration="400" />
                            </div>
                            <div className="_sharkItem">
                                <img src="/images/dron_05.png" data-aos="fade-up" data-aos-duration="500" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* section 02 E */}
                {/* section 03 S */}
                <div className="wrap">
                    <div className="typing">
                        <div className="contentArea bar">
                            <div className="contentTitle" data-aos="fade-up" data-aos-duration="1000">Establishment of a convenient and rapid drone delivery system</div>
                        </div>
                        <div className="introBox">
                            <div className="imgScroll">
                                <div className="wrap esonImage" >
                                    <img src="/images/dron_06.png" data-aos="fade-up" data-aos-duration="500" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* section 03 E */}
                {/* section 04 S */}
                <div className="wrap">
                    <div className="typing">
                        <div className="contentArea bar">
                            <div className="contentTitle" data-aos="fade-up" data-aos-duration="1000">Construction of logistics infrastructure through drones</div>
                        </div>
                        <div className="introBox">
                            <div className="imgScroll">
                                <div className="wrap esonImage" >
                                    <img src="/images/dron_07.png" data-aos="fade-up" data-aos-duration="500" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* section 04 E */}
            </div >
        </React.Fragment >
    );
}

export default Dron;