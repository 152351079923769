import AOS from "aos";
import Dron from "./dron";
import "aos/dist/aos.css";
import Tab from "view/parts/tab/tab";

import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import NotFound from "view/pages/error/notfound";

function Index() {


    useEffect(() => {
        AOS.init();
    })

    return (
        <React.Fragment>
            <Tab currentIndex={4} />
            <Routes>
                <Route path="/dron" element={<Dron />} />
                <Route path="/*" element={<NotFound />} />
            </Routes>
        </React.Fragment>
    );
}

export default Index;