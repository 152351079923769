import React from "react";
import "view/style/css/solution.css";
import Nav from "view/parts/nav/nav";

function Ai({ props }) {
    return (
        <React.Fragment>
            <div className="content-inseide solution en">
                <div className="wrap">
                    <div className="contentArea">
                        <div className="contentTitle">
                            <div data-aos="fade-up" data-aos-duration="500">gSmart AI</div>
                        </div>
                    </div>
                    <Nav />
                    <div className="typing">
                        <div className="_typing-title center" data-aos="fade-up" data-aos-duration="500">
                            gSmart AI can discover hidden patterns in data and perform predictive analysis<br />
                            It is an AI-based analytics platform.
                        </div>

                        <div className="_typing-text center" data-aos="fade-up" data-aos-duration="800">
                            Data-based decision-making is necessary to have sustainable competitiveness in an ever-complex and diversified business environment.
                            Whether it's customer experience, user monitoring, threat and fraud analysis issues, you can gain business insights from data and its analytics.
                            gSmart AI collects all the information needed for forecasting/analysis and makes more precise and accurate forecasts due to this intelligence process
                            Provided to decision makers.
                            gSmart AI provides optimal analysis/prediction that meets customer conditions through various analysis techniques.
                        </div>
                    </div>
                </div>
                {/* section 02 S */}
                <div className="grayBox" >
                    <div className="imgScroll">
                        <div className="wrap esonImage" data-aos="fade-up" data-aos-duration="500">
                            <img src="/images/gsmartai_01.png" />
                        </div>
                    </div>
                </div>
                {/* section 02 E */}
                {/* section 03 S */}
                <div className="wrap">
                    <div className="typing">
                        <div className="contentArea bar">
                            <div className="contentTitle" data-aos="fade-up" data-aos-duration="1000">gSmartAI 기대효과</div>
                        </div>

                        <div className="_typing-title center" data-aos="fade-up" data-aos-duration="1200">
                            gSmart AI is based on existing data, data pre-processing, learning model creation, learning quality evaluation, <br />
                            It creates, recommends, and simulates new rules through detection performance and prediction accuracy-based feedback steps.<br />
                            You can make data-driven decisions through gSmart AI optimized for prediction/analysis.
                        </div>
                    </div>
                    <div className="imgScroll">
                        <div className="wrap esonImage" >
                            <img src="/images/gsmartai_02.png" data-aos="fade-up" data-aos-duration="500" />
                        </div>
                    </div>

                    <div className="Link">
                        Contact : <span>sales@gaion.kr</span> (AI Solution Division, Sales Team)<br />
                        Tel : <span>02.2051.9595</span>
                    </div>

                </div>
                {/* section 03 E */}
            </div >
        </React.Fragment >
    );
}

export default Ai;