import React, { useState, useCallback, useEffect, useRef } from "react";
import "view/style/css/intro.css";
import Nav from "view/parts/nav/nav";

/*swiper*/
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/pagination";
import { Map, MapMarker } from "react-kakao-maps-sdk";
/*swiper*/

import Map1 from 'view/parts/map/map1';
import Map2 from 'view/parts/map/map2';
import Map3 from 'view/parts/map/map3';
import classNames from "classnames";

function Intro() {

    const [isMap, setMap] = useState(0);

    return (
        <React.Fragment>
            <div className="content-inseide overview en">
                <div className="wrap">
                    <div className="contentArea" data-aos="fade-up">
                        <div className="contentTitle">Overview</div>
                    </div>
                    <Nav />
                    <div className="typing">
                        <div className="_typing-title center" data-aos="fade-up" data-aos-duration="500" >
                            Guyon works with the technology that connects people to the world.
                        </div>
                        <div className="_typing-text center" data-aos="fade-up" data-aos-duration="1000" >
                            Gaion's mission is to customize and provide cutting-edge technologies such as AI, big data, and drones to companies and help maximize the value of creation. Customer's <br />
                            Accurately understand and diagnose needs and current status, professional consulting to find the technology you need, use it in the real world and create value
                            I'm applying.
                            <br /><br />
                            Even now, data analytics, drone control, and development to create value for data as well as big data solution development and deployment projects in the public and corporate areas<br />
                            It is leading various AI-based big data businesses such as businesses.
                            <br /><br />
                            <span className="color">The challenge of Gaion to connect people to the world doesn't stop until Gaon helps wherever technology is needed.</span>
                        </div>
                    </div>
                </div>
                {/* section 02 S */}
                <div className="wrap">
                    <div className="contentArea bar">
                        <div className="contentTitle">Company Overview</div>
                    </div>
                    <div className="tableBorder">
                        <table>
                            <tbody>
                                <tr>
                                    <th>Company name</th>
                                    <td>Gaion</td>
                                </tr>
                                <tr>
                                    <th>Address of business establishment</th>
                                    <td>11th floor of Hanshin Interval Valley, 322 Teheran-ro, Gangnam-gu, Seoul</td>
                                </tr>
                                <tr>
                                    <th>Date of establishment</th>
                                    <td>April 2, 2007</td>
                                </tr>
                                <tr>
                                    <th>CEO</th>
                                    <td>Kang Hyun-seop</td>
                                </tr>
                                <tr>
                                    <th>Business Area</th>
                                    <td>Big Data Solution Development and Data Analysis Services, Infrastructure/Network/Story Supply, and Technical Support</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <img src="/images/intro_new_01.png" />
                </div>
                {/* section 02 E */}


                {/* section 03 S */}

                <div className="wrap">
                    <div className="contentArea bar">
                        <div className="contentTitle">Organization chart</div>
                    </div>
                    <div className="introBox">
                        <div className="imgScroll">
                            <div className=" imgWrap">
                                <img src="/images/box.png" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* section 03 E */}

                {/* section 04 S */}
                <div className="wrap">
                    <div className="contentArea bar">
                        <div className="contentTitle">History</div>
                    </div>
                </div>
                <div className="history">
                    <div className="historyContent">
                        <div className="wrap">
                            <div className="_grid">
                                <div className="_item" >
                                    <div className="_item-title" >
                                        2018<br />
                                        ~ the present day
                                    </div>
                                </div>
                                <div className="_item">
                                    <div className="_item-circle" >
                                        <div className="_circleWhite"></div>
                                    </div>
                                    <div className="border"></div>
                                </div>
                                <div className="_item" >
                                    2022 Daejeon Technology Commercialization Center opened (Yuseong-gu, Daejeon Metropolitan City) / Capital increase to KRW 606 million<br />
                                    2021 Factory Registration (Dongan-gu, Anyang-si, Gyeonggi-do) / Capital increase to KRW 594 million / Capital increase to KRW 58 million <br />
                                    2020 Expand the headquarters to the first and second floors (Samsung-dong, Cheonglim Building), 19, Samseong-ro 96-gil, Gangnam-gu, Seoul
                                    2019 Acoustic Exclusive Korea Distributor Contract / Korea Data Industry Jiheungwon Data Voucher Project<br />
                                    2018 Selection of government projects for import and export trend prediction service through the intelligence of 2018 NIPA trade customs information.
                                    2018 Selection of the government project to establish a crowd-based recommended medical system through the analysis of big data on pets by the Rural Development Administration in 2018.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="historyContent">
                        <div className="wrap">
                            <div className="_grid">
                                <div className="_item">
                                    <div className="_item-title" >
                                        2011<br />
                                        ~ In 2017.
                                    </div>
                                </div>
                                <div className="_item">
                                    <div className="_item-circle" >
                                        <div className="_circleWhite"></div>
                                    </div>
                                </div>
                                <div className="_item" >
                                    2017 Development of 2017 big data-based anomaly detection security solution/Development of artificial intelligence analysis platform (gSmartML)<br />
                                    2016 Innobiz Certification<br />
                                    2015 Add Big Data Business (Oracle, IBM, Splunk, etc.) / Register BigShark Software (Big Data Analysis Solution)<br />
                                    2014 patent application (4 cases related to big data)<br />
                                    2013 Big Data Platform Development (Big Shark) / Establishment of a corporate research institute (Big Data Research Institute)<br />
                                    2012 Big Data Solution Development (gSmartTrend, gSmartShield, gSmartUSN)<br />
                                    2011 Add Oracle BI Solution Business / Add Quest v-Foglight Business (Virtualization Monitoring Solution) / Add Big Data Business / Citrix<br />
                                    / Add NetEap Virtualization Solution Business (Desktop Virtualization, Server Virtualization)

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="historyContent">
                        <div className="wrap">
                            <div className="_grid">
                                <div className="_item">
                                    <div className="_item-title" >
                                        2007<br />
                                        ~ Year 2010
                                    </div>
                                </div>
                                <div className="_item">
                                    <div className="_item-circle" >
                                        <div className="_circleWhite"></div>
                                    </div>
                                </div>
                                <div className="_item" >
                                    2010 Headquarters relocation: Samsung-dong Inspection Building / Quest Software Solution Business Addition / Venture Business Certification / Carbon Emissions - Greenhouse Gas, Hazardous Substance Management Solution Business Addition<br />
                                    / Add Oracle Solution Business (WebLogic, DBMS) / Development of FTA Origin Certification Solution (GAION FTA C/O / Change of CEO (Kang Hyun-seop)) <br />
                                    2009 10SK Networks Partner Agreement (Network Equipment, Servers)<br />
                                    2007 Establishment of Guyon Co., Ltd.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* section 04 E */}


                {/* section 05 S */}
                <div className="wrap" style={{ marginTop: "200px" }}>
                    <div className="contentArea bar">
                        <div className="contentTitle">a patent in possession</div>
                    </div>

                    <div className="tableLine">

                        <div className="tableTitle">
                            Own Intellectual Property Rights:<span className="otherColor">11 patents (2 registrations + 9 applications), 13 trademarks (12 registrations + 1 application)</span>
                        </div>

                        <table className="colorBorderTable _table" data-aos="fade-up" data-aos-duration="1000">
                            <thead>
                                <tr>
                                    <th>division</th>
                                    <th>Application number<br /> (registration number)</th>
                                    <th>Application date<br /> (Registration date)</th>
                                    <th>Patent Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="color center" rowSpan={11}>
                                        patent</td>
                                    <td className="center">10-2019-0150273​<br />(10-2329525)​</td>
                                    <td className="center">2019.11.21<br />(2021.11.17)</td>
                                    <td>Drone Delivery Area Management System and Method</td>
                                </tr>
                                <tr>
                                    <td className="center">10-2019-0152364<br />(10-2330239)</td>
                                    <td className="center">2019.11.25<br />(2021.11.18)</td>
                                    <td>Cloud-based drone delivery management system and method</td>
                                </tr>
                                <tr>
                                    <td className="center">10-2018-0154102</td>
                                    <td className="center"> 2018.12.07</td>
                                    <td>Animal Species Tracking Integrated Management System and Method</td>
                                </tr>
                                <tr>
                                    <td className="center">10-2019-0150275</td>
                                    <td className="center"> 2019.11.21</td>
                                    <td>System and method for predicting the life cycle of pets based on electronic medical record data</td>
                                </tr>
                                <tr>
                                    <td className="center">10-2019-0177540</td>
                                    <td className="center">2019.12.30</td>
                                    <td>Post Office Drone Delivery Management System and Method</td>
                                </tr>
                                <tr>
                                    <td className="center">10-2020-0118164</td>
                                    <td className="center">2020.09.15</td>
                                    <td>System and method for generating drone paths considering topographic altitude</td>
                                </tr>
                                <tr>
                                    <td className="center">10-2020-0148398</td>
                                    <td className="center">2020.11.09</td>
                                    <td>System and method for classifying diseases using electronic medical records in animal hospitals</td>
                                </tr>
                                <tr>
                                    <td className="center">10-2020-0148400</td>
                                    <td className="center">2020.11.09</td>
                                    <td>Preprocessing System and Method for Electronic Medical Record Data in Animal Hospitals</td>
                                </tr>
                                <tr>
                                    <td className="center">10-2020-0187587</td>
                                    <td className="center">2020.12.30</td>
                                    <td>Drone-Droid Collaboration-Based Unmanned Delivery Processing System and Method</td>
                                </tr>
                                <tr>
                                    <td className="center">10-2021-0155479</td>
                                    <td className="center">2021.11.12</td>
                                    <td>Unmanned delivery processing system and method using drone-mounted autonomous driving droids</td>
                                </tr>
                                <tr>
                                    <td className="center">10-2021-0160578</td>
                                    <td className="center">2021.11.19</td>
                                    <td>System and method for analyzing overseas market information</td>
                                </tr>
                            </tbody>
                        </table>

                        <table className="colorBorderTable _table" data-aos="fade-up" data-aos-duration="1000">
                            <thead>
                                <tr>
                                    <th>division</th>
                                    <th>Application number<br /> (registration number)</th>
                                    <th>Application date<br /> (Registration date)</th>
                                    <th>Patent Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="color center" rowSpan={13}>trademark rights</td>
                                    <td className="center">40-2016-0011490 <br />(40.1212422-0000)​</td>
                                    <td className="center">2016.02.16<br />(2016.10.31)</td>
                                    <td>BIG SHARK</td>
                                </tr>
                                <tr>
                                    <td className="center">40-2016-0011537​<br />(40.1212423-0000)​</td>
                                    <td className="center">2016.02.16<br />(2016.10.31)</td>
                                    <td>gSmart TREND</td>
                                </tr>
                                <tr>
                                    <td className="center">40-2020-0144669​</td>
                                    <td className="center">2020.08.18</td>
                                    <td>gSmartAI</td>
                                </tr>
                                <tr>
                                    <td className="center">40-2020-0174361​<br />(40-1824701-0000)​</td>
                                    <td className="center">2020.09.29<br />(2022.01.21)</td>
                                    <td>ESon</td>
                                </tr>
                                <tr>
                                    <td className="center">40-2020-0174366​<br />(40-1824702-0000)​</td>
                                    <td className="center">2020.09.29<br />(2022.01.21)</td>
                                    <td>The Gaon Digital Marketing Service G</td>
                                </tr>
                                <tr>
                                    <td className="center">40-2020-0174367​<br />(40-1824704-0000)​</td>
                                    <td className="center">2020.09.29<br />(2022.01.21)</td>
                                    <td>More data analysis service N</td>
                                </tr>
                                <tr>
                                    <td className="center">40-2020-0174437<br />(40-1824706-0000)</td>
                                    <td className="center">2020.09.29<br />(2022.01.21)</td>
                                    <td>WeTrade​</td>
                                </tr>
                                <tr>
                                    <td className="center">40-2020-0174440<br />(40-1824707-0000)</td>
                                    <td className="center">2020.09.29<br />(2022.01.21)</td>
                                    <td>BIGTRADE</td>
                                </tr>
                                <tr>
                                    <td className="center">40-2020-0174446<br />(40-1824708-0000)</td>
                                    <td className="center">2020.09.29<br />(2022.01.21)</td>
                                    <td>theDron</td>
                                </tr>
                                <tr>
                                    <td className="center">40-2020-0174448<br />(40-1824709-0000)</td>
                                    <td className="center">2020.09.29<br />(2022.01.21)</td>
                                    <td>theDron</td>
                                </tr>
                                <tr>
                                    <td className="center">40-2020-0174455<br />(40-1824713-0000)</td>
                                    <td className="center">2020.09.29<br />(2022.01.21)</td>
                                    <td>GAION</td>
                                </tr>
                                <tr>
                                    <td className="center">40-2020-0174457<br />(40-1824714-0000)</td>
                                    <td className="center">2020.09.29<br />(2022.01.21)</td>
                                    <td>DABOAN</td>
                                </tr>
                                <tr>
                                    <td className="center">40-2020-0174462<br />(40-1824717-0000)​</td>
                                    <td className="center">2020.09.29<br />(2022.01.21)</td>
                                    <td>For a Better Organizational Culture of Gaion! the Joeun</td>
                                </tr>

                            </tbody>

                        </table>
                        <div className="tableTitle">
                            ETRI Technology Transfer Patents List: <span className="otherColor">4 technologies (AI object recognition, AI language processing, AI security, AI logistics delivery), 30 patents (9 registered + 21 applications, 22 domestic + 8 US) </span>
                        </div>
                        <table className="colorBorderTable _table" data-aos="fade-up" data-aos-duration="1000">
                            <thead>
                                <tr>
                                    <th>division</th>
                                    <th>Application number<br /> (registration number)</th>
                                    <th>Application date<br /> (Registration date)</th>
                                    <th>Patent Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="otherColor center" rowSpan={9}>AI Object Recognition <br /> (Deep View)<br /> Technology  (TRL Level 6)</td>
                                    <td className="center">2020-0060095​​</td>
                                    <td className="center">2020.05.20​</td>
                                    <td>Object detection systems and methods</td>
                                </tr>
                                <tr>
                                    <td className="center">2019-0038049​​</td>
                                    <td className="center">2019.04.01​</td>
                                    <td>Main adaptation-based object recognition device and method</td>
                                </tr>
                                <tr>
                                    <td className="center">2018-0139323<br />(2291041)​​</td>
                                    <td className="center">2018.11.13<br />(2021.08.11)​</td>
                                    <td>Game Data-Based Learning Devices and Methods</td>
                                </tr>
                                <tr>
                                    <td className="center">16/834500​​</td>
                                    <td className="center">2020.03.30​</td>
                                    <td>Domain Adaptation-Based Face Recognition Devices and Methods</td>
                                </tr>
                                <tr>
                                    <td className="center">2020-0041134​​</td>
                                    <td className="center">2020.04.03​</td>
                                    <td>A system and method for detecting littering agents using multiple feature information in a surveillance camera environment</td>
                                </tr>
                                <tr>
                                    <td className="center">16/401789<br />(11048942)​​</td>
                                    <td className="center">2019.05.02<br />(2021.06.29)​</td>
                                    <td>Auto-detection system for real-time waste dumping behavior in a video surveillance system</td>
                                </tr>
                                <tr>
                                    <td className="center">16/033152<br />(10789470)​​</td>
                                    <td className="center">2018.07.11<br />(2020.09.29)​</td>
                                    <td>Variable background learning methods and systems based on global motion analysis for dynamic object detection in dynamic cameras</td>
                                </tr>
                                <tr>
                                    <td className="center">2016-0044176<br />(1953906)​​</td>
                                    <td className="center">2016.04.11<br />(2019.02.25)​</td>
                                    <td>Task Scheduling Method and Devices</td>
                                </tr>
                                <tr>
                                    <td className="center">2021-0051961​​</td>
                                    <td className="center">2021.04.21​</td>
                                    <td>Neural Network Learning Devices and Methods</td>
                                </tr>
                            </tbody>
                        </table>

                        <table className="colorBorderTable _table" data-aos="fade-up" data-aos-duration="1000">
                            <thead>
                                <tr>
                                    <th>division</th>
                                    <th>Application number<br /> (registration number)</th>
                                    <th>Application date<br /> (Registration date)</th>
                                    <th>Patent Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="otherColor center" rowSpan={9}>AI Language Processing<br />(exobrain)<br />Technology (TRL Step 6)</td>
                                    <td className="center">2020-0002588​​</td>
                                    <td className="center">2020.01.08​​</td>
                                    <td>System and method for generating news articles including indirect advertisements</td>
                                </tr>
                                <tr>
                                    <td className="center">2017-0164239<br />(2157390)​​</td>
                                    <td className="center">2017.12.01<br />(2020.09.11)​</td>
                                    <td>How to restore Korean omitted ingredients based on language model</td>
                                </tr>
                                <tr>
                                    <td className="center">2017-0159393<br />(2169868)​​</td>
                                    <td className="center">2017.11.27<br />(2020.10.20)​</td>
                                    <td>Chatting Robot System and Service Method</td>
                                </tr>
                                <tr>
                                    <td className="center">16/123356<br />(10771408)​​</td>
                                    <td className="center">2018.09.06<br />(2020.09.08)​</td>
                                    <td>A chatbot system that talks to other chatbots on behalf of the user</td>
                                </tr>
                                <tr>
                                    <td className="center">2021-0091541​​</td>
                                    <td className="center">2021.07.13​</td>
                                    <td>Pre-learning system and method for Korean deep learning language models capable of understanding and generating languages</td>
                                </tr>
                                <tr>
                                    <td className="center">2018-0133422​​</td>
                                    <td className="center">2018.11.02​</td>
                                    <td>Online encyclopedia-based new object names and new object names heterogeneous dictionary construction devices and methods</td>
                                </tr>
                                <tr>
                                    <td className="center">2019-0142837​​</td>
                                    <td className="center">2019.11.08​</td>
                                    <td>How to answer questions and devices in the legal field</td>
                                </tr>
                                <tr>
                                    <td className="center">2020-0002587​​</td>
                                    <td className="center">2020.01.08​</td>
                                    <td>Invention technology search system and method using virtual composite technical documents that integrate similar invention technical documents</td>
                                </tr>
                                <tr>
                                    <td className="center">2017-0159871<br />(2072238)​​</td>
                                    <td className="center">2017.11.28<br />(2020.01.23)​</td>
                                    <td>Reliability-based Question and Answer System and Method</td>
                                </tr>
                            </tbody>
                        </table>

                        <table className="colorBorderTable _table" data-aos="fade-up" data-aos-duration="1000">
                            <thead>
                                <tr>
                                    <th>division</th>
                                    <th>Application number<br /> (registration number)</th>
                                    <th>Application date<br /> (Registration date)</th>
                                    <th>Patent Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="otherColor center" rowSpan={4}>AI Security Technology<br />(TRL 6단계)​</td>
                                    <td className="center">2019-0008851​</td>
                                    <td className="center">2019.01.23</td>
                                    <td>Mask Area Processing System and Method</td>
                                </tr>
                                <tr>
                                    <td className="center">2019-0042130​</td>
                                    <td className="center">2019.04.10​</td>
                                    <td>Image protection transmitter, receiver, and method</td>
                                </tr>
                                <tr>
                                    <td className="center">2020-0173671</td>
                                    <td className="center">2020.12.11​</td>
                                    <td>Outshell-based Privacy Masking Device, Outshell-based Privacy<br />
                                        How to share a masking restoration device and privacy masking area descriptor </td>
                                </tr>
                                <tr>
                                    <td className="center">16/749762</td>
                                    <td className="center">2020.01.22</td>
                                    <td>System and Method of Processing Masking Region</td>
                                </tr>
                                <tr>
                                    <td className="otherColor center" rowSpan={8}>AI Logistics Shipping Technology<br /> (TRL Step 6)</td>
                                    <td className="center">2017-0038481​</td>
                                    <td className="center">2017.03.27</td>
                                    <td>Logistics Collaborative Delivery Methods and Devices Using Unmanned Aircraft</td>
                                </tr>
                                <tr>
                                    <td className="center">2017-0040866</td>
                                    <td className="center">2017.03.30​</td>
                                    <td>Unmanned Shipping Operation Method and Devices</td>
                                </tr>
                                <tr>
                                    <td className="center">15/938963<br />(10586460)</td>
                                    <td className="center">2018.03.28<br />(2020.03.10)</td>
                                    <td>System and its control method based on the result of generating a safe operating altitude by an unmanned aerial vehicle<br /></td>
                                </tr>
                                <tr>
                                    <td className="center">2020-0059542</td>
                                    <td className="center">2020.05.19​</td>
                                    <td>Unmanned Delivery Management Devices and Methods</td>
                                </tr>
                                <tr>
                                    <td className="center">17/323538</td>
                                    <td className="center">2021.05.18</td>
                                    <td>Drones delivery system with optimal delivery path generation method and its control method</td>
                                </tr>
                                <tr>
                                    <td className="center">2020-0156999</td>
                                    <td className="center">2020.11.20​</td>
                                    <td>An unmanned supply system using drones</td>
                                </tr>
                                <tr>
                                    <td className="center">17/477919</td>
                                    <td className="center">2021.09.08.​</td>
                                    <td>Device and method for landing drones and supplying unmanned delivery boxes for high-weight goods</td>
                                </tr>
                                <tr>
                                    <td className="center">2020-0189696</td>
                                    <td className="center">2020.12.31​</td>
                                    <td>Multi-Sensor Information LSTM-based unmanned robot collision detection/warning method and system</td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
                {/* section 05 E */}

                {/* section 06 S */}
                <div className="wrap">
                    <div className="contentArea bar">
                        <div className="contentTitle">on your way here.</div>
                    </div>
                    <div className="mapTab">
                        <div className={classNames("_tab", { on: isMap == 0 })}
                            onClick={() => {
                                setMap(0)
                            }}
                        >
                            ABC (AI-Big Data Center)
                        </div>
                        <div className={classNames("_tab", { on: isMap == 1 })}
                            onClick={() => {
                                setMap(1)
                            }}
                        >
                            TCC (Technical Commercialization Center)
                        </div>
                        <div className={classNames("_tab", { on: isMap == 2 })}
                            onClick={() => {
                                setMap(2)
                            }}
                        >
                            D.Factory
                        </div>
                    </div>
                    <div>
                        {isMap == 0 && (
                            <Map1 />
                        )}
                        {isMap == 1 && (
                            <Map2 />
                        )}
                        {isMap == 2 && (
                            <Map3 />
                        )}
                    </div>
                </div>
                {/* section 06 E */}

            </div >
        </React.Fragment >
    );
}

export default Intro;