import axios from "axios";
import "view/style/css/intro.css";
import Nav from "view/parts/nav/nav";
import React, { useState } from "react";
import { useMutation } from "react-query";
import ReCAPTCHA from 'react-google-recaptcha';
import { showToast } from "view/component/toast";
import { Privacy } from "view/parts/main/privacy";
import { ArrowButton } from "view/component/arrowBtn";

function Contact() {
    const [values, setValues] = useState({})
    const [approve, setApprove] = useState(false);
    const [inflowArr, setInflowArr] = useState([]);
    const [productArr, setProductArr] = useState([]);
    const [captchaVerified, setCaptchaVerified] = useState(false);
    const sendContactUs = async (req) => {
        const response = await axios.post(`${process.env.REACT_APP_API}/qna/sendContactUs`, req)
        return response
    }

    const sendVerify = async (req) => {
        const response = await axios.post(`${process.env.REACT_APP_API}/qna/verifyCaptchaKey`, { key: req })
        return response
    }

    const verifyCaptcha = useMutation({
        mutationFn: (req) => sendVerify(req),
        onSuccess: response => {
            if (response.data.isSucceed && response.data.value.statusCodeValue === 200) {
                showToast("인증 완료", "green", 1000);
                setCaptchaVerified(true);
            } else {
                setCaptchaVerified(false);
            }
        },
        onError: () => {
            showToast("Error", "red", 1000);
            setCaptchaVerified(false);
        }
    })

    const {isLoading, mutate} = useMutation({
        mutationFn: (req) => sendContactUs(req),
        onSuccess: () => {
            showToast("전송 완료", "green", 1000, "/");
        },
        onError: () => {
            showToast("Error", "red", 1000);
        }
    })

    const handleFieldChange = event => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };

    const onChangeCaptchaValue = value => {
        verifyCaptcha.mutate(value);
    }

    const handleProductChange = event => {
        const value = event.target.name;

        if (event.target.checked) {
            setProductArr([...productArr, value]);
        } else {
            setProductArr([...productArr.filter(v => v !== value)])
        }
    }

    const handleInflowChange = event => {
        const value = event.target.name;

        if (event.target.checked) {
            setInflowArr([...inflowArr, value]);
        } else {
            setInflowArr([...inflowArr.filter(v => v !== value)])
        }
    }

    const onSubmitHandler = () => {
        if (!values.name || !values.email || !values.phone1 || !values.phone2 || !values.phone3) {
            showToast("이름, 이메일, 연락처를 입력해주세요", "red", 3000);
        } else if (!approve) {
            showToast("개인정보 처리 방침에 동의해주세요", "red", 3000);
        } else if (!captchaVerified) {
            showToast("Captcha 인증을 수행해주세요", "red", 3000);
        } else {
            const request = {
                name: values.name,
                email: values.email,
                inflowPath: inflowArr,
                content: values.content,
                productArray: productArr,
                position: values.position,
                department: values.department,
                companyName: values.companyName,
                phone: `${values.phone1}-${values.phone2}-${values.phone3}`,
            }
            mutate(request);
        }
    }

    return (
        <React.Fragment>
            <div className="content-inseide overview">
                <div className="wrap">
                    <div className="contentArea" data-aos="fade-up" data-aos-duration="500">
                        <div className="contentTitle">문의하기</div>
                    </div>
                    <Nav />
                </div>
                <div className="wrap">
                    <div className="typing">
                        <div className="_typing-title center" data-aos="fade-up" data-aos-duration="500" >
                            제품에 대해 궁금하신 점이 있으신 경우, 아래 문의하기를 통해 문의사항을<br />
                            남겨주시면 연락 드리겠습니다.
                        </div>
                    </div>
                    <div className="privacyArea">
                        <div className="privacyContent">
                            <Privacy />
                        </div>
                        <div className="privacyAgree">
                            <input type="checkbox" onClick={() => setApprove(!approve)} /><p>개인정보 처리 방침에 동의합니다.</p>
                        </div>
                    </div>
                </div>
                <div className="wrap">
                    <div className="privacyArea" style={{ marginTop: "50px", padding: 0, height: '100%', borderBottom: 'none' }}>
                        <div className="halfRow">
                            <div className="formField">
                                <div className="th"><span className="star">*</span>성명</div>
                                <div className="td"><input type="text" maxLength="15" name="name" onChange={handleFieldChange} /></div>
                            </div>
                            <div className="formField">
                                <div className="th"><span className="star">*</span>연락처</div>
                                <div className="td">
                                    <input type="text" maxLength="3" name="phone1" onChange={handleFieldChange} />&nbsp;-&nbsp;
                                    <input type="text" maxLength="4" name="phone2" onChange={handleFieldChange} />&nbsp;-&nbsp;
                                    <input type="text" maxLength="4" name="phone3" onChange={handleFieldChange} />
                                </div>
                            </div>
                        </div>
                        <div className="halfRow">
                            <div className="formField">
                                <div className="th"><span className="star">*</span>이메일</div>
                                <div className="td"><input type="text" name="email" onChange={handleFieldChange} /></div>
                            </div>
                            <div className="formField">
                                <div className="th"><span className="star">*</span>회사명</div>
                                <div className="td"><input type="text" name="companyName" onChange={handleFieldChange} /></div>
                            </div>
                        </div>
                        <div className="halfRow">
                            <div className="formField">
                                <div className="th"><span className="star">*</span>직급</div>
                                <div className="td"><input type="text" name="position" onChange={handleFieldChange} /></div>
                            </div>
                            <div className="formField">
                                <div className="th"><span className="star">*</span>부서</div>
                                <div className="td"><input type="text" name="department" onChange={handleFieldChange} /></div>
                            </div>
                        </div>
                        <div className="formFieldFull">
                            <div className="th"><span className="star">*</span>제품</div>
                            <div className="productList">
                                <ul>
                                    <li>
                                        <div className="productCheck">
                                            <input type="checkbox" id="splunk" name="Splunk" onChange={handleProductChange} />
                                            <label htmlFor="splunk" className="splunk">Splunk</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="productCheck">
                                            <input type="checkbox" id="teradata" name="Teradata" onChange={handleProductChange} />
                                            <label htmlFor="teradata" className="teradata">Teradata</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="productCheck">
                                            <input type="checkbox" id="Aqua" name="Aqua" onChange={handleProductChange} />
                                            <label htmlFor="Aqua" className="Aqua">Aqua</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="productCheck">
                                            <input type="checkbox" id="acoustic" name="Acoustic" onChange={handleProductChange} />
                                            <label htmlFor="acoustic" className="acoustic">Acoustic</label>
                                        </div>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <div className="productCheck">
                                            <input type="checkbox" id="ads" name="ADS" onChange={handleProductChange} />
                                            <label htmlFor="ads" className="ads">SentriX ADS(이상징후탐지)</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="productCheck">
                                            <input type="checkbox" id="soc" name="SOC" onChange={handleProductChange} />
                                            <label htmlFor="soc" className="soc">SentriX</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="productCheck">
                                            <input type="checkbox" id="gsmartmes" name="gSmart Mes" onChange={handleProductChange} />
                                            <label htmlFor="gsmartmes" className="gsmartmes">gSmart Mes</label>
                                        </div>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <div className="productCheck">
                                            <input type="checkbox" id="wetrade" name="WeTrade" onChange={handleProductChange} />
                                            <label htmlFor="wetrade" className="wetrade">WeTRADE</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="productCheck">
                                            <input type="checkbox" id="gsmartai" name="gSmart AI" onChange={handleProductChange} />
                                            <label htmlFor="gsmartai" className="gsmartai">gSmart AI</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="productCheck">
                                            <input type="checkbox" id="gsmartvision" name="gSmart VISION" onChange={handleProductChange} />
                                            <label htmlFor="gsmartvision" className="gsmartvision">gSmart VISION</label>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="productCheck">
                                            <input type="checkbox" id="gsmartbrain" name="gSmart BRAIN" onChange={handleProductChange} />
                                            <label htmlFor="gsmartbrain" className="gsmartbrain">gSmart BRAIN</label>
                                        </div>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <div className="productCheck">
                                            <input type="checkbox" id="thedron" name="the Dron" onChange={handleProductChange} />
                                            <label htmlFor="thedron" className="thedron">theDron</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="productCheck">
                                            <input type="checkbox" id="voucher" name="Voucher" onChange={handleProductChange} />
                                            <label htmlFor="voucher" className="voucher">Voucher</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="productCheck">
                                            <input type="checkbox" id="etc" name="etc" onChange={handleProductChange} />
                                            <label htmlFor="etc" className="etc">기타</label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="formFieldFull">
                            <div className="th"><span className="star">*</span>유입경로</div>
                            <div className="productList">
                                <ul>
                                    <li>
                                        <div className="productCheck">
                                            <input type="checkbox" id="o_etc1" name="온/오프라인 행사" onChange={handleInflowChange} />
                                            <label htmlFor="o_etc1">온/오프라인 행사</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="productCheck">
                                            <input type="checkbox" id="o_etc2" name="뉴스레터&메일" onChange={handleInflowChange} />
                                            <label htmlFor="o_etc2">뉴스레터&amp;메일</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="productCheck">
                                            <input type="checkbox" id="o_etc3" name="광고&보도자료" onChange={handleInflowChange} />
                                            <label htmlFor="o_etc3">광고&amp;보도자료</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="productCheck">
                                            <input type="checkbox" id="o_etc4" name="검색" onChange={handleInflowChange} />
                                            <label htmlFor="o_etc4">검색</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="productCheck">
                                            <input type="checkbox" id="o_etc5" name="기타" onChange={handleInflowChange} />
                                            <label htmlFor="o_etc5">기타</label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="formFieldFull">
                            <div className="th"><span className="star">*</span>내용</div>
                            <div className="td"><textarea name="content" onChange={handleFieldChange} /></div>
                        </div>
                    </div>
                </div>
                <div className="wrap">
                    <ReCAPTCHA
                        className="captcha"
                        sitekey={process.env.REACT_APP_CAPTCHA_KEY}
                        onChange={onChangeCaptchaValue}
                    />
                    <div className="submitWrap">
                        <p data-aos="fade-up" data-aos-duration="500" style={{ marginBottom: "65px" }}>문의사항 접수 여부 등은 영업일 기준 1주일 이내에 기재하신 이메일로 회신 드립니다.</p>
                        <ArrowButton title={"문의하기"} link={onSubmitHandler} isLoading={isLoading} margin={"auto"} />
                    </div>
                </div>
            </div>
        </React.Fragment >
    );
}

export default Contact;