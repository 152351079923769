import React from "react";
import "view/style/css/solution.css";
import Nav from "view/parts/nav/nav";

const Sop = () => {
  return (
    <React.Fragment>
      <div className="content-inseide solution">
        <div className="wrap">
          {/* <div className="contentArea">
            <div className="contentTitle">
              <div data-aos="fade-up" data-aos-duration="500">
                SentriX
              </div>
            </div>
          </div>
          <Nav /> */}
          <div className="typing max-width">
            <div
              className="_typing-title  center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img
                src="/images/solution/SentriX_SOP_Black.png"
                style={{ width: "300px" }}
                alt="sop logo"
              />
            </div>
            <div
              className="_typing-text center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              효율적인 보안 업무 운영을 위해, 최적화된 기능을 제공하는 보안 관제
              운영 포탈 솔루션입니다.
            </div>
          </div>
          <div
            className="introBox"
            style={{ padding: "50px 0" }}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="imgScroll">
              <div
                className="wrap esonImage"
                style={{ width: "unset", overflow: "hidden" }}
              >
                <img src="/images/solution/sop_01.jpg" alt="SOP 소개" />
              </div>
            </div>
          </div>
          <div className="typing max-width">
            <div
              className="_typing-title  center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              보안 업무 효율화 / 고도화 도구
            </div>
            <div
              className="_typing-text center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              SOP는 조직의 IT 보안 활동 수행을 관리하고 강화하기 위한 첨단 보안
              관제 업무 포탈 솔루션입니다.
              <br />
              자체 특화된 기능을 통해 보다 효율적이고 고도화된 보안 관제를 할 수
              있게 합니다.
            </div>
          </div>
          <div
            className="introBox"
            style={{ padding: "50px 0" }}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="imgScroll">
              <div
                className="wrap esonImage"
                style={{ width: "unset", overflow: "hidden" }}
              >
                <img src="/images/solution/sop_02.jpg" alt="SOP 소개" />
              </div>
            </div>
          </div>
          <div className="typing max-width">
            <div
              className="_typing-title  center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img
                src="/images/solution/SentriX_Black.png"
                style={{ width: "200px", marginRight: "20px" }}
                alt="sop logo"
              />
              System Architecture
            </div>
            <div
              className="_typing-text center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              센트릭스의 시스템 아키텍쳐는 이벤트 수집 영역으로부터, 수집된
              이벤트를 이벤트 가공 영역에서
              <br /> 전처리, 자동 티켓 병합, 티켓 이력, 자산 및 위협정보 매칭을
              통해 티켓 처리 영역으로 전달하도록 구성되어 있습니다.
            </div>
          </div>
          <div
            className="introBox"
            style={{ padding: "50px 0" }}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="imgScroll">
              <div
                className="wrap esonImage"
                style={{ width: "unset", overflow: "hidden" }}
              >
                <img src="/images/solution/sop_03.jpg" alt="SOP 소개" />
              </div>
            </div>
          </div>
          <div className="typing max-width">
            <div
              className="_typing-title  center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img
                src="/images/solution/SentriX_Black.png"
                style={{ width: "200px", marginRight: "20px" }}
                alt="sop logo"
              />
              AI Approach
            </div>
            <div
              className="_typing-text center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              많은 데이터 라벨링 소요가 발생하는 지도학습 형태를 지양하고 관제
              업무의 경감과 사용자의 판단을 도와주는 AI 아키텍처를 추구합니다.
            </div>
          </div>
          <div
            className="introBox"
            style={{ padding: "50px 0" }}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="imgScroll">
              <div
                className="wrap esonImage"
                style={{ width: "unset", overflow: "hidden" }}
              >
                <img src="/images/solution/sop_04.jpg" alt="SOP 소개" />
              </div>
            </div>
          </div>
          <div className="typing max-width">
            <div
              className="_typing-title  center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img
                src="/images/solution/SentriX_Black.png"
                style={{ width: "200px", marginRight: "20px" }}
                alt="sop logo"
              />
              도입 시 기대 효과
            </div>
            <div
              className="_typing-text center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              센트릭스는 보안 관제 업무 프로세스에 최적화된 기능과, 화면 구성을
              통해, 보안 관제 지식 정보와 티켓 프로세스를 통합 제공하며,
              <br /> AI와 지식정보의 연계로 위협 판단과 침해 대응 업무를
              신속하게 할 수 있습니다.
            </div>
          </div>
          <div
            className="introBox"
            style={{ padding: "50px 0" }}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="imgScroll">
              <div
                className="wrap esonImage"
                style={{ width: "unset", overflow: "hidden" }}
              >
                <img src="/images/solution/sop_05.jpg" alt="SOP 소개" />
              </div>
            </div>
          </div>
          <div className="Link">
            문의처 : <span>sales@gaion.kr</span> (영업팀)
            <br />
            Tel : <span>02.2051.9595</span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Sop;
