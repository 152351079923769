import React from "react";
import "view/style/css/voucher.css";
import Nav from "view/parts/nav/nav";
import * as IconHi from "react-icons/hi";
import { ArrowButton } from "view/component/arrowBtn";

function voucher({ props }) {
    return (
        <React.Fragment>
            <div className="content-inseide solution">
                <div className="wrap">

                    <div className="contentArea">
                        <div className="contentTitle">
                            <div data-aos="fade-up" data-aos-duration="500">AI Voucher</div>
                        </div>
                    </div>
                    <Nav />


                </div>

                <div className="wrap">
                    <div className="inner_center">
                        <div className="img_box center">
                            <a href="https://forms.gle/qFT3u6bbtkxAs7Cj6"
                                target="_blank"><img src="/images/voucher/voucher_icon_Ai_01.png" alt="voucher_img"
                                    className="img-responsive center_img" /></a>

                        </div>
                        <br />

                        <div className="inner_section">
                            <h4><br />
                            </h4>
                            <h3>바우처 사업 </h3>
                            <h5>
                                바우처 사업은 산업 활성화 생태계 조성 및 기술 활용에 어려움을 겪는<br />
                                중소, 벤처, 스타트업, 소상공인 등을 돕기 위해 국가에서 빅데이터와  AI 등의<br />
                                기술활용 비용을 지원해주는 사업입니다.<br />

                                가이온은 뛰어난 기술력과 풍부한 기술력을 인정 받아 데이터 바우처와<br />
                                AI 바우처의 공식 공급기관으로 선정되었습니다.
                            </h5>
                            <br />
                            <br />
                            <br />
                            <br />

                            <h3>사업개요</h3>
                            <h5>
                                중소벤처기업들의 혁신과 디지털 전환의 가속화를 위한 AI 솔루션을,<br />
                                전문기업으로부터 제공받을 수 있도록 지원하는 제도입니다.<br />
                                <br />
                                데이터 활용이 꼭 필요한 기업을 대상으로, <br />
                                기존 데이터 활용의 애로사항이었던 데이터 구매, 가공에 소요되는 비용을 지원합니다.   <br />
                            </h5>
                            <div className="voucher_icon">
                                <ul>
                                    <li>
                                        <div className="voucher_cont">
                                            <div className="icon_box"><img src="/images/voucher/voucher_icon01.png"
                                                alt="voucher_icon" /></div>
                                            <p>사업명</p><span>AI 바우처 지원사업</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="voucher_cont">
                                            <div className="icon_box"><img src="/images/voucher/voucher_icon02.png"
                                                alt="voucher_icon" /></div>
                                            <p>총 사업비</p><span>기업 당 최대 3억원</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="voucher_cont">
                                            <div className="icon_box"><img src="/images/voucher/voucher_icon03.png"
                                                alt="voucher_icon" /></div>
                                            <p>지원규모</p>
                                            <span>
                                                기업의 당면문제를 해결하고<br />
                                                생산성 혁신을 위해 AI 제품·서비스<br />적용이 필요한 중소·벤처/중견기업
                                            </span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="voucher_cont">
                                            <div className="icon_box"><img src="/images/voucher/voucher_icon04.png"
                                                alt="voucher_icon" /></div>
                                            <p>사업추진방법</p>
                                            <span>
                                                모든 산업분야에서 적용 가능하며,<br />
                                                가이온은 무역/경제, 스마트제조,<br />
                                                의료 헬스케어 등 분야에 특화
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <br />
                            <br />
                            <h5>
                                바우처 수요기업 등록기간은 보통 매년 초에 진행됩니다.<br />
                                바우처 지원사업 수요기업 지원, 혹은 관련하여 궁금하신 점이 있으신 고객께서는<br />
                                osm@gaion.kr로 문의 부탁드립니다.
                            </h5>

                        </div>

                        <h3>사업비 구성</h3>
                        <h5>
                            데이터 구매 / 데이터 가공(AI or 일반) 중 택1 하여 지원 가능
                        </h5>
                        <div className="img_box center">
                            <img src="/images/voucher/20210216_01.png" alt="voucher_img"
                                className="img-responsive center_img" />
                        </div>
                        <h5>
                            * 현금부담 &gt; 수요기업으로 참여하는 기업은 유형에 관계없이 민간부담금 중<br />
                            현금 부담을 중소기업 수준으로 함(민간부담금 중 현금부담은 10%)
                        </h5>

                        <h3>수행체계</h3>
                        <h5>
                            AI 솔루션을 도입 · 활용하고자 하는 수요기업이 공급기업의 AI 솔루션을 구매 · 활용하고,<br />
                            비용은 AI 바우처로 지급하게 됩니다.
                        </h5>
                        <div className="voucher_grid">
                            <img src="/images/voucher/voucher_img001_1.png" alt="voucher_img" className="img-responsive" />
                            <img src="/images/voucher/voucher_img002_1.png" alt="voucher_img" className="img-responsive" />



                        </div>

                        <h4 className="center">데이터바우처 전문 상담 : 플랫폼사업부문 02-6956-5941</h4>

                    </div>
                </div>

            </div >
        </React.Fragment >
    );
}

export default voucher;
