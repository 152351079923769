export function Privacy(props) {
  return (
    <div>
      <h4>◈ 개인정보처리방침</h4>
      <h5>
        ‘주식회사 가이온’은 (이하 ‘회사’는) 고객님의 개인정보를 중요시하며,
        개인정보 보호법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률,
        신용정보의 이용 및 보호에 관한 법률, CCPA(California Consumer privacy
        Act)를 준수하고 있습니다. 귀하는 개인정보호법에 따라 개인정보 수집, 이용
        동의를 거부하실 수 있으며, 귀하가 제공한 개인정보는 당사의
        개인정보보호정책에 따라 안전하게 관리됩니다. 당사는 다음과 같이
        개인정보의 처리를 수집, 이용합니다.
      </h5>
      <h2 className="center privacy_title">제1장 총칙</h2>
      <h3>◈ 제1조 목적</h3>
      <h5>
        본 지침은“정보통신망이용촉진 및 정보보호 등에 관한
        법률”,“개인정보보호법”등과 관련하여 주식회사 가이온(이하 ‘회사’)에서
        운영하고 있는 개인정보를 체계적으로 관리하고, 허가 받지 않은 공개,
        위·변조 및 파괴 등으로부터 보호함을 목적으로 한다.
      </h5>
      <h3>◈ 제2조 적용범위</h3>
      <h5>
        본 지침은 회사의 목적을 위하여 정보통신망 또는 정보통신망 이외의 수단을
        통하여 수집, 저장, 이용, 전송 및 폐기되는 개인정보 생명주기 전반에
        적용되며, 이러한 개인 정보를 처리하는 직원 및 제3자에게 적용한다.
      </h5>
      <h3>◈ 제3조 용어정의</h3>
      <ul className="_privacy-content">
        <li>
          “개인정보”라 함은 생존하는 개인에 관한 정보로서 성명·주민등록번호 등에
          의하여 당해 개인을 알아볼 수 있는 부호·문자·음성·음향·영상 및 생체특성
          등에 관한 정보(당해 정보만으로는 특정 개인을 알아볼 수 없는 경우에도
          다른 정보와 용이하게 결합하여 알아볼 수 있는 것을 포함한다)를 말한다.
        </li>
        <li>
          “개인정보보호 책임자”라 함은 부서 내에서 개인정보보호 업무를
          총괄하거나 업무처리를 최종 결정하는 임직원을 말한다.
        </li>
        <li>
          “개인정보보호 관리자”라 함은 개인정보보호 책임자를 보좌하여
          개인정보보호 업무에 대한 실무를 총괄하고 관리하는 자를 말한다.
        </li>
        <li>
          “개인정보처리자”라 함은 부서 내에서 고객의 개인정보를 수집, 보관,
          처리, 이용, 제공, 관리 또는 파기 등의 업무를 하는 자를 말한다.
        </li>
        <li>
          “제3자”라 함은 다음 각 호에 해당하는 자 이외의 자연인, 법인, 기관·단체
          및 기타의 자로써 1. 회사와 당해 서비스를 이용하는 자
          <dl>
            <dt>1. 회사로부터 개인정보의 수집·처리· 관리 등을 위탁 받은 자</dt>
            <dt>
              2. 영업의 양수, 합병, 상속 등에 의하여 서비스제공자로부터
              권리·의무를 승계한 자를 말한다.
            </dt>
          </dl>
        </li>
        <li>
          “개인정보처리시스템”이라 함은 개인정보를 처리할 수 있도록 체계적으로
          구성한 데이터베이스시스템을 말한다.
        </li>
        <li>
          “접속기록”이라 함은 이용자 또는 개인정보처리자 등이
          개인정보처리시스템에 접속하여 수행한 업무 내역에 대하여 식별자, 접속
          일시, 접속지를 알 수 있는 정보, 수행업무 등 접속한 사실을 전자적으로
          기록한 것을 말한다.
        </li>
        <li>
          “아이핀(I-PIN)”이라 함은 인터넷에서 주민등록 번호가 각종 범죄에
          악용되는 것을 해결하기 위해 정부가 개발한 인터넷 신원 확인
          번호(Internet Personal Identification Number)를 말한다.
        </li>
        <li>
          “고유식별정보”라 함은 개인을 고유하게 구별하기 위하여 부여된
          식별정보로서 주민등록번호, 여권번호, 운전면허번호, 외국인등록번호 등을
          말한다.
        </li>
        <li>
          “P2P(Peer to Peer)”라 함은 정보통신망을 통해 서버의 도움 없이 개인과
          개인이 직접 연결되어 파일을 공유하는 것을 말한다.
        </li>
        <li>
          “공유설정”이라 함은 컴퓨터 소유자의 파일을 타인이 조회·변경·복사 등을
          할 수 있도록 설정하는 것을 말한다.
        </li>
        <li>
          “침해사고”라 함은 해킹, 바이러스, 논리폭탄, 메일폭탄, 서비스 거부 또는
          고출력 전자기파 등에 의하여 정보통신망 또는 이와 관련된 정보시스템을
          공격하는 행위로 인하여 발생한 사태를 말한다.
        </li>
        <li>
          “개인정보파일”이라 함은 컴퓨터 등에 의하여 처리할 수 있도록 체계적으로
          구성된 개인정보의 집합물로서 자기테이프·자기디스크 등 전자적인 매체에
          기록된 것을 말한다.
        </li>
        <li>
          “개인영상정보”라 함은 CCTV·네트워크 카메라 등 영상(이하
          ‘영상정보처리기기’라 한다)에 의하여 촬영·처리 되는 영상정보 중 개인의
          초상·행동 등 사생활과 관련된 영상으로서 해당 개인의 동일성 여부를
          식별할 수 있는 정보를 말한다.
        </li>
        <li>
          “이용자”라 함은 회사가 제공하는 서비스를 이용하는 고객을 말한다.
        </li>
      </ul>

      <h2 className="center privacy_title">제2장 개인정보보호 조직 및 역할</h2>
      <h3>◈ 제1조 책임</h3>
      <h5>
        고객의 개인정보 보안에 대한 전반적인 책임은 ‘대표이사’에 있으며, 전사
        개인정보의 처리 및 안전성 확보를 위한 조치를 관리·감독할 책임은
        ‘개인정보관리책임자’에 있고, 개인정보를 직접 운영 및 처리하는 부서(팀)는
        해당 부서(팀)장에게 모든 책임이 있다.
      </h5>
      <h3>◈ 제2조 개인정보 보호책임자의 지정</h3>
      <h5>
        개인정보를 보호하고 개인정보와 관련한 이용자의 고충을 처리하기 위하여 각
        호의 어느 하나에 해당하는 지위에 있는 자를 개인정보 보호책임자를
        지정하여야 한다.
      </h5>
      <ul className="_privacy-content">
        <li>
          개인정보 보호책임자
          <dl>
            <dt>
              1. 개인정보 보호책임자
              <br /> 성명: 강현섭 대표 &nbsp;&nbsp; 소속: 가이온 &nbsp;&nbsp;
              연락처: 02-2051-9595 &nbsp;&nbsp; 이메일: hskkang@gaion.kr
            </dt>
            <dt>
              2. 개인정보 보호담당자
              <br /> 성명: 이건재 상무 &nbsp;&nbsp; 소속: 솔루션2사업부
              &nbsp;&nbsp; 연락처: 02-2051-9595 &nbsp;&nbsp; 이메일:
              gjlee@gaion.kr
            </dt>
          </dl>
        </li>
        <li>
          개인정보와 관련하여 고객의 고충처리르 담당하는 부서의 장
          <dl>
            <dt>
              1. 개인정보보호책임자를 지정하거나 변경하는 경우 개인정보
              보호책임자의 지정 및 변경 사실, 성명과 부서의 명칭, 전화번호 등
              연락처를 공개하여야 한다.
            </dt>
            <dt>
              2. 개인정보보호책임자 및 개인정보보호 관리자는 「개인정보보호 내부
              관리 계획」에서 정한다.
            </dt>
            <dt>
              3. 개인정보보호책임자는 회사 상황 및 조직 특성 등을 고려하여
              정보보호최고책임자가 겸임할 수 있다.
            </dt>
          </dl>
        </li>
      </ul>
      <h3>◈ 제3조 역할 및 책임</h3>
      <h5>개인정보보호책임자는 다음 각 호의 업무를 수행한다.</h5>
      <ul className="_privacy-content">
        <li>개인정보보호 계획과 관리 총괄</li>
        <li>개인정보보호지침 및 관련 규정의 검토, 승인</li>
        <li>개인정보침해 사고 발생에 대한 분석 및 사후관리 총괄</li>
        <li>
          개인정보보호 관련 통계 및 자료의 취합 및 관리 등 개인정보보호관리자는
          다음 각 호의 업무를 수행한다. 개인정보보호관리자는 정보보호 관리자가
          겸임할 수 있다.
          <dl>
            <dt>
              1. 개인정보보호 계획 및 방침의 수립, 관련지침 개정에 대한 검토 후
              개인정보보호 책임자에게 보고
            </dt>
            <dt>2. 개인정보침해 사고에 대한 분석, 대응.</dt>
          </dl>
        </li>
        <li>개인정보처리 실태 관리 결과 및 각종 자료의 취합</li>
        <li>
          개인정보보호담당자는 다음 각 호의 업무를 수행한다.
          개인정보보호담당자는 정보보호 담당자가 겸임할 수 있다.
          <dl>
            <dt>
              1. 개인정보보호 실태 및 활동 결과에 대하여 개인정보보호관리자에게
              보고
            </dt>
            <dt>2. 개인정보보호 관련 업무 전반의 이행 등</dt>
          </dl>
        </li>
      </ul>
      <h2 className="center privacy_title">제3장 개인정보의 보호</h2>
      <h3>◈ 제1조 개인정보의 파기 등</h3>
      <ul className="_privacy-content">
        <li>
          동의를 얻은 개인정보를 사전에 정한 목적을 달성한 경우와 개인정보의
          보유 및 이용기간이 종료한 경우, 사업을 폐지하는 경우에는 지체 없이
          파기하여야 한다. (다만, 다른 법령에 따라 보존할 필요성이 있는 경우 및
          보유기간을 미리 고객에게 고지하거나 개별적으로 고객의 동의를 받아 고객
          정보를 보존하여야 하는 경우에는 예외로 한다)
        </li>
        <li>
          제1항에 따라 다른 법령에 따라 보존하여야 하는 경우에는 해당 개인정보
          또는 개인정보 파일을 다른 개인정보와 분리하여서 저장ㆍ관리하여야 한다.
        </li>
        <li>
          개인정보보호 책임자가 개인정보를 파기할 때에는 복구 또는 재생되지
          아니하도록 다음 각 호의 구분에 따른 방법으로 파기하고 그 결과를
          개인정보파기 대장에 기록. 유지 하여야 한다. (시스템 상으로 출력/복사
          및 파기를 관리하는 부서는 개인정보 파기 대장을 별도로 작성하지 않는다)
          <dl>
            <dt>
              1. 전자적 파일 형태인 경우 : 복원이 불가능한 방법으로 영구 삭제
            </dt>
            <dt>
              2. 제1호 외의 기록물, 인쇄물, 서면, 그 밖의 기록매체인 경우 : 파쇄
              또는 소각
            </dt>
          </dl>
        </li>
        <li>
          1년 동안 서비스를 이용하지 아니하는 고객의 개인정보를 보호하기 위하여
          개인정보를 즉시 파기하거나 다른 고객의 개인정보와 분리하여 별도로
          저장ㆍ관리하여야 한다. 이 경우 해당 개인정보를 이용하거나 제공하여서는
          아니된다.
        </li>
        <li>
          기간 만료 30일 전까지 개인정보가 파기되거나 분리되어 저장·관리되는
          사실과 기간 만료일 및 해당 개인정보의 항목을 고객에게 알려야 한다.
        </li>
        <li>
          개인정보를 전송 받은 수탁업체, 제3자 등은 해당 업무 목적을 달성할
          때에는 전송 받은 개인정보를 즉시 파기해야 하며, 파기확인증을 기록·유지
          하여야 한다.
        </li>
        <li>
          개인정보 자료 파기를 수탁자가 대행하는 경우에는
          개인정보보호담당자(또는 위임 받는자) 입회 하에 폐기를 하여야 하며, 그
          결과를 개인정보 파기 대장에 기록·유지 하여야 한다.
        </li>
        <li>
          탈퇴회원의 개인정보의 경우 탈퇴 즉시 별도의 DB에 저장하여 관련 법률에
          정해진 기간 동안 보관한다.
        </li>
      </ul>
      <h2 className="center privacy_title">
        제4장 개인정보 처리방침 및 보호조치
      </h2>
      <h3>◈ 제1조 개인정보 처리방침의 공개</h3>
      <ul className="_privacy-content">
        <li>
          고객의 개인정보를 처리하는 경우에는 ‘개인정보 처리 방침’을 정하여 이를
          개인이 언제든지 쉽게 확인할 수 있도록 다음과 같은 사항을 포함하여
          공개하여야 한다.
          <dl>
            <dt>
              1 개인정보의 수집·이용 목적, 수집하는 개인정보의 항목 및 수집방법
            </dt>
            <dt>
              2 개인정보를 제3자에게 제공하는 경우 제공받는 자의 성명(법인인
              경우에는 법인의 명칭을 말한다), 제공받는 자의 이용 목적과 제공하는
              개인정보의 항목
            </dt>
            <dt>
              3 개인정보의 보유 및 이용 기간, 개인정보의 파기절차 및
              파기방법(개인정보를 보존하여야 하는 경우에는 그 보존근거와
              보존하는 개인정보 항목을 포함한다)
            </dt>
            <dt>
              4 개인정보 처리위탁을 하는 업무의 내용 및 수탁자(해당되는 경우에만
              처리방침에 포함한다)
            </dt>
            <dt>5 이용자 및 법정대리인의 권리와 그 행사방법</dt>
            <dt>
              6 인터넷 접속정보파일 등 개인정보를 자동으로 수집하는 장치의
              설치·운영 및 그 거부에 관한 사항
            </dt>
            <dt>
              7 개인정보보호 책임자의 성명 또는 개인정보보호 업무 및 관련
              고충사항을 처리하는 부서의 명칭과 그 전화번호 등 연락처
            </dt>
          </dl>
        </li>
        <li>
          개인정보 처리방침을 변경하는 경우에는 그 이유 및 변경내용을 다음 각
          호의 방법 중 어느 하나 이상의 방법으로 공지하고, 고객이 언제든지
          변경된 사항을 쉽게 확인할 수 있도록 인터넷 홈페이지에 지속적으로
          게재하여 공개하여야 한다.
        </li>
        <li>
          개인정보 처리방침의 내용과 정보주체와 체결한 계약의 내용이 다른
          경우에는 정보 주체에게 유리한 것을 적용한다.
        </li>
      </ul>
      <h3>◈ 제2조 개인정보의 보호조치</h3>
      <ul className="_privacy-content">
        <li>
          개인정보를 처리할 때에는 개인정보의 분실·도난·누출·변조 또는 훼손을
          방지하기 위하여 다음 각 호의 기술적·관리적 조치를 하여야 한다.
          <dl>
            <dt>
              1. 개인정보를 안전하게 처리하기 위한 내부관리계획의 수립·시행
            </dt>
            <dt>
              2. 개인정보에 대한 불법적인 접근을 차단하기 위한 침입차단시스템 등
              접근 통제장치의 설치·운영
            </dt>
            <dt>3. 접속기록의 위조·변조 방지를 위한 조치</dt>
            <dt>
              4. 개인정보를 안전하게 저장·전송할 수 있는 암호화기술 등을 이용한
              보안조치
            </dt>
            <dt>
              5. 백신 소프트웨어의 설치·운영 등 컴퓨터바이러스에 의한 침해
              방지조치
            </dt>
            <dt>6. 그 밖에 개인정보의 안전성 확보를 위하여 필요한 보호조치</dt>
          </dl>
        </li>
        <li>고객의 개인정보를 처리하는 자를 최소한으로 제한하여야 한다.</li>
        <li>
          개인정보의 안전한 처리를 위하여 다음 각 호의 내용을 포함하는
          내부관리계획을 수립ㆍ시행하여야 한다.
          <dl>
            <dt>
              1. 개인정보보호 책임자의 지정 등 개인정보보호 조직의 구성ㆍ운영에
              관한 사항
            </dt>
            <dt>2. 개인정보처리자의 교육에 관한 사항</dt>
            <dt>3. 불법적인 접근을 차단하기 위한 조치</dt>
            <dt>4. 접속기록의 위조ㆍ변조 방지를 위한 조치</dt>
            <dt>5. 개인정보를 안전하게 저장ㆍ전송할 수 있는 보호조치</dt>
            <dt>
              6. 개인정보처리시스템 및 개인정보처리자가 개인정보 처리에 이용하는
              정보기기에 컴퓨터바이러스, 스파이웨어 등 악성프로그램의 침투
              여부를 항시 점검ㆍ치료할 수 있는 백신소프트웨어 설치 및 이의
              주기적으로 갱신ㆍ점검
            </dt>
          </dl>
        </li>
      </ul>
      <h2 className="center privacy_title">제5장 정보주체의 권리보장</h2>
      <h3>◈ 제1조 개인정보 열람, 정정, 삭제, 처리정지 등</h3>
      <ul className="_privacy-content">
        <li>
          고객이 개인정보 수집·이용·제공 등의 동의를 철회하면 지체 없이 수집된
          개인정보를 복구·재생할 수 없도록 파기하는 등 필요한 조치를 하여야
          한다.
        </li>
        <li>
          다음 각 호의 어느 하나의 사항에 대한 열람 또는 제공을 요구 받으면
          지체없이 30일 안에 필요한 조치를 하여야 한다.
          <dl>
            <dt>1. 처리 중인 이용자의 개인정보</dt>
            <dt>2. 정보주체의 개인정보를 이용하거나 제3자에게 제공한 현황</dt>
            <dt>3. 개인정보 수집·이용·제공 등의 동의를 한 현황</dt>
          </dl>
        </li>
        <li>
          오류의 정정을 요구받으면 지체 없이 그 오류를 정정하거나 정정하지
          못하는 사유를 이용자에게 알리는 등 필요한 조치를 하여야 하고, 필요한
          조치를 할 때까지는 해당 개인정보를 이용하거나 제공하여서는 아니 된다.
        </li>
        <li>동의의 철회 또는 개인정보의 열람·제공</li>
      </ul>
      <h3>◈ 제2조 개인정보 이용내역의 통지</h3>
      <ul className="_privacy-content">
        <li>
          수집한 고객 개인정보의 다음 각 호의 내용을 포함하는 이용내역(제공 및
          개인정보 취급위탁을 포함한다)을 정기적으로 고객에게 통지하여야 한다.
          <dl>
            <dt>1. 개인정보의 수집ㆍ이용 목적 및 수집한 개인정보의 항목</dt>
            <dt>
              2. 개인정보를 제공받은 자와 그 제공 목적 및 제공한 개인정보의 항목
            </dt>
            <dt>
              3. 개인정보 처리위탁을 받은 자 및 그 처리위탁을 하는 업무의 내용
            </dt>
          </dl>
        </li>
      </ul>
      <h3>◈ 제3조 권리행사의 방법 및 절차</h3>
      <ul className="_privacy-content">
        <li>
          홈페이지, 이메일 등을 통하여 정보주체가 열람요구 등 권리를 행사할 수
          있도록 그 방법 및 절차를 공개하여야 한다.
        </li>
        <li>
          열람요구 등 권리행사의 방법은 개인정보를 수집하는 방법과 동일하거나
          보다 쉽게 정보주체가 열람요구 등 권리를 행사할 수 있도록 간편한 방법을
          제공하여야 한다. 공개하여야 한다.
        </li>
        <li>
          정보주체가 열람요구 등 권리행사에 대한 거절조치에 대하여 불복이 있는
          경우 이의를 제기할 수 있도록 필요한 절차를 마련하고 안내하여야 한다.
        </li>
      </ul>
      <h3>◈ 제4조 대리인에 의한 권리행사</h3>
      <h5>
        정보주체의 법정대리인 또는 위임을 받은 자가 열람요구 등 권리를 대리하는
        경우에는 반드시 정보주체의 위임장 등을 확인하여야 한다.
      </h5>
      <h2 className="center privacy_title">제6장 정보주체의 권리보장</h2>
      <h3>◈ 제1조 개인정보 유출 통지</h3>
      <ul className="_privacy-content">
        <li>
          개인정보의 분실·도난·누출 사실을 안 때에는 지체 없이 다음 각 호의 모든
          사항을 해당 고객에게 알리고 한국인터넷진흥원에 신고하여야 하며, 정당한
          사유 없이 그 사실을 안 때부터 24시간을 경과하여 통지·신고해서는 아니
          된다.
          <dl>
            <dt>1. 분실·도난·누출이 된 개인정보 항목</dt>
            <dt>2. 분실·도난·누출이 발생한 시점</dt>
            <dt>3. 이용자가 취할 수 있는 조치</dt>
            <dt>4. 개인정보처리자 등의 대응 조치</dt>
            <dt>5. 이용자가 상담 등을 접수할 수 있는 부서 및 연락처</dt>
          </dl>
        </li>
        <li>
          분실·도난·누출된 개인정보 항목 또는 분실·도난·누출이 발생한 시점에
          관한 구체적인 내용이 확인되지 아니하였으면 그때까지 확인된 내용과 같은
          고객이 취할 수 있는 조치, 개인정보보호 책임자의 대응 조치, 고객이 상담
          등을 접수할 수 있는 부서 및 연락처를 우선 통지ㆍ신고한 후 추가로
          확인되는 내용에 대해서는 확인되는 즉시 통지ㆍ 신고하여야 한다.
          이용자가 상담 등을 접수할 수 있는 부서 및 연락처
        </li>
        <li>
          개인정보가 유출된 경우 그 피해를 최소화하기 위한 대책을 마련하고
          필요한 조치를 하여야 한다.
        </li>
      </ul>
    </div>
  );
}
