import { useEffect, useState } from 'react';
import "view/style/css/footer.css";
import classNames from 'classnames';
import * as Io from "react-icons/io";
import { Link } from "react-router-dom";
import { useRecoilValue } from 'recoil';
import { localeInfo } from 'view/utils';

function Fixed() {
    const locale = useRecoilValue(localeInfo);
    const [isFixOpen, setFixOpen] = useState(false);
    const [menuWidth, setMenuWidth] = useState(undefined);

    const closeFixed = () => {
        setFixOpen(false);
    }

    useEffect(() => {
        if (!menuWidth) {
            setMenuWidth(document.getElementById("fixed-menu").offsetWidth)
        }
    }, [menuWidth])

    return (
        <div className={classNames("fixedMenu", { fixOpen: isFixOpen })} style={{ right: !isFixOpen ? 0 - menuWidth : 0 }}>
            <div className="_fixed-rotate"
                onClick={() => (
                    setFixOpen(!isFixOpen)
                )}
            >
                <div className="_fixed-text">
                    CUSTOMER
                    <Io.IoIosArrowUp />
                </div>
            </div>
            <div id="fixed-menu" className="_fixed-menu">
                <Link to="/company/contact" onClick={closeFixed}><img src="/images/fixed_01.png" /><div className="text">{locale === 'kr' ? "문의하기" : "Contact us"}</div></Link>
                <Link to="" onClick={closeFixed}><img src="/images/fixed_02.png" /><div className="text">{locale === 'kr' ? "소개서다운" : "Introduction"}</div></Link>
                <Link to="/company/notice" onClick={closeFixed} state={{ index: 3 }}><img src="/images/fixed_03.png" /><div className="text">{locale === 'kr' ? "공지사항" : "Notice"}</div></Link>
                <a href="https://we-trade.co.kr" target="_blank" onClick={closeFixed}><img src="/images/fixed_04.png" /><div className="text">{locale === 'kr' ? "위트레이드" : "WeTrade"}</div></a>
                <a href="https://www.wetrademall.com/" target="_blank" onClick={closeFixed}><img src="/images/fixed_05.png" /><div className="text">{locale === 'kr' ? "위트레이드몰" : "WeTrade Mall"}</div></a>
            </div>
        </div>
    );
}
export default Fixed;