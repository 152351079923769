import React, {
    useState,
    useEffect,
} from "react";
import "view/style/css/intro.css";
import { useLocation } from "react-router-dom";

function Nav() {
    const location = useLocation();
    const [isNum, setNum] = useState(0);

    useEffect(() => {

        if (location.pathname.match("intro")) {
            setNum(0);
        }
        if (location.pathname.match("ai")) {
            setNum(1);
        }
        if (location.pathname.match("bigdata")) {
            setNum(2);
        }
        if (location.pathname.match("dron")) {
            setNum(3);
        }

    }, [location.pathname]);

    return (
        <React.Fragment>
            <div className="nav">
                <React.Fragment>
                    {/* <div className="text">home　&gt; </div>
                    <div className="text">{menuItems[isNum]?.title}　&gt;</div>
                    <div className="text">{menuItems[isNum]?.list[menuClick]?.name}</div> */}
                </React.Fragment>
            </div>
        </React.Fragment>
    );
}

export default Nav;