import React from "react";
import "view/style/css/voucher.css";
import Nav from "view/parts/nav/nav";

function voucher() {
    return (
        <React.Fragment>
            <div className="content-inseide solution en">
                <div className="wrap">
                    <div className="contentArea">
                        <div className="contentTitle">
                            <div data-aos="fade-up" data-aos-duration="500">AI Voucher</div>
                        </div>
                    </div>
                    <Nav />
                </div>
                <div className="wrap">
                    <div className="inner_center">
                        <div className="img_box center">
                            <a href="https://forms.gle/qFT3u6bbtkxAs7Cj6"
                                target="_blank"><img src="/images/voucher/voucher_icon_Ai_01.png" alt="voucher_img"
                                    className="img-responsive center_img" /></a>
                        </div>
                        <br />
                        <div className="inner_section">
                            <h4><br />
                            </h4>
                            <h3>Voucher business</h3>
                            <h5>
                                The voucher business is designed to overcome difficulties in creating an industrial vitalization ecosystem and utilizing technology.<br />
                                To help SMEs, ventures, startups, small business owners, etc.<br />
                                This is a project that supports the cost of using technology.<br />
                                Gaion has been recognized for its outstanding technology and abundant technology,<br />
                                Selected as an official supplier of AI vouchers.
                            </h5>
                            <br />
                            <br />
                            <br />
                            <br />
                            <h3>Business overview</h3>
                            <h5>
                                AI solutions for accelerating innovation and digital transformation of small and medium-sized venture companies,<br />
                                This is a support system that allows you to receive services from specialized companies.<br />
                                <br />
                                For companies that need data utilization,<br />
                                We support the cost of purchasing and processing data, which was a difficulty in using existing data.<br />
                            </h5>
                            <div className="voucher_icon">
                                <ul>
                                    <li>
                                        <div className="voucher_cont">
                                            <div className="icon_box"><img src="/images/voucher/voucher_icon01.png"
                                                alt="voucher_icon" /></div>
                                            <p>Business name</p><span>AI voucher support project</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="voucher_cont">
                                            <div className="icon_box"><img src="/images/voucher/voucher_icon02.png"
                                                alt="voucher_icon" /></div>
                                            <p>Total cost of business</p><span>Up to 300 million won per company</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="voucher_cont">
                                            <div className="icon_box"><img src="/images/voucher/voucher_icon03.png"
                                                alt="voucher_icon" /></div>
                                            <p>Scale of support</p>
                                            <span>
                                                solve the company's problems<br />
                                                AI products and services for productivity<br />
                                                innovation Small and medium-sized ventures/medium-sized<br />
                                                companies that need to be applied
                                            </span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="voucher_cont">
                                            <div className="icon_box"><img src="/images/voucher/voucher_icon04.png"
                                                alt="voucher_icon" /></div>
                                            <p>How to promote business</p>
                                            <span>
                                                Applicable in all industries,<br />
                                                Gaion is a trade/economy, smart manufacturing,<br />
                                                Specialized in fields such as medical healthcare
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <br />
                            <br />
                            <h5>
                                The registration period for companies requiring vouchers is usually held at the beginning of each year.<br />
                                If you have any questions regarding the support for companies in demand for the voucher support project,<br />
                                Please contact osm@gaion.kr.
                            </h5>
                        </div>
                        <h3>Composition of project cost</h3>
                        <h5>
                            Data purchase / data processing (AI or general) can be applied by choosing 1
                        </h5>
                        <div className="img_box center">
                            <img src="/images/voucher/20210216_01.png" alt="voucher_img"
                                className="img-responsive center_img" />
                        </div>
                        <h5>
                            * Cash burden &gt; For companies participating as demand companies, regardless of type, part of the private burden<br />
                            Set the cash burden to the level of small and medium-sized businesses (cash burden is 10% of private contributions)
                        </h5>
                        <h3>Performance system</h3>
                        <h5>
                            Demand companies that want to introduce and utilize AI solutions purchase and use AI solutions from suppliers,<br />
                            The cost will be paid by AI voucher.
                        </h5>
                        <div className="voucher_grid">
                            <img src="/images/voucher/voucher_img001_1.png" alt="voucher_img" className="img-responsive" />
                            <img src="/images/voucher/voucher_img002_1.png" alt="voucher_img" className="img-responsive" />
                        </div>
                        <h4 className="center">Data voucher expert consultation: platform business division 02-6956-5941</h4>
                    </div>
                </div>
            </div >
        </React.Fragment >
    );
}

export default voucher;
