import React, { useState, useCallback, useEffect, useRef } from "react";

const { kakao } = window;

function Map1() {
  useEffect(() => {
    mapscript();
  }, []);

  const mapscript = () => {
    let container = document.getElementById("map");
    let options = {
      center: new kakao.maps.LatLng(36.380798458, 127.36627178),
      level: 3,
    };
    //map
    const map = new kakao.maps.Map(container, options);

    //마커가 표시 될 위치
    let markerPosition = new kakao.maps.LatLng(36.380798458, 127.36627178);

    // 마커를 생성
    let marker = new kakao.maps.Marker({
      position: markerPosition,
    });

    // 마커를 지도 위에 표시
    marker.setMap(map);
  };
  return (
    <div
      style={{
        width: "100%",
        display: "inline-block",
      }}
    >
      <div id="map" style={{ width: "100rea%", height: "500px" }}></div>
      <div className="mapAddress">
        TCC(기술사업화센터) : 대전광역시 유성구 가정로 218, ETRI융합기술연구센터
        2층
      </div>
    </div>
  );
}

export default Map1;
