import 'swiper/css';
import React from "react";
import "swiper/css/pagination";
import { Pagination } from 'swiper';
import "view/style/css/solution.css";
import Nav from "view/parts/nav/nav";
import { Swiper, SwiperSlide } from 'swiper/react';

function Vision({ props }) {
    return (
        <React.Fragment>
            <div className="content-inseide solution">
                <div className="wrap">
                    <div className="contentArea">
                        <div className="contentTitle" style={{ "zIndex": "-1", "position": "relative" }}>
                            <div data-aos="fade-up" data-aos-duration="500">gSmart VISION</div>
                        </div>
                    </div>
                    <Nav />
                </div>
                <div className="wrap">
                    <div className="typing">
                        <div className="_typing-title center">
                            gSmart VISION은 영상을 수집하고 분석해<br />
                            상황에 맞는 후속 조치와 대응이 가능하도록 지원하는<br />
                            AI 영상지능에 기반한 감시 • 관제 플랫폼 입니다.
                        </div>
                        <div className="_typing-text center">
                            CCTV, 드론, 카메라, 스마트폰 등을 통해 취합된 영상에서 필요한 정보를 추출하고 메타데이터화 해<br />
                            라벨링 하며, gSmart VISION만의 객체인식 및 탐지 기술을 통해AI 기반으로 실시간 분석하고,<br />
                            특정 객체나 사람을 구분해 행동과 상황을 감지해서 신속하게 알림을 전달하거나 대응할 수 있게 지원합니다.
                        </div>
                    </div>
                </div>
                <div className="imgScroll">
                    <div className="esonImage">
                        <img src="/images/vision_01.jpg" data-aos="fade-up" data-aos-duration="500" style={{ width: "100%" }} />
                    </div>
                </div>

                {/* 필요성 S */}
                <div className="wrap">
                    <div className="typing">
                        <div className="contentArea bar">
                            <div className="contentTitle" data-aos="fade-up" data-aos-duration="500">gSmart VISION 도입의 필요성​</div>
                        </div>
                        <div className="_typing-title center" data-aos="fade-up" data-aos-duration="800">
                            gSmart VISION을 통해 영상보안, 생활, 산업, 국방, 교통, 방재 등의 영역에서 발생할 수 있는<br />
                            사건과 사고를 예방 하거나 발생 시에 빠르게 대처해 피해를 최소화할 수 있습니다.
                        </div>
                        <div className="_typing-text center">
                            객체탐지 기술을 통해 이미지나 영상 내에 관심 있는 객체(사람, 사물)를 탐지하고,<br />
                            실시간 객체 탐지가 가능한 심층 신경망을 생성하고 AI 학습 프로그램을 구성할 수 있습니다.​<br /><br />
                            포즈추정 기술을 통해 사람의 상태를 이해하고, 구간 분할 기술을 통해 영상 변화를 탐지하며,<br />
                            객체분석 기술과 블러링을 통해 개인정보보호도 지원합니다.
                        </div>
                    </div>
                </div>
                <div className="introBox">
                    <div className="imgScroll">
                        <div className="esonImage" data-aos="fade-up" data-aos-duration="500">
                            <img src="/images/vision_02.jpg" style={{ width: "100%" }} />
                        </div>
                    </div>
                </div>
                {/* 필요성 E */}

                {/* 차별성 S */}
                <div className="wrap">
                    <div className="typing">
                        <div className="contentArea bar">
                            <div className="contentTitle" data-aos="fade-up" data-aos-duration="500">gSmart VISION 만의 차별성​​</div>
                        </div>
                        <div className="_typing-title center" data-aos="fade-up" data-aos-duration="800">
                            1. 검출 정확도 95% 수준의 ‘AI 기반 시각지능화 기술’
                        </div>

                        <div className="grid two">
                            <div className="_two-item pic_01">
                                <div className="_bg"></div>
                                <div className="_two-title">
                                    넓은 범위의 객체 탐지 기술
                                </div>
                                <div className="_two-content">
                                    이미지나 영상 내에 관심 있는 객체를 탐지하는 기술인<br />
                                    객체탐지 기술을 통해 실시간으로 객체를 탐지할 수 있습니다.<br />
                                    현재 40여 종의 객체를 검출할 수 있으며,<br />
                                    향후 80여 종 인식을 목표로 개발 중에 있습니다.<br />
                                    또한 이를 기반으로 심층 신경망을 생성할 수도 있습니다.
                                </div>
                            </div>
                            <div className="_two-item pic_02">
                                <div className="_bg"></div>
                                <div className="_two-title">
                                    디테일한 인스턴스 세그멘테이션
                                </div>
                                <div className="_two-content">
                                    객체가 같은 클래스(구분)에 속하더라도 독립된 객체라면,<br />
                                    각기 다른 색이나 라벨로 표현 가능한 수준의 구분이 가능합니다.<br />
                                    객체를  모두 개별 인스턴스 단위로 인식 하기 때문에<br />
                                    픽셀 단위로도 외형을 분할할 수 있습니다.
                                </div>
                            </div>
                            <div className="_two-item pic_03">
                                <div className="_bg"></div>
                                <div className="_two-title">
                                    이상 탐지를 위한 포즈 추정 기술
                                </div>
                                <div className="_two-content">
                                    사람이 포함된 이미지나 영상에서 사람의 관절 위치를 추정해<br />
                                    자세를 인식할 수 있으며, 이를 통해  사람의 상태를 이해하고<br />
                                    안내 혹은 경고 시스템과 연동할 수 있습니다.<br /><br />
                                </div>
                            </div>
                            <div className="_two-item pic_04">
                                <div className="_bg"></div>
                                <div className="_two-title">
                                    효율적 탐지를 위한 구간 분할 기술
                                </div>
                                <div className="_two-content">
                                    수집되는 영상 정보 중에서 의미 있는 변화를 탐지 및 인식하고<br />
                                    이 부분을 구분하여 따로 구간화하고 수집 할 수 있습니다.
                                </div>
                            </div>
                            <div className="_two-item pic_05">
                                <div className="_bg"></div>
                                <div className="_two-title">
                                    개인정보보호를 위한 마스킹 기술
                                </div>
                                <div className="_two-content">
                                    이미지와 영상에서 사람의 얼굴 등 보호가 필요한 영역을<br />
                                    추론하고 검출해 얼굴을 가리는 블러링이 가능하며,<br />
                                    이를 통해 허가 받지 않은 개인정보 수집을 예방할 수 있습니다.
                                </div>
                            </div>
                            <div className="_two-item pic_06">
                                <div className="_bg"></div>
                                <div className="_two-title">
                                    빠른 처리속도와 인식 정확도
                                </div>
                                <div className="_two-content">
                                    세계 최고 수준인 135fps, 94.2%의 객체인식 처리속도 및 정확도<br />
                                    20fps, 45mAP의 속성정보 학습 및 인식 성능<br />
                                    MOTA 52%@20fps의 다중물체 추적 기술 성능<br />
                                    80%의 50여 행동에 대한 행동인식 정확도
                                </div>
                            </div>
                        </div>

                        <div className="_typing-title center" data-aos="fade-up" data-aos-duration="800">
                            2.  통합 실시간 감시 • 관제 모니터링 기능
                        </div>

                        <div className="grid two">
                            <div className="_two-item pic_07">
                                <div className="_bg"></div>
                                <div className="_two-title">
                                    디테일한 파악이 가능한 영상분석 결과 제공
                                </div>
                                <div className="_two-content">
                                    사람 및 사물 등 객체탐지 결과 가시화 표기<br />
                                    객체의 어노테이션 데이터 제공<br />
                                    얼굴 위치 및 영역 추론으로 블러링  효과 처리 가능<br />
                                    실시간 사람의 관절추정으로 자세 인식
                                </div>
                            </div>
                            <div className="_two-item pic_08">
                                <div className="_bg"></div>
                                <div className="_two-title">
                                    빠른 대응을 위한 실시간 이벤트 발생 알림
                                </div>
                                <div className="_two-content">
                                    관제인력의 모니터링 한계를 극복한 효율적인 모니터링<br />
                                    객체 추적 및 탐지결과, 검색결과 제공<br />
                                    세이프존 장애감지 및 이상상황 발생 이벤트 알림<br />
                                    사람 자세 분석 결과에 따른 특정행위 탐지 시 이벤트 알림
                                </div>
                            </div>
                            <div className="_two-item pic_09">
                                <div className="_bg"></div>
                                <div className="_two-title">
                                    사용자 및 기업에 맞춤화 커스터마이징 설정
                                </div>
                                <div className="_two-content">
                                    분석 객체의 종류, 행동 등에 따라 분류된 데이터 수집 가능<br />
                                    각 객체나 종류 별로 블록색 등 설정 가능<br />
                                    추가 인식이 필요한  개체 등에 대한 업그레이드 지원 가능
                                </div>
                            </div>
                            <div className="_two-item pic_10">
                                <div className="_bg"></div>
                                <div className="_two-title">
                                    관제센터 운영 및 업그레이드 지원
                                </div>
                                <div className="_two-content">
                                    영상분석서버 한 대로 실시간 동시 20채널 분석 가능<br />
                                    플랫폼 활용을 위한 지속적인 교육 지원<br />
                                    시스템 활용 세부 매뉴얼 제공


                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                {/* 차별성 E */}

                {/* 도입 효과 S */}
                <div className="wrap">
                    <div className="typing">
                        <div className="contentArea bar">
                            <div className="contentTitle" data-aos="fade-up" data-aos-duration="500">gSmart VISION 도입의 기대효과​​</div>
                        </div>
                        <div className="center">
                            <img src="/images/vision_03.png" style={{ width: "120px", marginTop: "80px" }} />
                        </div>
                        <div className="_typing-title center" data-aos="fade-up" data-aos-duration="800">
                            1. 감시 관제 서비스의 효율성 강화
                        </div>
                        <div className="_typing-text center" data-aos="fade-up" data-aos-duration="800">
                            gSmart VSION을 통해 1인당 기존의 2배가 넘는 500개 채널 이상을 관리할 수 있기 때문에,<br />
                            감시관제 서비스의 안정성을 유지 혹은 강화하면서 기존 대비 큰 인건비 절감효과를 확보할 수 있습니다.<br />
                            또한 기존의 CCTV 등의 인프라를 그대로 활용할 수도 있어 구축 예산도 절감할 수 있습니다.
                        </div>

                        <div className="center">
                            <img src="/images/vision_04.png" style={{ width: "120px", marginTop: "80px" }} />
                        </div>
                        <div className="_typing-title center" data-aos="fade-up" data-aos-duration="800">
                            2. 신속한 비상 상황 대응을 통한 골든타임 확보 지원
                        </div>
                        <div className="_typing-text center" data-aos="fade-up" data-aos-duration="800">
                            24시간 실시간 감시와 탐지에 기반한 알림 및 메시지 시스템으로<br />
                            비상 상황 발생 시에 빠른 확인과 대응이 가능하며<br />
                            피해를 최소화 할 수 있는 골든타임 확보를 지원합니다.
                        </div>

                        <div className="center">
                            <img src="/images/vision_05.png" style={{ width: "120px", marginTop: "80px" }} />
                        </div>
                        <div className="_typing-title center" data-aos="fade-up" data-aos-duration="800">
                            3. 선별관제를 통한 효과성 강화
                        </div>
                        <div className="_typing-text center" data-aos="fade-up" data-aos-duration="800">
                            AI 기반의 분석기술을 통해 이상 상황이나 의심 상황에 대한 선별 관제가 가능하며,<br />
                            사용자는 식별과 대응에 조금 더 집중할 수 있어서<br />
                            전체적인 관제 업무의 신뢰도와 정확도도 향상시킬 수 있습니다.
                        </div>
                    </div>

                    <div className="Link">
                        문의처 : <span>sales@gaion.kr</span> (AI솔루션부문, 영업팀)<br />
                        Tel :  <span>02.2051.9595</span>
                    </div>
                </div>
                {/* 도입 효과 E */}

            </div >
        </React.Fragment >
    );
}

export default Vision;