import "view/style/css/modal.css";

export function Modal(props) {
    const { className, height = 600, width = 800, title, setOpen, body } = props;

    return (
        <div className={className}>
            <div className='modal_body' style={{ height: height, width: width }}>
                <div className="modal_header">
                    <h3>{title}</h3>
                    <button onClick={() => setOpen(false)} />
                </div>
                <section className="modal_content">
                    <div className="inner_section">
                        {body}
                    </div>
                </section>
            </div>
        </div>
    );
}