import React from "react";
import "view/style/css/solution.css";
import Nav from "view/parts/nav/nav";

const Edc = () => {
  return (
    <React.Fragment>
      <div className="content-inseide solution">
        <div className="wrap">
          {/* <div className="contentArea">
            <div className="contentTitle">
              <div data-aos="fade-up" data-aos-duration="500">
                SentriX
              </div>
            </div>
          </div>
          <Nav /> */}
          <div className="typing max-width">
            <div
              className="_typing-title  center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img
                src="/images/solution/SentriX_EDC_Black.png"
                style={{ width: "300px" }}
                alt="edc logo"
              />
            </div>
            <div
              className="_typing-text center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              Enterprise Data Lake Solutions
            </div>
            <div
              className="_typing-text center"
              data-aos="fade-up"
              data-aos-duration="800"
              style={{ wordWrap: "break-word" }}
            >
              <img
                src="/images/solution/SentriX_ADS_Black.png"
                style={{ width: "200px", marginRight: "10px" }}
                alt="edc logo"
              />
              is a data broker that connects with various data such as RDBMS and
              NoSQL as well as commercial and open source solutions.
            </div>
          </div>
          <div
            className="introBox"
            style={{ padding: "50px 0" }}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="imgScroll">
              <div
                className="wrap esonImage"
                style={{ width: "unset", overflow: "hidden" }}
              >
                <img src="/images/solution/edc_01.jpg" alt="edc 소개" />
              </div>
            </div>
          </div>
          <div className="typing max-width">
            <div
              className="_typing-title  center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img
                src="/images/solution/SentriX_ADS_Black.png"
                style={{ width: "200px", marginRight: "10px" }}
                alt="edc logo"
              />
              core values(3C)
            </div>
          </div>
          <div
            className="introBox"
            style={{ padding: "50px 0" }}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="imgScroll">
              <div
                className="wrap esonImage"
                style={{ width: "unset", overflow: "hidden" }}
              >
                <img src="/images/solution/edc_02.jpg" alt="edc 소개" />
              </div>
            </div>
          </div>
          <div className="typing max-width">
            <div
              className="_typing-title  center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img
                src="/images/solution/SentriX_ADS_Black.png"
                style={{ width: "200px", marginRight: "10px" }}
                alt="edc logo"
              />
              Examples
            </div>
            <div
              className="_typing-text center"
              data-aos="fade-up"
              data-aos-duration="800"
              style={{ wordWrap: "break-word" }}
            >
              <img
                src="/images/solution/SentriX_ADS_Black.png"
                style={{ width: "200px", marginRight: "10px" }}
                alt="edc logo"
              />
              Typically, customers who are operating Splunk or want to increase
              additional data collection capacity or reduce costs by introducing
              a new big data system such as Elastic.
            </div>
          </div>
          <div
            className="introBox"
            style={{ padding: "50px 0" }}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="imgScroll">
              <div
                className="wrap esonImage"
                style={{ width: "unset", overflow: "hidden" }}
              >
                <img src="/images/solution/edc_03.jpg" alt="edc 소개" />
              </div>
            </div>
          </div>
          <div className="typing max-width">
            <div
              className="_typing-title  center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img
                src="/images/solution/SentriX_ADS_Black.png"
                style={{ width: "200px", marginRight: "10px" }}
                alt="edc logo"
              />
              Effect
            </div>
            <div
              className="_typing-text center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <img
                src="/images/solution/SentriX_ADS_Black.png"
                style={{ width: "200px", marginRight: "10px" }}
                alt="edc logo"
              />
              By simply introducing it, users can use the existing UI and
              dashboard without additional development and even reduce costs.
            </div>
          </div>
          <div
            className="introBox"
            style={{ padding: "50px 0" }}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="imgScroll">
              <div
                className="wrap esonImage"
                style={{ width: "unset", overflow: "hidden" }}
              >
                <img src="/images/solution/edc_04.jpg" alt="edc 소개" />
              </div>
            </div>
          </div>
          <div className="Link">
            Contact: <span>sales@gaion.kr</span> (Sales Team)
            <br />
            Tel : <span>02.2051.9595</span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Edc;
