import React from "react";
import "view/style/css/intro.css";
import Tab from "view/parts/tab/tab";
import Nav from "view/parts/nav/nav";
import { useRecoilValue } from "recoil";
import { localeInfo } from "view/utils";

function Gaion() {


    return (
        <React.Fragment>
            <div className="content-inseide overview en">
                <div className="background intro">
                    <div className="titleArea">
                        <div className="subTitle">
                            COMPANY
                        </div>
                        <div className="subContent">
                            가이온의 인공지능 기반의 빅데이터 전문 기업입니다.
                        </div>
                    </div>
                </div>
                <div className="wrap">
                    <Tab />
                </div>
                <div className="wrap">
                    <div className="contentArea">
                        <div className="contentTitle">회사소개</div>
                    </div>
                    <Nav />
                </div>
            </div>
        </React.Fragment>
    );
}

export default Gaion;