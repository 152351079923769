import AOS from "aos";
import "aos/dist/aos.css";
import Ai from "./aivoucher";
import Data from "./datavoucher";
import Tab from "view/parts/tab/tab";

import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import NotFound from "view/pages/error/notfound";

function Index() {


    useEffect(() => {
        AOS.init();
    })

    return (
        <React.Fragment>
            <Tab currentIndex={5} />
            <Routes>
                <Route path="/" element={<Data />} />
                <Route path="/data" element={<Data />} />
                <Route path="/ai" element={<Ai />} />
                <Route path="/*" element={<NotFound />} />
            </Routes>
        </React.Fragment>
    );
}

export default Index;