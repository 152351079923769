import React, { useState, useCallback, useEffect, useRef } from "react";
import "view/style/css/intro.css";
import Nav from "view/parts/nav/nav";

/*swiper*/
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Map, MapMarker } from "react-kakao-maps-sdk";
/*swiper*/

import Map1 from "view/parts/map/map1";
import Map2 from "view/parts/map/map2";
import Map3 from "view/parts/map/map3";
import classNames from "classnames";

function Intro() {
  const [isMap, setMap] = useState(0);

  return (
    <React.Fragment>
      <div className="content-inseide overview">
        <div className="wrap">
          <div className="contentArea" data-aos="fade-up">
            <div className="contentTitle">Overview</div>
          </div>
          <Nav />
          <div className="typing">
            <div
              className="_typing-title center"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              가이온은 사람과 세상을 연결하는 기술을 다루는 일을 합니다.
            </div>
            <div
              className="_typing-text center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              AI, 빅데이터, 드론 등의 첨단 기술을 기업에 맞춤화 해 제공하고,
              창출 가치를 극대화할 수 있게 돕는 것이 가이온의 사명입니다.
              고객사의
              <br />
              니즈와 현황을 정밀하게 파악 및 진단하고, 전문적인 컨설팅을 통해
              필요한 기술을 찾아, 실제 업무 현장에서 활용하고 가치를 창출할 수
              있게
              <br />
              지원하고 있습니다.
              <br />
              <br />
              지금도 공공 및 기업 영역에서 빅데이터 솔루션 개발과 구축 사업 뿐
              아니라 데이터의 가치를 창출하기 위한 데이터 분석, 드론 관제 및
              개발
              <br />
              사업 등 AI기반의 다양한 빅데이터 사업을 주도하고 있습니다.
              <br />
              <br />
              <span className="color">
                기술이 필요한 모든 곳에 가이온이 도움이 될 때 까지, 사람과
                세상을 연결하기 위한 가이온의 도전은 멈추지 않습니다.
              </span>
            </div>
          </div>
        </div>
        {/* section 02 S */}
        <div className="wrap">
          <div className="contentArea bar">
            <div className="contentTitle">회사 개요</div>
          </div>
          <div className="tableBorder">
            <table>
              <tbody>
                <tr>
                  <th>회사명</th>
                  <td>가이온</td>
                </tr>
                <tr>
                  <th>사업장 주소</th>
                  <td>
                    경기도 안양시 동안구 시민대로 327번길 24 (관양동,
                    더프라임빌딩 8층)
                  </td>
                </tr>
                <tr>
                  <th>설립일</th>
                  <td>2007년 4월 2일</td>
                </tr>
                <tr>
                  <th>대표이사</th>
                  <td>강 현 섭</td>
                </tr>
                <tr>
                  <th>사업영역</th>
                  <td>
                    빅데이터 솔루션 개발 및 데이터 분석 서비스,
                    인프라/Network/스토리 공급 및 기술지원
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <img src="/images/intro_new_01.png" />
        </div>
        {/* section 02 E */}

        {/* section 03 S */}

        <div className="wrap">
          <div className="contentArea bar">
            <div className="contentTitle">조직도</div>
          </div>
          <div className="introBox">
            <div className="imgScroll">
              <div className=" imgWrap">
                <img src="/images/box.png" />
              </div>
            </div>
          </div>
        </div>

        {/* section 03 E */}

        {/* section 04 S */}
        <div className="wrap">
          <div className="contentArea bar">
            <div className="contentTitle">연혁</div>
          </div>
        </div>
        <div className="history">
          <div className="historyContent">
            <div className="wrap">
              <div className="_grid">
                <div className="_item">
                  <div className="_item-title">
                    2018년
                    <br />~ 현재
                  </div>
                </div>
                <div className="_item">
                  <div className="_item-circle">
                    <div className="_circleWhite"></div>
                  </div>
                  <div className="border"></div>
                </div>
                <div className="_item">
                  2022 대전 기술사업화센터 개소(대전광역시 유성구) / 자본금
                  606백만원으로 증자
                  <br />
                  2021 공장 등록(경기도 안양시 동안구) / 자본금 594백만원으로
                  증자 / 자본금 580백만원으로 증자
                  <br />
                  2020 서울특별시 강남구 삼성로96길 19, 1층 2층(삼성동,
                  청림빌딩)으로 본점 확장
                  <br />
                  2019 Acoustic 독점 한국 총판계약 / 한국데이터산업지흥원 데이터
                  바우처 사업
                  <br />
                  2018 NIPA 무역통관정보 지능화를 통한 수출입 동향예측 서비스
                  정부 사업 선정
                  <br />
                  2018 농진청 반려동물 빅데이터 분석을 통한 크라우드 기반 추천
                  의료시스템 구축 정부 사업 선정
                </div>
              </div>
            </div>
          </div>
          <div className="historyContent">
            <div className="wrap">
              <div className="_grid">
                <div className="_item">
                  <div className="_item-title">
                    2011년
                    <br />~ 2017년
                  </div>
                </div>
                <div className="_item">
                  <div className="_item-circle">
                    <div className="_circleWhite"></div>
                  </div>
                </div>
                <div className="_item">
                  2017 빅데이터 기반 이상징후탐지 보안 솔루션 개발 /
                  인공지능분석플랫폼 개발 (gSmartML
                  <br />
                  2016 이노비즈 인증
                  <br />
                  2015 빅데이터사업추가(오라클, 아이비엠, 스플렁크 등) /
                  BigShark 소프트웨어 등록 (빅데이터 분석 솔루션)
                  <br />
                  2014 특허출원 (빅데이터 관련 4건)
                  <br />
                  2013 빅데이터 플랫폼 개발 (Big Shark )/ 기업부설연구소 설립
                  (빅데이터 연구소)
                  <br />
                  2012 빅데이터 솔루션 개발 (gSmartTrend, gSmartShield,
                  gSmartUSN)
                  <br />
                  2011 오라클 BI솔루션 사업 추가 / Quest v-Foglight 사업
                  추가(가상화 모니터링 솔루션) / 빅데이터 사업 추가 / Citrix
                  <br />/ 넷엡 가상화솔루션 사업추가(데스크탑 가상화,
                  서버가상화)
                </div>
              </div>
            </div>
          </div>
          <div className="historyContent">
            <div className="wrap">
              <div className="_grid">
                <div className="_item">
                  <div className="_item-title">
                    2007년
                    <br />~ 2010년
                  </div>
                </div>
                <div className="_item">
                  <div className="_item-circle">
                    <div className="_circleWhite"></div>
                  </div>
                </div>
                <div className="_item">
                  2010 본사 이전 : 삼성동 감령빌딩 / Quest 소프트웨어 솔루션
                  사업 추가 / 벤처기업 인증 / 탄소배출권-온실가스,
                  유해물질관리솔루션 사업 추가
                  <br />
                  / Oracle 솔루션사업 추가 (WebLogic, DBMS) / FTA원산지 증명
                  솔루션 개발 (GAION FTA C/O / 대표이사 변경 (강현섭)
                  <br />
                  2009 10SK네트웍스 파트너계약 체결(네트웍장비, 서버)
                  <br />
                  2007 (주)가이온 설립
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* section 04 E */}

        {/* section 05 S */}
        <div className="wrap" style={{ marginTop: "200px" }}>
          <div className="contentArea bar">
            <div className="contentTitle">보유 특허</div>
          </div>

          <div className="tableLine">
            <div className="tableTitle">
              자체 보유 지적재산권:{" "}
              <span className="otherColor">
                11개 특허(등록 2개 + 출원 9개), 13개 상표권(등록 12개 + 출원
                1개)
              </span>
            </div>

            <table
              className="colorBorderTable _table"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <thead>
                <tr>
                  <th>구분</th>
                  <th>
                    출원번호
                    <br />
                    (등록번호)​
                  </th>
                  <th>
                    출원일
                    <br />
                    (등록일)​
                  </th>
                  <th>특허 명칭</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="color center" rowSpan={11}>
                    특허권
                  </td>
                  <td className="center">
                    10-2019-0150273​
                    <br />
                    (10-2329525)​
                  </td>
                  <td className="center">
                    2019.11.21
                    <br />
                    (2021.11.17)
                  </td>
                  <td>드론 배달영역 관리 시스템 및 방법</td>
                </tr>
                <tr>
                  <td className="center">
                    10-2019-0152364
                    <br />
                    (10-2330239)
                  </td>
                  <td className="center">
                    2019.11.25
                    <br />
                    (2021.11.18)
                  </td>
                  <td>클라우드 기반 드론 배달 관리 시스템 및 방법</td>
                </tr>
                <tr>
                  <td className="center">10-2018-0154102</td>
                  <td className="center"> 2018.12.07</td>
                  <td>반려동물 종 추적 통합 관리 시스템 및 방법</td>
                </tr>
                <tr>
                  <td className="center">10-2019-0150275</td>
                  <td className="center"> 2019.11.21</td>
                  <td>
                    전자의무기록 데이터 기반 반려동물 생애주기 예측 시스템 및
                    방법
                  </td>
                </tr>
                <tr>
                  <td className="center">10-2019-0177540</td>
                  <td className="center">2019.12.30</td>
                  <td>우체국 드론 배달 관리 시스템 및 방법</td>
                </tr>
                <tr>
                  <td className="center">10-2020-0118164</td>
                  <td className="center">2020.09.15</td>
                  <td>지형고도를 고려한 드론 경로 생성 시스템 및 방법</td>
                </tr>
                <tr>
                  <td className="center">10-2020-0148398</td>
                  <td className="center">2020.11.09</td>
                  <td>
                    동물병원 전자의무기록을 이용한 질병 분류 시스템 및 방법
                  </td>
                </tr>
                <tr>
                  <td className="center">10-2020-0148400</td>
                  <td className="center">2020.11.09</td>
                  <td>동물병원 전자의무기록 데이터 전처리 시스템 및 방법</td>
                </tr>
                <tr>
                  <td className="center">10-2020-0187587</td>
                  <td className="center">2020.12.30</td>
                  <td>드론-드로이드 협업 기반 무인택배 처리 시스템 및 방법</td>
                </tr>
                <tr>
                  <td className="center">10-2021-0155479</td>
                  <td className="center">2021.11.12</td>
                  <td>
                    드론 탑재 자율주행 드로이드를 이용한 무인택배 처리 시스템 및
                    방법
                  </td>
                </tr>
                <tr>
                  <td className="center">10-2021-0160578</td>
                  <td className="center">2021.11.19</td>
                  <td>해외 시장정보 분석 시스템 및 방법</td>
                </tr>
              </tbody>
            </table>

            <table
              className="colorBorderTable _table"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <thead>
                <tr>
                  <th>구분</th>
                  <th>
                    출원번호
                    <br />
                    (등록번호)​
                  </th>
                  <th>
                    출원일
                    <br />
                    (등록일)​
                  </th>
                  <th>특허 명칭</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="color center" rowSpan={13}>
                    상표권
                  </td>
                  <td className="center">
                    40-2016-0011490 <br />
                    (40.1212422-0000)​
                  </td>
                  <td className="center">
                    2016.02.16
                    <br />
                    (2016.10.31)
                  </td>
                  <td>BIG SHARK</td>
                </tr>
                <tr>
                  <td className="center">
                    40-2016-0011537​
                    <br />
                    (40.1212423-0000)​
                  </td>
                  <td className="center">
                    2016.02.16
                    <br />
                    (2016.10.31)
                  </td>
                  <td>gSmart TREND</td>
                </tr>
                <tr>
                  <td className="center">40-2020-0144669​</td>
                  <td className="center">2020.08.18</td>
                  <td>gSmartAI</td>
                </tr>
                <tr>
                  <td className="center">
                    40-2020-0174361​
                    <br />
                    (40-1824701-0000)​
                  </td>
                  <td className="center">
                    2020.09.29
                    <br />
                    (2022.01.21)
                  </td>
                  <td>ESON</td>
                </tr>
                <tr>
                  <td className="center">
                    40-2020-0174366​
                    <br />
                    (40-1824702-0000)​
                  </td>
                  <td className="center">
                    2020.09.29
                    <br />
                    (2022.01.21)
                  </td>
                  <td>더 가온 디지털 마케팅 서비스 G</td>
                </tr>
                <tr>
                  <td className="center">
                    40-2020-0174367​
                    <br />
                    (40-1824704-0000)​
                  </td>
                  <td className="center">
                    2020.09.29
                    <br />
                    (2022.01.21)
                  </td>
                  <td>더 나온 데이터 분석 서비스 N ​</td>
                </tr>
                <tr>
                  <td className="center">
                    40-2020-0174437
                    <br />
                    (40-1824706-0000)
                  </td>
                  <td className="center">
                    2020.09.29
                    <br />
                    (2022.01.21)
                  </td>
                  <td>WeTrade​</td>
                </tr>
                <tr>
                  <td className="center">
                    40-2020-0174440
                    <br />
                    (40-1824707-0000)
                  </td>
                  <td className="center">
                    2020.09.29
                    <br />
                    (2022.01.21)
                  </td>
                  <td>BIGTRADE</td>
                </tr>
                <tr>
                  <td className="center">
                    40-2020-0174446
                    <br />
                    (40-1824708-0000)
                  </td>
                  <td className="center">
                    2020.09.29
                    <br />
                    (2022.01.21)
                  </td>
                  <td>theDron</td>
                </tr>
                <tr>
                  <td className="center">
                    40-2020-0174448
                    <br />
                    (40-1824709-0000)
                  </td>
                  <td className="center">
                    2020.09.29
                    <br />
                    (2022.01.21)
                  </td>
                  <td>theDron</td>
                </tr>
                <tr>
                  <td className="center">
                    40-2020-0174455
                    <br />
                    (40-1824713-0000)
                  </td>
                  <td className="center">
                    2020.09.29
                    <br />
                    (2022.01.21)
                  </td>
                  <td>GAION</td>
                </tr>
                <tr>
                  <td className="center">
                    40-2020-0174457
                    <br />
                    (40-1824714-0000)
                  </td>
                  <td className="center">
                    2020.09.29
                    <br />
                    (2022.01.21)
                  </td>
                  <td>DABOAN</td>
                </tr>
                <tr>
                  <td className="center">
                    40-2020-0174462
                    <br />
                    (40-1824717-0000)​
                  </td>
                  <td className="center">
                    2020.09.29
                    <br />
                    (2022.01.21)
                  </td>
                  <td>더 좋은 가이온의 조직 문화를 위한! the Joeun</td>
                </tr>
              </tbody>
            </table>
            <div className="tableTitle">
              ETRI 기술이전 특허 목록 :{" "}
              <span className="otherColor">
                4개 기술(AI 객체인식, AI 언어처리, AI 보안, AI 물류배송), 30개
                특허(등록 9개 + 출원 21개, 국내 22개 + 미국 8개){" "}
              </span>
            </div>
            <table
              className="colorBorderTable _table"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <thead>
                <tr>
                  <th>구분</th>
                  <th>
                    출원번호
                    <br />
                    (등록번호)​
                  </th>
                  <th>
                    출원일
                    <br />
                    (등록일)​
                  </th>
                  <th>특허 명칭</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="otherColor center" rowSpan={9}>
                    AI 객체인식(딥뷰)
                    <br />
                    기술(TRL 6단계)
                  </td>
                  <td className="center">2020-0060095​​</td>
                  <td className="center">2020.05.20​</td>
                  <td>객체 검출 시스템 및 방법</td>
                </tr>
                <tr>
                  <td className="center">2019-0038049​​</td>
                  <td className="center">2019.04.01​</td>
                  <td>메인 적응 기반 객체 인식 장치 및 그 방법</td>
                </tr>
                <tr>
                  <td className="center">
                    2018-0139323
                    <br />
                    (2291041)​​
                  </td>
                  <td className="center">
                    2018.11.13
                    <br />
                    (2021.08.11)​
                  </td>
                  <td>게임 데이터 기반의 학습 장치 및 방법</td>
                </tr>
                <tr>
                  <td className="center">16/834500​​</td>
                  <td className="center">2020.03.30​</td>
                  <td>도메인 적응 기반 얼굴인식 장치 및 방법</td>
                </tr>
                <tr>
                  <td className="center">2020-0041134​​</td>
                  <td className="center">2020.04.03​</td>
                  <td>
                    감시카메라 환경에서 다중 특징 정보를 이용한 쓰레기 투기
                    행위자 탐지 시스템 및 방법
                  </td>
                </tr>
                <tr>
                  <td className="center">
                    16/401789
                    <br />
                    (11048942)​​
                  </td>
                  <td className="center">
                    2019.05.02
                    <br />
                    (2021.06.29)​
                  </td>
                  <td>
                    영상 감시 시스템에서의 실시간 쓰레기 투기 행동 자동 탐지
                    시스템
                  </td>
                </tr>
                <tr>
                  <td className="center">
                    16/033152
                    <br />
                    (10789470)​​
                  </td>
                  <td className="center">
                    2018.07.11
                    <br />
                    (2020.09.29)​
                  </td>
                  <td>
                    동적 카메라에서 동적 물체 탐지를 위한 전역 움직임 분석
                    기반가변 배경 학습 방법 및 시스템
                  </td>
                </tr>
                <tr>
                  <td className="center">
                    2016-0044176
                    <br />
                    (1953906)​​
                  </td>
                  <td className="center">
                    2016.04.11
                    <br />
                    (2019.02.25)​
                  </td>
                  <td>태스크 스케쥴링 방법 및 장치</td>
                </tr>
                <tr>
                  <td className="center">2021-0051961​​</td>
                  <td className="center">2021.04.21​</td>
                  <td>뉴럴 네트워크 학습 장치 및 방법</td>
                </tr>
              </tbody>
            </table>

            <table
              className="colorBorderTable _table"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <thead>
                <tr>
                  <th>구분</th>
                  <th>
                    출원번호
                    <br />
                    (등록번호)​
                  </th>
                  <th>
                    출원일
                    <br />
                    (등록일)​
                  </th>
                  <th>특허 명칭</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="otherColor center" rowSpan={9}>
                    AI 언어처리
                    <br />
                    (엑소브레인)
                    <br />
                    기술(TRL 6단계)​
                  </td>
                  <td className="center">2020-0002588​​</td>
                  <td className="center">2020.01.08​​</td>
                  <td>간접광고를 포함한 뉴스 기사 생성 시스템 및 방법​</td>
                </tr>
                <tr>
                  <td className="center">
                    2017-0164239
                    <br />
                    (2157390)​​
                  </td>
                  <td className="center">
                    2017.12.01
                    <br />
                    (2020.09.11)​
                  </td>
                  <td>언어모델에 기반한 한국어 생략 성분 복원 방법</td>
                </tr>
                <tr>
                  <td className="center">
                    2017-0159393
                    <br />
                    (2169868)​​
                  </td>
                  <td className="center">
                    2017.11.27
                    <br />
                    (2020.10.20)​
                  </td>
                  <td>채팅로봇 시스템 및 서비스방법</td>
                </tr>
                <tr>
                  <td className="center">
                    16/123356
                    <br />
                    (10771408)​​
                  </td>
                  <td className="center">
                    2018.09.06
                    <br />
                    (2020.09.08)​
                  </td>
                  <td>사용자를 대신하여 다른 챗봇과 대화하는 챗봇 시스템</td>
                </tr>
                <tr>
                  <td className="center">2021-0091541​​</td>
                  <td className="center">2021.07.13​</td>
                  <td>
                    언어 이해 및 생성이 가능한 한국어 딥러닝 언어모델을 위한
                    사전학습 시스템 및 방법
                  </td>
                </tr>
                <tr>
                  <td className="center">2018-0133422​​</td>
                  <td className="center">2018.11.02​</td>
                  <td>
                    온라인 백과 사전 기반 신규 개체명 및 신규 개체명의 이형태
                    사전 구축 장치 및 방법
                  </td>
                </tr>
                <tr>
                  <td className="center">2019-0142837​​</td>
                  <td className="center">2019.11.08​</td>
                  <td>법령 분야 질의 응답 방법 및 장치</td>
                </tr>
                <tr>
                  <td className="center">2020-0002587​​</td>
                  <td className="center">2020.01.08​</td>
                  <td>
                    유사 발명 기술문서를 통합한 가상 복합 기술문서를 이용한 발명
                    기술 검색 시스템 및 방법
                  </td>
                </tr>
                <tr>
                  <td className="center">
                    2017-0159871
                    <br />
                    (2072238)​​
                  </td>
                  <td className="center">
                    2017.11.28
                    <br />
                    (2020.01.23)​
                  </td>
                  <td>신뢰도 기반 질의응답 시스템 및 방법</td>
                </tr>
              </tbody>
            </table>

            <table
              className="colorBorderTable _table"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <thead>
                <tr>
                  <th>구분</th>
                  <th>
                    출원번호
                    <br />
                    (등록번호)​
                  </th>
                  <th>
                    출원일
                    <br />
                    (등록일)​
                  </th>
                  <th>특허 명칭</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="otherColor center" rowSpan={4}>
                    AI 보안 기술​
                    <br />
                    (TRL 6단계)​
                  </td>
                  <td className="center">2019-0008851​</td>
                  <td className="center">2019.01.23</td>
                  <td>마스킹 영역 처리 시스템 및 방법​</td>
                </tr>
                <tr>
                  <td className="center">2019-0042130​</td>
                  <td className="center">2019.04.10​</td>
                  <td>영상 보호 송신장치, 수신장치 및 방법​</td>
                </tr>
                <tr>
                  <td className="center">2020-0173671</td>
                  <td className="center">2020.12.11​</td>
                  <td>
                    외곽선 기반 프라이버시 마스킹 장치, 외곽선 기반 프라이버시
                    <br />
                    마스킹 복원 장치 및 프라이버시 마스킹 영역 디스크립터 공유
                    방법
                  </td>
                </tr>
                <tr>
                  <td className="center">16/749762</td>
                  <td className="center">2020.01.22</td>
                  <td>System and Method of Processing Masking Region</td>
                </tr>
                <tr>
                  <td className="otherColor center" rowSpan={8}>
                    AI 물류 배송기술
                    <br />
                    (TRL 6단계)
                  </td>
                  <td className="center">2017-0038481​</td>
                  <td className="center">2017.03.27</td>
                  <td>무인 항공기를 이용한 물류 협업 배송 방법 및 장치</td>
                </tr>
                <tr>
                  <td className="center">2017-0040866</td>
                  <td className="center">2017.03.30​</td>
                  <td>무인 배송 운영 방법 및 장치</td>
                </tr>
                <tr>
                  <td className="center">
                    15/938963
                    <br />
                    (10586460)
                  </td>
                  <td className="center">
                    2018.03.28
                    <br />
                    (2020.03.10)
                  </td>
                  <td>
                    무인비행체에 의한 안전운영 고도 생성 결과기반 우편물 배송
                    <br />
                    시스템 및 그 제어 방법​
                  </td>
                </tr>
                <tr>
                  <td className="center">2020-0059542</td>
                  <td className="center">2020.05.19​</td>
                  <td>무인 배송 관리 장치 및 방법</td>
                </tr>
                <tr>
                  <td className="center">17/323538</td>
                  <td className="center">2021.05.18</td>
                  <td>
                    최적의 배송경로 생성 방법을 적용한 드론 배송 시스템 및 그
                    제어 방법
                  </td>
                </tr>
                <tr>
                  <td className="center">2020-0156999</td>
                  <td className="center">2020.11.20​</td>
                  <td>드론을 이용한 무인 물품 공급 시스템</td>
                </tr>
                <tr>
                  <td className="center">17/477919</td>
                  <td className="center">2021.09.08.​</td>
                  <td>
                    고중량 물품 배송용 드론 착륙 및 무인택배함 물품 공급 장치 및
                    방법
                  </td>
                </tr>
                <tr>
                  <td className="center">2020-0189696</td>
                  <td className="center">2020.12.31​</td>
                  <td>
                    다중 센서 정보 LSTM 기반의 무인 로봇 충돌 감지/경고 방법 및
                    시스템
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* section 05 E */}

        {/* section 06 S */}
        <div className="wrap">
          <div className="contentArea bar">
            <div className="contentTitle">찾아오시는 길</div>
          </div>
          <div className="mapTab">
            <div
              className={classNames("_tab", { on: isMap == 0 })}
              onClick={() => {
                setMap(0);
              }}
            >
              ABC(AI-빅데이터센터)
            </div>
            <div
              className={classNames("_tab", { on: isMap == 1 })}
              onClick={() => {
                setMap(1);
              }}
            >
              TCC(기술사업화센터)
            </div>
            {/* <div className={classNames("_tab", { on: isMap == 2 })}
                            onClick={() => {
                                setMap(2)
                            }}
                        >
                            D.Factory
                        </div> */}
          </div>
          <div>
            {isMap == 0 && <Map1 />}
            {isMap == 1 && <Map2 />}
            {/* {isMap == 2 && <Map3 />} */}
          </div>
        </div>
        {/* section 06 E */}
      </div>
    </React.Fragment>
  );
}

export default Intro;
