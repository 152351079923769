import React, { useState, useCallback, useEffect, useRef } from "react";

const { kakao } = window;

function Map1() {
    useEffect(() => {
        mapscript();
    }, []);

    const mapscript = () => {
        let container = document.getElementById("map");
        let options = {
            center: new kakao.maps.LatLng(37.39432776461827, 126.97009595909611),
            level: 2,
        };
        //map
        const map = new kakao.maps.Map(container, options);

        //마커가 표시 될 위치
        let markerPosition = new kakao.maps.LatLng(
            37.39432776461827,
            126.97009595909611
        );

        // 마커를 생성
        let marker = new kakao.maps.Marker({
            position: markerPosition,
        });

        // 마커를 지도 위에 표시
        marker.setMap(map);
    };
    return (
        <div
            style={{
                width: '100%',
                display: 'inline-block',
            }}
        >
            <div id="map" style={{ width: '100rea%', height: '500px' }}></div>
            <div className="mapAddress">
                D.Factory : 경기도 안양시 동안구 벌말로 66, 평촌하이필드 3층
            </div>
        </div>
    );
}

export default Map1;