import axios from "axios";
import "view/style/css/intro.css";
import classNames from "classnames";
import Nav from "view/parts/nav/nav";
import { useMutation } from "react-query";
import React, { useEffect, useState } from "react";
import { localDatetimeRenderer } from "view/utils";
import { Link, useNavigate } from "react-router-dom";

function Notice() {
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(0);
    const [noticeList, setNoticeList] = useState(undefined);
    const getNoticeList = async (req) => {
        const response = await axios.post(`${process.env.REACT_APP_API}/serviceCenter/gaionNotice/${req.page}`, req)
        return response
    }

    const mutation = useMutation({
        mutationFn: (req) => getNoticeList(req),
        onSuccess: (result) => {
            setNoticeList(result.data.value);
        }
    })

    const idRenderer = (total, index, currentPage) => {
        return total - (currentPage * 10 + index)
    }

    const onClickPageMove = (page) => {
        setCurrentPage(page);
        mutation.mutate({ page: page });
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    useEffect(() => {
        mutation.mutate({ page: 0 });
    }, [])

    return (
        <React.Fragment>
            <div className="content-inseide notice en">
                <div className="wrap">
                    <div className="contentArea">
                        <div className="contentTitle" data-aos="fade-up" data-aos-duration="500">NOTICE</div>
                    </div>
                    <Nav />
                    <div className="gridTable">
                        <div className="grid-table th">
                            <div className="_table-item">Number </div>
                            <div className="_table-item">Title </div>
                            <div className="_table-item">Writer</div>
                            <div className="_table-item">Date </div>
                        </div>
                        {noticeList && noticeList.content.map((element, index) => (
                            <div className="grid-table td" key={index} onClick={() => navigate(`/company/notice/detail`, { state: { item: element, index: 3 } })}>
                                <div className="_table-item">{idRenderer(noticeList.totalElements, index, currentPage)}</div>
                                <div className="_table-item">
                                    <Link to="">{element.announcement_title}</Link>
                                </div>
                                <div className="_table-item">관리자</div>
                                <div className="_table-item">{localDatetimeRenderer(element.created_date, "dateFormat")}</div>
                            </div>
                        ))}
                    </div>
                    <div className="paging">
                        {noticeList && (
                            <ul>
                                <p
                                    onClick={() => currentPage > 0 && onClickPageMove(currentPage - 1)}
                                    style={{ color: currentPage === 0 ? "#c0c0c0" : "#000000", cursor: currentPage === 0 ? "not-allowed" : "pointer" }}>
                                    {"<"}
                                </p>
                                {Array.from({ length: noticeList.totalPages }, () => undefined).map((_, index) => (
                                    <li className={classNames(null, { active: index === currentPage })} key={index} onClick={() => onClickPageMove(index)}><span>{index + 1}</span></li>
                                ))}
                                <p
                                    onClick={() => currentPage < noticeList.totalPages - 1 && onClickPageMove(currentPage + 1)}
                                    style={{ color: currentPage === noticeList.totalPages - 1 ? "#c0c0c0" : "#000000", cursor: currentPage === noticeList.totalPages - 1 ? "not-allowed" : "pointer" }}>
                                    {">"}
                                </p>
                            </ul>
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Notice;