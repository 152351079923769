import React from "react";
import "view/style/css/solution.css";
import Nav from "view/parts/nav/nav";

const Sop = () => {
  return (
    <React.Fragment>
      <div className="content-inseide solution">
        <div className="wrap">
          {/* <div className="contentArea">
            <div className="contentTitle">
              <div data-aos="fade-up" data-aos-duration="500">
                SentriX
              </div>
            </div>
          </div>
          <Nav /> */}
          <div className="typing max-width">
            <div
              className="_typing-title  center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img
                src="/images/solution/SentriX_SOP_Black.png"
                style={{ width: "300px" }}
                alt="sop logo"
              />
            </div>
            <div
              className="_typing-text center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              It is a security control operation portal solution that provides
              optimized facilities for security operations.
            </div>
          </div>
          <div
            className="introBox"
            style={{ padding: "50px 0" }}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="imgScroll">
              <div
                className="wrap esonImage"
                style={{ width: "unset", overflow: "hidden" }}
              >
                <img src="/images/solution/sop_01.jpg" alt="SOP 소개" />
              </div>
            </div>
          </div>
          <div className="typing max-width">
            <div
              className="_typing-title  center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Security work efficiency / advancement tool
            </div>
            <div
              className="_typing-text center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              SOP is an advanced security control work portal solution to manage
              and strengthen the performance of an organization's IT security
              activities.
              <br />
              It enables more efficient and advanced security control through
              its own specialized functions.
            </div>
          </div>
          <div
            className="introBox"
            style={{ padding: "50px 0" }}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="imgScroll">
              <div
                className="wrap esonImage"
                style={{ width: "unset", overflow: "hidden" }}
              >
                <img src="/images/solution/sop_02.jpg" alt="SOP 소개" />
              </div>
            </div>
          </div>
          <div className="typing max-width">
            <div
              className="_typing-title  center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img
                src="/images/solution/SentriX_Black.png"
                style={{ width: "200px", marginRight: "20px" }}
                alt="sop logo"
              />
              System Architecture
            </div>
            <div
              className="_typing-text center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              Sentrix's system architecture is structured to deliver collected
              events from the event collection area to the ticket processing
              area through pre-processing, automatic ticket merging, ticket
              history, asset and threat information matching.
            </div>
          </div>
          <div
            className="introBox"
            style={{ padding: "50px 0" }}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="imgScroll">
              <div
                className="wrap esonImage"
                style={{ width: "unset", overflow: "hidden" }}
              >
                <img src="/images/solution/sop_03.jpg" alt="SOP 소개" />
              </div>
            </div>
          </div>
          <div className="typing max-width">
            <div
              className="_typing-title  center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img
                src="/images/solution/SentriX_Black.png"
                style={{ width: "200px", marginRight: "20px" }}
                alt="sop logo"
              />
              AI Approach
            </div>
            <div
              className="_typing-text center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              We avoid supervised learning, which requires a lot of data
              labeling, and pursue an AI architecture that reduces control tasks
              and helps users make decisions.
            </div>
          </div>
          <div
            className="introBox"
            style={{ padding: "50px 0" }}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="imgScroll">
              <div
                className="wrap esonImage"
                style={{ width: "unset", overflow: "hidden" }}
              >
                <img src="/images/solution/sop_04.jpg" alt="SOP 소개" />
              </div>
            </div>
          </div>
          <div className="typing max-width">
            <div
              className="_typing-title  center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img
                src="/images/solution/SentriX_Black.png"
                style={{ width: "200px", marginRight: "20px" }}
                alt="sop logo"
              />
              Expected effects upon introduction
            </div>
            <div
              className="_typing-text center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              Sentrix provides integrated security control knowledge information
              and ticket processes through functions and screen configurations
              optimized for the security control work process, and can quickly
              determine threats and respond to infringements by linking AI and
              knowledge information.
            </div>
          </div>
          <div
            className="introBox"
            style={{ padding: "50px 0" }}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="imgScroll">
              <div
                className="wrap esonImage"
                style={{ width: "unset", overflow: "hidden" }}
              >
                <img src="/images/solution/sop_05.jpg" alt="SOP 소개" />
              </div>
            </div>
          </div>
          <div className="Link">
            Contact: <span>sales@gaion.kr</span> (Sales Team)
            <br />
            Tel : <span>02.2051.9595</span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Sop;
