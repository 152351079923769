import React from "react";
import "view/style/css/acoustic.css";
import Nav from "view/parts/nav/nav";

function Teradata() {
  return (
    <React.Fragment>
      <div className="content-inseide acoustic en">
        <div className="wrap">
          <div className="contentArea">
            <div
              className="contentTitle"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              Teradata
            </div>
          </div>
          <Nav />
        </div>
        <div className="wrap">
          <div className="typing max-width">
            <div
              className="_typing-title  center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Vantage QueryGrid™
              <div className="smallBig">Query Virtualization for Data Hub</div>
            </div>
            <div
              className="_typing-text center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              As the company grows, the amount of data managed by each
              department inevitably increases.
              <br />
              By integrating this, the company's new In order to derive
              insights, ETL (Extract, Transform,
              <br />
              Load / Extract, Transform, Summarize) data is used as before. You
              don't have to. Put the data
              <br />
              built by each department in place and create a virtual database in
              memory. You can create and
              <br />
              connect departmental data and use it as if it were a single
              database. that skill is Data Virtualization
              <br />
              provided by Teradata's QueryGrid™. For less with Teradata's
              QueryGrid™ Get unprecedented insights from your own data.
            </div>
          </div>
          <div className="introBox">
            <div className="imgScroll">
              <div className="wrap esonImage">
                <img src="/images/tera_01.jpg" />
              </div>
            </div>
          </div>
          <div className="typing max-width">
            <div
              className="_typing-title  center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Vantage & Object Storage
              <div className="smallBig">Data Lakehouse</div>
            </div>
            <div
              className="_typing-text center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              A data lakehouse combines the flexibility and scalability of a
              data lake with the data structure and data management capabilities
              of a data warehouse. An open data management architecture. A data
              lake stores large amounts of raw data as it is, making it
              difficult to use AI and ML algorithms. Although it has strengths,
              it does not meet data quality and governance requirements, so
              there are problems such as incomplete or redundant data.
              Teradata's Vantage & Object Storage addresses data lake
              reliability, performance, governance, and quality issues. ACID
              (Atomicity Consistency, Isolation and Durability) transaction
              guarantee, indexing and concurrency control, table and column
              access control, Resolving this through schema validation, etc. If
              you want to effectively collect, manage, and utilize data from
              each department in the company We recommend building a data
              lakehouse with Vantage & Object Storage along with Teradata's
              QueryGrid™.
            </div>
          </div>
          <div className="introBox">
            <div className="imgScroll">
              <div className="wrap esonImage">
                <img src="/images/tera_02.jpg" />
              </div>
            </div>
          </div>
          <div className="Link">
            Contact: <span>sales@gaion.kr</span> (Sales Team)
            <br />
            Tel : <span>02.2051.9595</span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Teradata;
