export const frame = "1fr ";
export const menuItems = (locale) => {
  return [
    {
      title: "Company",
      description:
        locale === "kr"
          ? "가이온은 사람과 AI를 연결하는 'AI Linktech' 기업입니다."
          : "Gaion is an 'AI Linktech' company that connects people to AI.",
      link: locale === "kr" ? "/company/company" : `/${locale}/company/company`,
      bg: "/images/company.jpg",
      list: [
        {
          name: "Overview",
          link:
            locale === "kr" ? "/company/company" : `/${locale}/company/company`,
        },
        {
          name: "Client",
          link:
            locale === "kr" ? "/company/client" : `/${locale}/company/client`,
        },
        {
          name: "CEO’s Greeting",
          link: locale === "kr" ? "/company/ceo" : `/${locale}/company/ceo`,
        },
        {
          name: "Notice",
          link:
            locale === "kr" ? "/company/notice" : `/${locale}/company/notice`,
        },
      ],
    },
    {
      title: "AI Platforms",
      description:
        locale === "kr"
          ? "가이온의 AI 머신러닝, 영상지능, 언어지능은 기업에 최적화 된 AI 기술 활용 환경을 구현합니다."
          : "Gaion's AI machine learning, video intelligence, and language intelligence implement an environment that utilizes AI technology optimized for companies.",
      link: locale === "kr" ? "/platform/ai" : `/${locale}/platform/ai`,
      bg: "/images/platforms.jpg",
      list: [
        {
          name: "gSmart AI",
          link: locale === "kr" ? "/platform/ai" : `/${locale}/platform/ai`,
        },
        {
          name: "gSmart VISION",
          link:
            locale === "kr" ? "/platform/vision" : `/${locale}/platform/vision`,
        },
        {
          name: "gSmart BRAIN",
          link:
            locale === "kr" ? "/platform/brain" : `/${locale}/platform/brain`,
        },
      ],
    },
    {
      title: "AI Solutions",
      description:
        locale === "kr"
          ? "가이온의 AI 솔루션은 보안, 디지털 마케팅, 데이터 관리, 클라우드, 전기절감 영역에서의 성과를 극대화 합니다."
          : "AI solutions from Gaion maximize performance in areas of security, digital marketing, data management, cloud, and power savings",
      link: locale === "kr" ? "/bigdata/splunk" : `/${locale}/bigdata/splunk`,
      bg: "/images/solution.jpg",
      list: [
        {
          name: "Splunk",
          link:
            locale === "kr" ? "/bigdata/splunk" : `/${locale}/bigdata/splunk`,
        },
        {
          name: "SentriX SOP",
          link: locale === "kr" ? "/bigdata/sop" : `/${locale}/bigdata/sop`,
        },
        {
          name: "SentriX ADS",
          link: locale === "kr" ? "/bigdata/ads" : `/${locale}/bigdata/ads`,
        },
        {
          name: "SentriX EDC",
          link: locale === "kr" ? "/bigdata/edc" : `/${locale}/bigdata/edc`,
        },
        {
          name: "Teradata",
          link:
            locale === "kr"
              ? "/bigdata/teradata"
              : `/${locale}/bigdata/teradata`,
        },
        {
          name: "gSmart MES",
          link: locale === "kr" ? "/bigdata/mes" : `/${locale}/bigdata/mes`,
        },
        {
          name: "Cato Networks",
          link: locale === "kr" ? "/bigdata/cato" : `/${locale}/bigdata/cato`,
        },
      ],
    },
    {
      title: "AI Trade",
      description:
        locale === "kr"
          ? "가이온의 AI 기반 수출지원 플랫폼인 WeTrade는 더 넓은 세계 시장과 중소기업의 연결을 지원합니다."
          : "Gaion's AI-based export support platform, WeTrade, supports the wider global market and the connectivity of small and medium-sized enterprises.",
      link: locale === "kr" ? "/weTrade/weTrade" : `/${locale}/weTrade/weTrade`,
      bg: "/images/backgorund_intro.png",
      list: [
        {
          name: "WeTrade",
          link:
            locale === "kr" ? "/weTrade/weTrade" : `/${locale}/weTrade/weTrade`,
        },
      ],
    },
    {
      title: "AI Logistics",
      description:
        locale === "kr"
          ? "가이온은 AI 기술을 통해 유통과 드론 인프라 구축을 위해 노력하고 있습니다."
          : "Gaion is trying to build distribution and drone infrastructure through AI technology.",
      link: locale === "kr" ? "/dron/dron" : `/${locale}/dron/dron`,
      bg: "/images/dron.jpg",
      list: [
        {
          name: "the Dron",
          link: locale === "kr" ? "/dron/dron" : `/${locale}/dron/dron`,
        },
      ],
    },
    {
      title: "Voucher",
      description:
        locale === "kr"
          ? "가이온은 믿을 수 있는 바우처 사업 파트너 입니다."
          : "Gaion is a reliable voucher business partner",
      link: locale === "kr" ? "/voucher/data" : `/${locale}/voucher/data`,
      bg: "/images/dron.jpg",
      list: [
        {
          name: "Data Voucher",
          link: locale === "kr" ? "/voucher/data" : `/${locale}/voucher/data`,
        },
        {
          name: "AI Voucher  ",
          link: locale === "kr" ? "/voucher/ai" : `/${locale}/voucher/ai`,
        },
      ],
    },
  ];
};
