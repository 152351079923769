import React from "react";
import "view/style/css/solution.css";
import Nav from "view/parts/nav/nav";
import * as IconHi from "react-icons/hi";
import { ArrowButton } from "view/component/arrowBtn";

function Wetrade({ props }) {
    return (
        <React.Fragment>
            <div className="content-inseide solution en">
                <div className="wrap">
                    <div className="right">
                        <a href="https://we-trade.co.kr/" target={"_blank"}
                            style={{ fontSize: "22px", fontWeight: "600", color: "#000" }}
                        ><IconHi.HiHome style={{ fontSize: "25px", marginRight: 10, verticalAlign: "sub" }} />Go to WeTrade</a>
                    </div>
                    <div className="contentArea">
                        <div className="contentTitle">
                            <div data-aos="fade-up" data-aos-duration="500">WeTrade</div>
                        </div>
                    </div>
                    <Nav />
                    <div className="typing">
                        <div className="_typing-title center" data-aos="fade-up" data-aos-duration="1000">
                            O2O import and export platform linked with offline exhibitions
                        </div>
                        <div className="_typing-text center" data-aos="fade-up" data-aos-duration="1000" >
                            Buyer data construction and buyer matching through participation in overseas exhibitions
                        </div>
                    </div>
                    <div className="introBox" style={{ padding: "0" }} >
                        <div className="imgScroll">
                            <div className="wrap esonImage">
                                <img src="/images/wetrade_05_eng.png" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* step 2 S */}
                <div className="grayBox">
                    <div className="wrap">
                        <div className="typing">
                            <div className="contentArea bar">
                                <div className="contentTitle" data-aos="fade-up" data-aos-duration="500" style={{ marginTop: "0" }}>
                                    AI-based import and export platform
                                </div>
                            </div>
                            <div className="_typing-title center" data-aos="fade-up" data-aos-duration="1000">
                                Provides the most convenient and easiest transaction support function<br />
                                that minimizes the workload of member companies
                            </div>
                            <div className="_typing-text center" data-aos="fade-up" data-aos-duration="1000" >
                                It provides optimal functions to support import and export activities of small and medium-sized enterprises.
                            </div>
                        </div>
                        <div className="grid five" >
                            <div className="_five-item">
                                <img src="/images/wetrade/wetrade_retouch_01.png" />
                                <div className="_five-text">
                                    <div className="_five-title">
                                        Provide introduction<br />
                                        page of company
                                    </div>
                                    <div className="_five-content">
                                        Seller is separate provide company introduction page
                                    </div>
                                </div>
                            </div>
                            <div className="_five-item">
                                <img src="/images/wetrade/wetrade_retouch_02.png" />
                                <div className="_five-text">
                                    <div className="_five-title">
                                        Offer matching algorithm
                                    </div>
                                    <div className="_five-content">Provides new matching data through a matching algorithm whenever a member joins</div>
                                </div>
                            </div>
                            <div className="_five-item">
                                <img src="/images/wetrade/wetrade_retouch_03.png" />
                                <div className="_five-text">
                                    <div className="_five-title">CS work<br /><br /></div>
                                    <div className="_five-content">
                                        operation of the integrated CS center
                                        Reducing member companies’ work through
                                    </div>
                                </div>
                            </div>
                            <div className="_five-item">
                                <img src="/images/wetrade/wetrade_retouch_04.png" />
                                <div className="_five-text">
                                    <div className="_five-title">Offer latest data</div>
                                    <div className="_five-content">
                                        When updating product information and registering RFQ
                                        Provide matching information in real time
                                    </div>
                                </div>
                            </div>
                            <div className="_five-item">
                                <img src="/images/wetrade/wetrade_retouch_05.png" />
                                <div className="_five-text">
                                    <div className="_five-title">
                                        Vendor promotion
                                    </div>
                                    <div className="_five-content">
                                        Bulk mail to matching buyers
                                        Provide automatic sending function
                                    </div>
                                </div>
                            </div>
                            <div className="_five-item">
                                <img src="/images/wetrade/wetrade_retouch_06.png" />
                                <div className="_five-text">
                                    <div className="_five-title">
                                        Customs Clearance/Delivery
                                        business agency
                                    </div>
                                    <div className="_five-content">
                                        Through linkage with specialized companies
                                        Easy import and export service
                                    </div>
                                </div>
                            </div>
                            <div className="_five-item">
                                <img src="/images/wetrade/wetrade_retouch_07.png" />
                                <div className="_five-text">
                                    <div className="_five-title">Marketing support<br /><br /></div>
                                    <div className="_five-content">
                                        Overseas exhibitions, buyer briefings, etc.
                                        Provision of opportunities to promote member companies through
                                    </div>
                                </div>
                            </div>
                            <div className="_five-item">
                                <img src="/images/wetrade/wetrade_retouch_08.png" />
                                <div className="_five-text">
                                    <div className="_five-title">EVENT<br /><br /></div>
                                    <div className="_five-content">
                                        Event function by member company
                                        Various promotional support by providing
                                    </div>
                                </div>
                            </div>
                            <div className="_five-item">
                                <img src="/images/wetrade/wetrade_retouch_09.png" />
                                <div className="_five-text">
                                    <div className="_five-title">Link with 3’rd Party</div>
                                    <div className="_five-content">
                                        External Expertise for Increased Convenience
                                        Providing convenience functions through company sourcing
                                    </div>
                                </div>
                            </div>
                            <div className="_five-item">
                                <img src="/images/wetrade/wetrade_retouch_10.png" />
                                <div className="_five-text">
                                    <div className="_five-title">
                                        Sales & Settlement system
                                    </div>
                                    <div className="_five-content">
                                        With a systematic sales & settlement system
                                        Easy to manage
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* step 2 E */}
                {/* step 3 S */}
                <div className="wrap">
                </div>
                {/* step 3 E */}
                {/* step 4 S */}
                <div className="wrap">
                    <div className="typing">
                        <div className="contentArea bar">
                            <div className="contentTitle" data-aos="fade-up" data-aos-duration="500">An import/export platform that provides the highest user convenience</div>
                        </div>
                        <div className="_typing-text center" data-aos="fade-up" data-aos-duration="1000" >
                            Designed to enable flexible platform function expansion by combining ETRI's AI solution with the latest technology
                        </div>
                    </div>
                    <div className="introBox" >
                        <div className="imgScroll">
                            <div className="wrap esonImage">
                                <img src="/images/wetrade_section_03_en.png" />
                            </div>
                        </div>
                    </div>
                    <div className="blueBox" >
                        <div className="_blue-left">
                            <div className="_blue-title">
                                Register your product on WeTrade and meet<br />
                                buyers from all over the world!
                            </div>
                            <div className="_blue-content">
                                WeTrade connects sellers and buyers around the world, and registers products<br />
                                overseas/domestically with one product registration. We support you to sell<br />
                                your products in the open market.
                            </div>
                            <ArrowButton title={"Product Inquiry"} link={{ link: "/company/contact", state: { index: 2, prev: 1 } }} />
                        </div>
                        <div className="_blue-right">
                            <img src="/images/wetrade_02.png" />
                        </div>
                    </div>
                </div>
                {/* step 4 E */}
                {/* step 5 S */}
                <div className="wrap">
                    <div className="typing">
                        <div className="contentArea bar">
                            <div className="contentTitle" data-aos="fade-up" data-aos-duration="500">
                                Application of a customized search algorithm that minimizes the workload of member companies
                            </div>
                        </div>
                        <div className="_typing-text center" data-aos="fade-up" data-aos-duration="1000" >
                            Using 350 million actual transaction data (B/L) and 2 million foreign company information<br />
                            Minimize product/company search time by recommending promising business information
                        </div>
                    </div>
                    <div className="introBox" >
                        <div className="imgScroll">
                            <div className="wrap esonImage">
                                <img src="/images/wetrade_section_04_eng.png" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* step 5 E */}
                {/* step 6 S */}
                <div className="wrap">
                    <div className="typing">
                        <div className="contentArea bar">
                            <div className="contentTitle" data-aos="fade-up" data-aos-duration="500">
                                Improve search convenience and curation by introducing AI-based Image Detection technology
                            </div>
                        </div>
                        <div className="_typing-text center" data-aos="fade-up" data-aos-duration="1000" >
                            Provides market reports for exported products when taking product photos, and recommends similar products within the platform through<br />
                            an image search function based on object detection technology
                        </div>
                    </div>
                    <div className="introBox" >
                        <div className="imgScroll">
                            <div className="wrap esonImage">
                                <img src="/images/wetrade_section_05_eng.png" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* step 6 E */}
                {/* step 7 S */}
                <div className="wrap">
                    <div className="typing">
                        <div className="contentArea bar">
                            <div className="contentTitle" data-aos="fade-up" data-aos-duration="500">
                                A system with XR technology added to support real-time and specific negotiations
                            </div>
                        </div>
                        <div className="_typing-text center" data-aos="fade-up" data-aos-duration="1000" >
                            Introduction of product properties/operating performance using Twin Reality and providing various community functions<br />
                            Provides the same level of negotiation environment as seeing and consulting actual products
                        </div>
                    </div>
                    <div className="introBox" >
                        <div className="imgScroll">
                            <div className="wrap esonImage">
                                <img src="/images/wetrade_section_06_eng.png" />
                            </div>
                        </div>
                    </div>
                    <div className="typing" style={{ display: "flex", justifyContent: "center", gap: 75 }}>
                        <ArrowButton title={"Go to WeTrade"} link={"https://we-trade.co.kr/"} />
                        <ArrowButton title={"Go to WeTrade Mall"} link={"https://www.wetrademall.com/"} />
                    </div>
                </div>
                {/* step 7 E */}
            </div >
        </React.Fragment >
    );
}

export default Wetrade;
