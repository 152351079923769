import React from "react";
import "view/style/css/solution.css";
import Nav from "view/parts/nav/nav";

function Ai() {
    return (
        <React.Fragment>
            <div className="content-inseide solution">
                <div className="wrap">
                    <div className="contentArea">
                        <div className="contentTitle">
                            <div data-aos="fade-up" data-aos-duration="500">gSmart AI</div>
                        </div>
                    </div>
                    <Nav />
                    <div className="typing">
                        <div className="_typing-title center" data-aos="fade-up" data-aos-duration="500">
                            gSmart AI는 데이터 내의 숨겨진 패턴을 발견하고 예측 분석을 할 수 있는<br />
                            AI 기반의 분석 플랫폼입니다.
                        </div>
                        <div className="_typing-text center" data-aos="fade-up" data-aos-duration="800">
                            끊임없이 복잡해지고 다양해져가는 비즈니스 환경에서 지속가능한 경쟁력을 갖추기 위해서는 데이터 기반의 의사결정이 필요합니다.<br />
                            고객 경험, 이용자 모니터링, 위협 및 사기 행위 분석 문제 등 데이터와 그 분석을 통해 비즈니스 인사이트를 얻을 수 있습니다.<br />
                            gSmart AI는 예측/분석에 필요한 모든 정보를 수집하고 이러한 인텔리전스 프로세스로 인해 보다 정밀하고 정확한 예측을 <br />
                            의사 결정자에게 제공합니다.<br />
                            gSmart AI는 다양한 분석 기법을 통해 고객의 조건에 맞는 최적의 분석/예측을 제공합니다.
                        </div>
                    </div>
                </div>
                {/* section 02 S */}
                <div className="grayBox" >
                    <div className="imgScroll">
                        <div className="wrap esonImage" data-aos="fade-up" data-aos-duration="500">
                            <img src="/images/gsmartai_01.png" />
                        </div>
                    </div>
                </div>
                {/* section 02 E */}
                {/* section 03 S */}
                <div className="wrap">
                    <div className="typing">
                        <div className="contentArea bar">
                            <div className="contentTitle" data-aos="fade-up" data-aos-duration="1000">gSmartAI 기대효과</div>
                        </div>
                        <div className="_typing-title center" data-aos="fade-up" data-aos-duration="1200">
                            gSmart AI는 기존 데이터를 기반으로 데이터 전처리, 학습모델 생성, 학습품질평가,<br />
                            탐지수행, 예측 정확도 기반 피드백 단계를 통해 새로운 Rule을 생성, 추천, Simulation 합니다.<br />
                            예측/분석에 최적화된 gSmart AI를 통해 데이터 기반의 의사결정을 할 수 있습니다.
                        </div>
                    </div>
                    <div className="imgScroll">
                        <div className="wrap esonImage" >
                            <img src="/images/gsmartai_02.png" data-aos="fade-up" data-aos-duration="500" />
                        </div>
                    </div>
                    <div className="Link">
                        문의처 : <span>sales@gaion.kr</span> (AI솔루션부문, 영업팀)<br />
                        Tel :  <span>02.2051.9595</span>
                    </div>
                </div>
                {/* section 03 E */}
            </div >
        </React.Fragment >
    );
}

export default Ai;