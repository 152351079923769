import React, {
    useState,
    useEffect,
} from "react";
import "view/style/css/intro.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.core.css";
import { localDatetimeRenderer } from "view/utils";
import { useLocation, useNavigate } from "react-router-dom";

function NoticeDetail() {
    const location = useLocation();
    const navigate = useNavigate();
    const [currentData, setCurrentData] = useState(undefined);

    useEffect(() => {
        if (currentData === undefined) {
            location.state?.item ? setCurrentData(location.state?.item) : setCurrentData({ announcement_title: "No data", announcement_content: "No data" })
        }
    }, [])

    return (
        <React.Fragment>
            <div className="content-inseide notice">
                <div className="wrap">
                    <div className="contentArea">
                        <div className="noticeTitle left" data-aos="fade-up" data-aos-duration="500">
                            {currentData && currentData.announcement_title}
                        </div>
                        <div className="contentDate" data-aos="fade-up" data-aos-duration="1000">
                            작성일 : {localDatetimeRenderer(currentData?.created_date, "dataFormat")}
                        </div>
                    </div>
                    <div className="noticeContent">
                        <ReactQuill
                            readOnly
                            theme="snow"
                            className="editor_quill"
                            style={{ width: '100%' }}
                            modules={{ toolbar: false }}
                            value={currentData && currentData.announcement_content}
                        />
                    </div>
                    <div className="center">
                        <div onClick={() => (
                            navigate("/company/notice", { state: { index: 3 } })
                        )}
                            style={{ cursor: "pointer" }}
                            className="back"
                        >목록으로</div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default NoticeDetail;