import React from "react";
import "view/style/css/solution.css";
import Nav from "view/parts/nav/nav";

const Edc = () => {
  return (
    <React.Fragment>
      <div className="content-inseide solution">
        <div className="wrap">
          {/* <div className="contentArea">
            <div className="contentTitle">
              <div data-aos="fade-up" data-aos-duration="500">
                SentriX
              </div>
            </div>
          </div>
          <Nav /> */}
          <div className="typing max-width">
            <div
              className="_typing-title  center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img
                src="/images/solution/SentriX_EDC_Black.png"
                style={{ width: "300px" }}
                alt="edc logo"
              />
            </div>
            <div
              className="_typing-text center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              엔터프라이즈 데이터 레이크 솔루션
            </div>
            <div
              className="_typing-text center"
              data-aos="fade-up"
              data-aos-duration="800"
              style={{ wordWrap: "break-word" }}
            >
              <img
                src="/images/solution/SentriX_ADS_Black.png"
                style={{ width: "200px", marginRight: "10px" }}
                alt="edc logo"
              />
              는 상용 및 오픈소스 솔루션 뿐만 아니라 RDBMS와 NoSQL등 다양한
              데이터와 연계하는 데이터 브로커입니다.
            </div>
          </div>
          <div
            className="introBox"
            style={{ padding: "50px 0" }}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="imgScroll">
              <div
                className="wrap esonImage"
                style={{ width: "unset", overflow: "hidden" }}
              >
                <img src="/images/solution/edc_01.jpg" alt="edc 소개" />
              </div>
            </div>
          </div>
          <div className="typing max-width">
            <div
              className="_typing-title  center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img
                src="/images/solution/SentriX_ADS_Black.png"
                style={{ width: "200px", marginRight: "10px" }}
                alt="edc logo"
              />
              의 핵심가치(3C)
            </div>
          </div>
          <div
            className="introBox"
            style={{ padding: "50px 0" }}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="imgScroll">
              <div
                className="wrap esonImage"
                style={{ width: "unset", overflow: "hidden" }}
              >
                <img src="/images/solution/edc_02.jpg" alt="edc 소개" />
              </div>
            </div>
          </div>
          <div className="typing max-width">
            <div
              className="_typing-title  center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img
                src="/images/solution/SentriX_ADS_Black.png"
                style={{ width: "200px", marginRight: "10px" }}
                alt="edc logo"
              />
              의 도입 대상 예시
            </div>
            <div
              className="_typing-text center"
              data-aos="fade-up"
              data-aos-duration="800"
              style={{ wordWrap: "break-word" }}
            >
              <img
                src="/images/solution/SentriX_ADS_Black.png"
                style={{ width: "200px", marginRight: "10px" }}
                alt="edc logo"
              />
              대표적으로 Splunk 운영 중이거나 Elastic과 같이 신규 빅데이터
              시스템 도입을 통해
              <br />
              추가적인 데이터 수집 용량을 증설하거나 비용을 절감하고자 하는 고객
            </div>
          </div>
          <div
            className="introBox"
            style={{ padding: "50px 0" }}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="imgScroll">
              <div
                className="wrap esonImage"
                style={{ width: "unset", overflow: "hidden" }}
              >
                <img src="/images/solution/edc_03.jpg" alt="edc 소개" />
              </div>
            </div>
          </div>
          <div className="typing max-width">
            <div
              className="_typing-title  center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img
                src="/images/solution/SentriX_ADS_Black.png"
                style={{ width: "200px", marginRight: "10px" }}
                alt="edc logo"
              />
              의 도입 효과
            </div>
            <div
              className="_typing-text center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <img
                src="/images/solution/SentriX_ADS_Black.png"
                style={{ width: "200px", marginRight: "10px" }}
                alt="edc logo"
              />
              도입만으로 추가 개발 없이 사용자는 기존 UI와 대시보드를 그대로
              사용할 수 있고 비용 절감까지 할 수 있습니다.
            </div>
          </div>
          <div
            className="introBox"
            style={{ padding: "50px 0" }}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="imgScroll">
              <div
                className="wrap esonImage"
                style={{ width: "unset", overflow: "hidden" }}
              >
                <img src="/images/solution/edc_04.jpg" alt="edc 소개" />
              </div>
            </div>
          </div>
          <div className="Link">
            문의처 : <span>sales@gaion.kr</span> (영업팀)
            <br />
            Tel : <span>02.2051.9595</span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Edc;
