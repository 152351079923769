import AOS from "aos";
import Soc from "./soc";
import Mes from "./mes";
import Cato from "./cato";
import "aos/dist/aos.css";
import Splunk from "./splunk";
import Teradata from "./teradata";
import Tab from "view/parts/tab/tab";

import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import NotFound from "view/pages/error/notfound";
import Sop from "./sop";
import Ads from "./ads";
import Edc from "./edc";
import Datalake from "./datalake";
import Distribution from "./distribution";

function Index() {
  useEffect(() => {
    AOS.init();
  });

  return (
    <React.Fragment>
      <Tab currentIndex={2} />
      <Routes>
        <Route path="/splunk" element={<Splunk />} />
        <Route path="/sop" element={<Sop />} />
        <Route path="/ads" element={<Ads />} />
        <Route path="/edc" element={<Edc />} />
        <Route path="/teradata" element={<Teradata />} />
        <Route path="/mes" element={<Mes />} />
        <Route path="/cato" element={<Cato />} />
        {/* <Route path="/datalake" element={<Datalake />} />
        <Route path="/distribution" element={<Distribution />} />
        <Route path="/sentrix" element={<Soc />} /> */}
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </React.Fragment>
  );
}

export default Index;
