import React from "react";
import "view/style/css/acoustic.css";
import Nav from "view/parts/nav/nav";

function Teradata({ props }) {
  return (
    <React.Fragment>
      <div className="content-inseide acoustic">
        <div className="wrap">
          <div className="contentArea">
            <div
              className="contentTitle"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              Teradata
            </div>
          </div>
          <Nav />
        </div>
        <div className="wrap">
          <div className="typing max-width">
            <div
              className="_typing-title  center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Vantage QueryGrid™
              <div className="smallBig">데이터 허브를 위한 쿼리 가상화</div>
            </div>
            <div
              className="_typing-text center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              회사가 성장함에 따라 각 부서 마다 관리하는 데이터들이 늘어날
              수밖에 없습니다. 이를 통합하여 회사의 새로운
              <br />
              인사이트를 이끌어 내기 위해서 예전처럼 데이터를 ETL(Extract,
              Transform, Load / 추출, 변환, 요약)을
              <br />
              하지 않아도 됩니다. 각 부서 별로 구축한 데이터들을 제자리에 두고
              메모리 안의 가상의 데이터베이스를
              <br />
              만들어 부서별 데이터를 연결시켜 마치 하나의 데이터베이스처럼
              활용하면 됩니다. 그 기술이 바로
              <br />
              Teradata의 QueryGrid™가 제공하는 데이터 가상화(Data
              Virtualization)입니다. Teradata의 QueryGrid™를 통해 보다 적은
              비용으로
              <br />
              자사의 데이터에서 이전과는 다른 인사이트를 얻어보세요.
            </div>
          </div>
          <div className="introBox">
            <div className="imgScroll">
              <div className="wrap esonImage">
                <img src="/images/tera_01.jpg" />
              </div>
            </div>
          </div>
          <div className="typing max-width">
            <div
              className="_typing-title  center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Vantage & Object Storage
              <div className="smallBig">
                데이터 레이크하우스(Data Lakehouse)
              </div>
            </div>
            <div
              className="_typing-text center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              데이터 레이크하우스는 데이터 레이크의 유연성 및 확장성과 데이터
              웨어하우스의 데이터 구조 및 데이터 관리 기능을 결합한
              <br />
              개방형 데이터 관리 아키텍쳐입니다. 데이터 레이크는 대규모 원시
              데이터를 그대로 저장해 AI 및 ML 알고리즘 이용에는
              <br />
              강점이 있지만 데이터 품질과 거버넌스 요건은 갖추진 않아 부실하거나
              중복되는 데이터 발생 등의 문제가 있습니다.
              <br />
              Teradata의 Vantage & Object Storge는 데이터 레이크의 신뢰성, 성능,
              거버넌스, 품질 문제를
              <br />
              ACID(Atomicity Consistency, Isolation and Durabilty) 트랜젝션
              보장, 인덱싱 및 동시성 제어, 테이블 및 컬럼 접근제어,
              <br />
              스키마 유효성 검증 등을 통해 이를 해결합니다. 사내의 각 부서의
              데이터들을 효과적으로 수집, 관리, 활용을 원하신다면
              <br />
              Teradata의 QueryGrid™와 함께 Vantage & Object Storage로 데이터
              레이크하우스 구축을 권해드립니다.
            </div>
          </div>
          <div className="introBox">
            <div className="imgScroll">
              <div className="wrap esonImage">
                <img src="/images/tera_02.jpg" />
              </div>
            </div>
          </div>
          <div className="Link">
            문의처 : <span>sales@gaion.kr</span> (영업팀)
            <br />
            Tel : <span>02.2051.9595</span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Teradata;
