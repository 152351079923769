import * as React from "react";
const SVGComponent = (props) => (
    <svg
        id="\uB808\uC774\uC5B4_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 660.66 272.81"
        style={{
            enableBackground: "new 0 0 660.66 272.81",
        }}
        xmlSpace="preserve"
        {...props}
    >
        <g>
            <path d="M358.47,58.9c-7.84-7.27-17.07-10.9-27.68-10.9c-10.61,0-19.9,3.63-27.85,10.9s-14.13,17.53-18.51,30.79 c-4.38,13.27-6.57,28.78-6.57,46.54c0,17.76,2.19,33.28,6.57,46.54c4.38,13.27,10.55,23.53,18.51,30.79s17.24,10.9,27.85,10.9 c10.61,0,19.84-3.63,27.68-10.9c7.84-7.27,13.95-17.53,18.34-30.79c4.38-13.26,6.57-28.77,6.57-46.54 c0-17.76-2.19-33.27-6.57-46.54C372.42,76.43,366.31,66.16,358.47,58.9z" />
            <polygon points="129.76,162.53 129.76,86.06 73.36,162.53  " />
            <polygon points="568.83,162.53 568.83,86.06 512.44,162.53  " />
            <path d="M0.18,0.08v272.65h660.87V0.08H0.18z M203.46,203.01h-27.33v60.55h-46.36v-60.55H22.16l-3.46-40.48L131.84,9.59h44.29 v152.93h27.33V203.01z M417.81,203.18c-8.42,19.72-20.24,35.06-35.46,46.02c-15.22,10.96-32.41,16.44-51.55,16.44 c-19.38,0-36.62-5.48-51.73-16.44c-15.11-10.95-26.93-26.3-35.47-46.02c-8.54-19.72-12.8-42.04-12.8-66.95s4.27-47.17,12.8-66.78 c8.53-19.6,20.35-34.89,35.47-45.85c15.1-10.95,32.35-16.44,51.73-16.44s36.62,5.48,51.73,16.44 c15.1,10.96,26.87,26.24,35.29,45.85c8.42,19.61,12.63,41.87,12.63,66.78S426.22,183.46,417.81,203.18z M642.53,203.01H615.2v60.55 h-46.36v-60.55H461.23l-3.46-40.48L570.91,9.59h44.29v152.93h27.33V203.01z" />
        </g>
    </svg>
);
export default SVGComponent;
