import React, {
    useState,
    useEffect,
} from "react";
import "view/style/css/intro.css";
import classNames from "classnames";
import * as Io from "react-icons/io";
import { menuItems } from "./tabList";
import AutoHeight from "react-auto-height";
import { Link, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { localeInfo } from "view/utils";

function MobileTab(props) {
    const { currentIndex, isChange, propChange } = props;
    const location = useLocation();
    const locale = useRecoilValue(localeInfo);
    const [isSub, setSub] = useState(false);
    const [isMenu, setMenu] = useState(false);
    const [isNow, setNow] = useState(undefined);
    const [change, setChange] = useState(false);
    const [menuClick, setMenuClick] = useState(location.state?.index);

    useEffect(() => {
        const locationConfirm = location.state?.index ? location.state.index : 0;

        menuItems(locale)[currentIndex].list.map((element) => {
            if (location.pathname.split("/")[2] === element.link.split("/")[2]) {
                setNow(element);
                const timeSet = setInterval(() => {
                    setChange(true);
                    setMenuClick(locationConfirm)
                    clearInterval(timeSet);
                }, 50)
            }
        })
    }, [location.pathname]);

    return (
        <React.Fragment>
            <div className="mobileNewTab">
                <div className="nowLocation">
                    <div className="title"
                        onClick={
                            () => {
                                setMenu(!isMenu)
                                setSub(false)
                            }
                        }
                    >
                        <div className="title_flex">
                            {isNow?.name}
                            <React.Fragment>
                                <Io.IoIosArrowDropdownCircle />
                            </React.Fragment>
                        </div>
                        <AutoHeight>
                            {isMenu == true && (
                                <ul
                                    className="tabMenu"
                                >
                                    {menuItems(locale)[currentIndex].list.map((item, i) => (
                                        <React.Fragment key={i}>
                                            <li>
                                                <Link to={item.link} state={{ index: i, prev: menuClick }}
                                                    className={classNames({
                                                        on: menuClick === i && change
                                                    })}
                                                    onClick={() => {
                                                        setMenuClick(i);
                                                    }}
                                                >{item.name}
                                                </Link>
                                            </li >
                                        </React.Fragment >
                                    ))
                                    }
                                </ul >
                            )}
                        </AutoHeight>
                    </div>
                    {menuItems(locale)[currentIndex]?.list[menuClick]?.menu && (
                        <div className="title"
                            onClick={
                                () => {
                                    setMenu(false)
                                    setSub(!isSub)
                                }
                            }
                        >
                            <div className="title_flex">
                                {isChange?.name}
                                <React.Fragment>
                                    <Io.IoIosArrowDropdownCircle />
                                </React.Fragment>
                            </div>
                            <AutoHeight>
                                {isSub == true && (
                                    <ul
                                        className="tabMenu"

                                    >
                                        {menuItems(locale)[currentIndex].list[menuClick].menu.map((_menu, index) => (
                                            <React.Fragment key={index}>
                                                <li className={classNames("subMenu",
                                                    {
                                                        add: isChange === _menu?.key
                                                    })}
                                                    onClick={() => {
                                                        propChange(_menu);
                                                    }}
                                                >{_menu.name}</li>
                                            </React.Fragment>
                                        ))}
                                    </ul>
                                )}
                            </AutoHeight>
                        </div>
                    )}
                </div>
            </div>
        </React.Fragment >
    );
}

export default MobileTab;