import AOS from "aos";
import "aos/dist/aos.css";
import Gaion from "./sub4";
import {CEO} from "./ceo";
import Client from "./client";
import Notice from "./notice";
import Introduce from "./intro";
import Contact from "./contact";
import Tab from "view/parts/tab/tab";

import React, {useEffect} from "react";
import NoticeDetail from "./noticeDetail";
import {Route, Routes} from "react-router-dom";
import NotFound from "view/pages/error/notfound";

function Index() {

    useEffect(() => {
        AOS.init();
    });

    return (
        <React.Fragment>
            <Tab currentIndex={0}/>
            <Routes>
                <Route path="/company" element={<Introduce/>}/>
                <Route path="/client" element={<Client/>}/>
                <Route path="/ceo" element={<CEO/>}/>
                <Route path="/contact" element={<Contact/>}/>
                <Route path="/gaion" element={<Gaion/>}/>
                <Route path="/notice" element={<Notice/>}/>
                <Route path="/notice/detail" element={<NoticeDetail/>}/>
                <Route path="/*" element={<NotFound/>}/>
            </Routes>
        </React.Fragment>
    );
}

export default Index;
