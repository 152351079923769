import React from "react";
import "view/style/css/voucher.css";
import Nav from "view/parts/nav/nav";
import * as IconHi from "react-icons/hi";
import { ArrowButton } from "view/component/arrowBtn";

function voucher({ props }) {
    return (
        <React.Fragment>
            <div className="content-inseide solution">
                <div className="wrap">

                    <div className="contentArea">
                        <div className="contentTitle">
                            <div data-aos="fade-up" data-aos-duration="500">Data Voucher</div>
                        </div>
                    </div>
                    <Nav />


                </div>

                <div className="wrap">
                    <div className="inner_center">
                        <div className="img_box center">
                            <a href="https://forms.gle/qFT3u6bbtkxAs7Cj6"
                                target="_blank"><img src="/images/voucher/voucher_icon_0322_re.png" alt="voucher_img"
                                    className="img-responsive center_img" /></a>

                        </div>
                        <br />

                        <div className="inner_section">
                            <h4><br />
                            </h4>
                            <h3>데이터바우처</h3>
                            <h5>
                                바우처 사업은 산업 활성화 생태계 조성 및 기술 활용에 어려움을 겪는<br />
                                중소, 벤처, 스타트업, 소상공인 등을 돕기 위해 국가에서 빅데이터와  AI 등의<br />
                                기술활용 비용을 지원해주는 사업입니다.<br />
                                <br />
                                가이온은 뛰어난 기술력과 풍부한 기술력을 인정 받아 데이터 바우처와<br />
                                AI 바우처의 공식 공급기관으로 선정되었습니다.
                            </h5>
                            <br />
                            <br />
                            <br />
                            <br />

                            <h3>사업개요</h3>
                            <h5>
                                데이터 구축­·가공·­유통 등 각 단계를 유기적으로 연계하고,<br />
                                데이터 구매·가공을 통한 거래를 확대함으로써 데이터·AI 산업 활성화,<br />
                                생태계 조성 및 데이터활용에 어려움을 겪는 중소·벤처기업, 소상공인 및<br />
                                1인 창조기업, 예비창업자를 대상으로 데이터 구매·가공 바우처를 지원하는 사업입니다.<br />
                                <br />
                                데이터 활용이 꼭 필요한 기업을 대상으로, <br />
                                기존 데이터 활용의 애로사항이었던 데이터 구매, 가공에 소요되는 비용을 지원합니다.    <br />
                            </h5>
                            <div className="voucher_icon">
                                <ul>
                                    <li>
                                        <div className="voucher_cont">
                                            <div className="icon_box"><img src="/images/voucher/voucher_icon01.png"
                                                alt="voucher_icon" /></div>
                                            <p>사업명</p><span>데이터 바우처 지원 사업</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="voucher_cont">
                                            <div className="icon_box"><img src="/images/voucher/voucher_icon02.png"
                                                alt="voucher_icon" /></div>
                                            <p>총 사업비</p><span>20 ~ 25간 총 약 8500억원<br />('23  년 약894억원)</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="voucher_cont">
                                            <div className="icon_box"><img src="/images/voucher/voucher_icon03.png"
                                                alt="voucher_icon" /></div>
                                            <p>지원규모</p><span>데이터 구매 760건<br />일반가공 250건<br />AI가공 1,000건 지원</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="voucher_cont">
                                            <div className="icon_box"><img src="/images/voucher/voucher_icon04.png"
                                                alt="voucher_icon" /></div>
                                            <p>사업추진방법</p><span>데이터 판매, 가공서비스를 제공할<br />공급기업을 모집하고, 이후 바우처활용<br />수요기업을 심사·선정하여
                                                지원</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <h5>
                                데이터바우처 수요기업 등록기간은 보통 매년 초에 진행됩니다.<br />
                                데이터 바우처 지원사업 수요기업 지원, 혹은 관련하여<br />
                                궁금하신 점이 있으신 고객께서는 <span className="color">osm@gaion.kr</span>로 문의 부탁드립니다.
                            </h5>

                        </div>

                        <div className="inner_section">

                            <h3>지원구분</h3>
                            <h5 style={{ marginBottom: "0px" }}>
                                데이터 구매 / 데이터 가공(AI or 일반) 중 택1 하여 지원 가능
                            </h5>
                            <div className="voucher_list01">
                                <ul>
                                    <li>
                                        <div className="voucher_list01_wrap">
                                            <div className="tit">데이터 가공 (AI 가공 6000만원/ 일반 가공 1000만원)</div>
                                            <div className="cont">
                                                AI기반 서비스·제품 개발, 빅데이터 분석, 사회 현안 해결 등에 필요한<br />
                                                인공지능 학습 데이터셋 구축 및 일반가공 비용 지원<br />
                                                -<br />
                                                ※ 데이터를 <b>가명·익명화(비식별) 처리</b>하는 경우도 <br />
                                                <b>데이터 가공 업무의 하나로 간주</b>하여 지원 <br /><br /><br /><br />

                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="voucher_list01_wrap">
                                            <div className="tit">데이터 구매 (1800만원)</div>
                                            <div className="cont">
                                                마케팅 전략 수립, 비즈니스 모델 개발, 창의·도전적인 아이디어 사업화 등<br />
                                                다양한 데이터 활용 목적에 필요한 데이터 구매 지원<br />
                                                -<br />
                                                구매 바우처 1건 당 1800만원 이내로 지원하며,<br />
                                                지원금 內 여러 종류의 데이터 구매 가능 <br />
                                                <br />
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <h5>* 가공바우처는 AI 개발을 위한 데이터셋 구축 등의 경우 1건 당 7000만원 이내 지원하며, 일반적인 빅데이터 분석을 위한 데이터 가공 업무에는 최대 4500만원
                                이내 지원 예정 <br />
                                * 기업매칭방식 지원으로 중소기업은 민간 부담금 20%(단, 현금 2% 必)<br />
                                * 소상공인, 1인 창조기업 민간 부담금 20%(전액 현물 가능) / 예비창업자는 민간 부담금 면제<br /><br />
                            </h5>
                            {/*지원내용 S*/}
                            <h3>지원내용</h3>
                            <h5>
                                여러 산업 분야와 규모에 맞추어 맞춤화된 데이터 서비스를 제공합니다.<br />
                                자세한 내용은 상담을 통해 알아보실 수 있습니다.
                            </h5>

                            <div className="img_box">
                                <img src="/images/voucher/voucher_img04_3.png" alt="voucher_img"
                                    className="img-responsive center_img" />
                            </div>

                            <h6>가이온 데이터 구매 Process</h6>
                            {/*Gird four Box S*/}
                            <div className="voucher_list02">
                                <ul>
                                    <li>
                                        <div className="voucher_list02_wrap">
                                            <div className="tit">01. 고객 안내/응대</div>
                                            <div className="cont">
                                                <p>바우처 안내</p>
                                                <p>문의 고객 응대</p>
                                                <p>Q&amp;A 응대</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="voucher_list02_wrap">
                                            <div className="tit">02. 컨설팅/협의</div>
                                            <div className="cont">
                                                <p>수출입데이터 소개</p>
                                                <p>서비스 협의</p>
                                                <p>데이터 범위 협의</p>
                                                <p>제공데이터 안내</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="voucher_list02_wrap">
                                            <div className="tit">03. 데이터 구매</div>
                                            <div className="cont">
                                                <p>수요 업체가 원하는 데이터 확인</p>
                                                <p>Raw 데이터 파일 작성</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="voucher_list02_wrap">
                                            <div className="tit">04. 결과물 FU</div>
                                            <div className="cont">
                                                <p>결과물 전달</p>
                                                <p>결과물 Fu</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            {/*Gird four Box E*/}
                            <h6>가이온 데이터 가공 Process</h6>

                            {/*Gird four Box S*/}
                            <div className="voucher_list02">
                                <ul>
                                    <li>
                                        <div className="voucher_list02_wrap">
                                            <div className="tit">01. 고객 안내/응대</div>
                                            <div className="cont">
                                                <p>바우처 안내</p>
                                                <p>문의 고객 응대</p>
                                                <p>Q&amp;A 응대</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="voucher_list02_wrap">
                                            <div className="tit">02. 컨설팅/협의</div>
                                            <div className="cont">
                                                <p>가공 내용 협의</p>
                                                <p>가공 범위 협의</p>
                                                <p>가공 과정 컨설팅</p>
                                                <p>가공 기법 소개</p>
                                                <p>기술 컨설팅</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="voucher_list02_wrap">
                                            <div className="tit">03. 데이터 가공</div>
                                            <div className="cont">
                                                <p>수요자 요청에 따른 데이터 가공</p>
                                                <p>기술 협의</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="voucher_list02_wrap">
                                            <div className="tit">04. 결과물 FU</div>
                                            <div className="cont">
                                                <p>결과물 전달</p>
                                                <p>결과물 Fu</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            {/*Gird four Box E*/}

                            {/*지원내용 E*/}
                            <br />
                            <br />
                            <br />
                            <br />

                            {/*사업 개념 S*/}
                            <div className="inner_section">
                                <h3>수행체계</h3>
                                <h5>
                                    데이터 활용은 바우처 방식으로 지원받을 수 있습니다.<br />
                                    수요기업은 직접 예산을 지원방는 방식이 아니라, 필요한 데이터,<br />
                                    가공서비스를 사전 지정된 전문기업으로부터 제공받는 방식으로 지원받게 됩니다.
                                </h5>
                                <div className="voucher_grid">
                                    <img src="/images/voucher/voucher_img01_1.png" alt="voucher_img" className="img-responsive" />
                                    <img src="/images/voucher/voucher_img02_1.png" alt="voucher_img" className="img-responsive" />
                                </div>
                            </div>
                            {/*사업 개념 E*/}

                            {/* 수행절차 S */}
                            <h3>수행절차</h3>

                            <div className="img_box">
                                <img src="/images/voucher/voucher_img03_2.png" alt="voucher_img"
                                    className="img-responsive center_img" />
                                <h4 className="center">데이터바우처 전문 상담 : 플랫폼사업부문 02-6956-5941</h4>

                            </div>
                            {/* 수행절차 E */}

                        </div>
                    </div>
                </div>

            </div >
        </React.Fragment >
    );
}

export default voucher;
