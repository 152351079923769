import "view/style/css/intro.css";
import Nav from "view/parts/nav/nav";
import Tab from "view/parts/tab/tab";
import * as Io from "react-icons/io";
import React, { useRef } from "react";
import MobileTab from "view/parts/tab/MobileTab";

/*swiper*/
import { Pagination, Navigation } from "swiper";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import { ArrowButton } from "view/component/arrowBtn";

function Client({ ...props }) {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + "</span>";
    },
  };

  return (
    <React.Fragment>
      <div className="content-inseide client">
        <div className="wrap">
          <div className="contentArea" data-aos="fade-up" data-aos-duration="500">
            <div className="contentTitle">Client</div>
          </div>
          <Nav />
        </div>
        <div className="typing">
          <div className="_typing-title center" data-aos="fade-up" data-aos-duration="1000">
            다양한 분야와 특징을 가진 제조/공공, 금융/보안, 유통, AI/Start-UP 등 300여개<br />
            고객사에 자체 AI 플랫폼 및 솔루션을 제공하고 있습니다.
          </div>

          <div className="grayBox">
            <div className="imgScroll">
              <div className="wrap esonImage">
                <img src="/images/client_0.png" data-aos="fade-up" data-aos-duration="500" />
              </div>
            </div>
          </div>

        </div>

        {/*section 02 S*/}
        <div className="wrap">
          <div className="imgSwiper">
            <div className="wrap">

              <div className="typing">
                <div className="contentArea bar">
                  <div className="contentTitle" data-aos="fade-up" data-aos-duration="500">파트너</div>
                </div>

                <div className="_typing-title center" data-aos="fade-up" data-aos-duration="800">
                  <span className="color">한국전자통신연구원(ETRI), 한국무역협회, 한국수입협회, 신한카드</span> 등과 업무협약을 통해<br />
                  사업 활성화를 위한 전략적 협업체계를 보유하고 있습니다.
                </div>

              </div>


              <div className="swiperRelative">

                <Swiper
                  spaceBetween={20}
                  slidesPerView={1}
                  speed={1000}
                  loop={false}
                  modules={[Pagination]}
                  pagination={{
                    type: "progressbar",
                    clickable: true,
                    el: '.mobile-rolling01',
                  }}
                  breakpoints={{
                    768: {
                      slidesPerView: 2,  //브라우저가 768보다 클 때
                      spaceBetween: 20,
                    },
                    1024: {
                      slidesPerView: 5,  //브라우저가 1024보다 클 때
                      spaceBetween: 20,
                    },
                  }}
                >
                  <SwiperSlide><img src="/images/client_1.png" /></SwiperSlide>
                  <SwiperSlide><img src="/images/client_2.png" /></SwiperSlide>
                  <SwiperSlide><img src="/images/client_3.png" /></SwiperSlide>
                  <SwiperSlide><img src="/images/client_4.png" /></SwiperSlide>
                  <SwiperSlide><img src="/images/client_5.png" /></SwiperSlide>
                </Swiper>

                < div className="swiper-pagination mobile-rolling01" />
              </div>
              <div className="_typing-text center" data-aos="fade-up" data-aos-duration="800" style={{ marginTop: "50px", fontSize: '22px' }}>
                AI, 빅데이터, 드론 등 첨단 기술을 통한 효과적 성과혁신,<br />
                가이온과 함께 라면 충분히 가능합니다.<br />
                <div className="bigText otherColor">
                  지금 바로 무료로 상담 받아 보세요!
                </div>
              </div>
              <div className="center">
                <ArrowButton title={"상담 문의하기"} link={{ link: "/company/contact", state: { index: 2, prev: 1 } }} />
              </div>
            </div>



          </div>
        </div>
        {/*section 02 E*/}

        <div className="wrap">
          <div className="contentArea bar">
            <div className="contentTitle" data-aos="fade-up" data-aos-duration="500">주요 사업실적_민간부분</div>
          </div>
          <div className="swiperBox">
            <Swiper
              onInit={(swiper) => {
                swiper.params.navigation.prevEl = prevRef.current;
                swiper.params.navigation.nextEl = nextRef.current;
                swiper.navigation.init();
                swiper.navigation.update();
              }}
              pagination={pagination}
              modules={[Pagination, Navigation]}
              effect={"fade"}
              spaceBetween={0}
              slidesPerView={1}
              speed={300}
              loop={false}
              className="changeBg"
            >
              <SwiperSlide>
                <table>
                  <thead>
                    <tr>
                      <th>고객사</th>
                      <th>사업명</th>
                      <th>년도</th>
                      <th>주요내용</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>토스증권</td>
                      <td>통합보안관제 구축</td>
                      <td>2020</td>
                      <td>보안로그 분석에 따른 보안 관제 구축</td>
                    </tr>
                    <tr>
                      <td>KB 증권</td>
                      <td>통합운영관제 구축</td>
                      <td>2020</td>
                      <td>
                        시스템 및 비즈니스 로그 분석에 따른 장애 및 예측 시스템
                        구축
                      </td>
                    </tr>
                    <tr>
                      <td>유안타증권</td>
                      <td>통합운영관제 구축</td>
                      <td>2020</td>
                      <td>보안로그 분석에 따른 보안 관제 구축</td>
                    </tr>
                    <tr>
                      <td>SKBB</td>
                      <td>통합인프라 운영관제 구축</td>
                      <td>2020</td>
                      <td>
                        서버, DB, 네트워크 장비 업무 시스템 장애 및 예측 시스템
                        구축
                      </td>
                    </tr>
                    <tr>
                      <td>한국은행</td>
                      <td>사이버위협분석기기 확대 프로젝트</td>
                      <td>2020</td>
                      <td>한국은행 사이버안전센터 고도화 사업</td>
                    </tr>
                    <tr>
                      <td>한국주택금융공사</td>
                      <td>신기술기반 차세대정보시스템2단계사업</td>
                      <td>2020</td>
                      <td>한국주택금융공사 SIEM 구축사업</td>
                    </tr>
                    <tr>
                      <td>아모레퍼시픽</td>
                      <td>해외 문서유출탐지 사업</td>
                      <td>2020</td>
                      <td>아모레퍼시픽 해외문서유출탐지 사업</td>
                    </tr>
                    <tr>
                      <td>삼성전자 서남아</td>
                      <td>삼성전자 서남아 총괄 스플렁크 2차 사업</td>
                      <td>2020</td>
                      <td>삼성전자 서남아 총괄 스플렁크 2차 사업</td>
                    </tr>
                    <tr>
                      <td>경남은행</td>
                      <td>통합보안관제솔루션 구축사업</td>
                      <td>2020</td>
                      <td>통합보안관제솔루션 구축사업</td>
                    </tr>
                    <tr>
                      <td>롯데정보통신</td>
                      <td>통합보안관제 플랫폼 구축</td>
                      <td>2019</td>
                      <td>통합 보안 관제 플랫폼 구축</td>
                    </tr>
                    <tr>
                      <td>NS홈쇼핑</td>
                      <td>디지털 컨택센터 구축</td>
                      <td>2019</td>
                      <td>디지털 컨택센터 구축</td>
                    </tr>
                    <tr>
                      <td>NC소프트</td>
                      <td>통합 모니터링 시스템 구축</td>
                      <td>2019</td>
                      <td>통합 모니터링 시스템 구축 확장</td>
                    </tr>
                    <tr>
                      <td>우리은행</td>
                      <td>차세대 보안관제 시스템</td>
                      <td>2018</td>
                      <td>차세대 보안관제 시스템 구축</td>
                    </tr>
                  </tbody>
                </table>
              </SwiperSlide>
              <SwiperSlide>
                <table>
                  <thead>
                    <tr>
                      <th>고객사</th>
                      <th>사업명</th>
                      <th>년도</th>
                      <th>주요내용</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>부산은행</td>
                      <td>고객행동분석 시스템 2차</td>
                      <td>2018</td>
                      <td>SUM Bank App 고객행동분석 시스템 업무확대 구축</td>
                    </tr>
                    <tr>
                      <td>SBS</td>
                      <td>고릴라 데이터 분석</td>
                      <td>2018</td>
                      <td>인터넷 라디오 고릴라 App 고객 데이터 분석</td>
                    </tr>
                    <tr>
                      <td>SGI서울보증보험</td>
                      <td>통합보안관리 재구축</td>
                      <td>2018</td>
                      <td>통합보안관리 시스템 재 구축</td>
                    </tr>
                    <tr>
                      <td>KB증권</td>
                      <td>실시간 이벤트 분석 플랫폼</td>
                      <td>2018</td>
                      <td>실시간 이벤트 분석 플랫폼 구축</td>
                    </tr>
                    <tr>
                      <td>한국남부발전</td>
                      <td>통합 보안로그 관제 시스템</td>
                      <td>2018</td>
                      <td>전사 통합 보안로그 관제 시스템 구축</td>
                    </tr>
                    <tr>
                      <td>CJ오쇼핑</td>
                      <td>新 LiveLog 구축</td>
                      <td>2017</td>
                      <td>
                        실시간 고객경험 분석 및 마케팅 활용을 위한 LiveLog 재
                        구축
                      </td>
                    </tr>
                    <tr>
                      <td>우리은행</td>
                      <td>차세대 보안관제 시스템</td>
                      <td>2017</td>
                      <td>차세대 보안관제 시스템 구축</td>
                    </tr>
                    <tr>
                      <td>삼성전기</td>
                      <td>비정형 로그 파일 분석 시스템</td>
                      <td>2017</td>
                      <td>설비 비정형 로그 파일 분석 시스템 구축</td>
                    </tr>
                    <tr>
                      <td>SK브로드밴드</td>
                      <td>CEI 데이터 분석</td>
                      <td>2017</td>
                      <td>CEI 데이터 분석 플랫폼 구축</td>
                    </tr>
                    <tr>
                      <td>동부증권</td>
                      <td>CEI 데이터 분석</td>
                      <td>2016</td>
                      <td>응용 프로그램 통합 모니터링 시스템 구축</td>
                    </tr>
                    <tr>
                      <td>CJ오쇼핑</td>
                      <td>통합 모니터링 시스템 구축</td>
                      <td>2016</td>
                      <td>
                        LiveLog 구조개선 컨설팅 및 데이터 수집체계 통합 구축
                      </td>
                    </tr>
                    <tr>
                      <td>SK텔레콤</td>
                      <td>Swing 인프라 모니터링 구축</td>
                      <td>2016</td>
                      <td>Swing 차세대 시스템 인프라 모니터링 시스템 구축</td>
                    </tr>
                    <tr>
                      <td>NIA</td>
                      <td>경기모니터링 및 조기경보 체계 구축</td>
                      <td>2016</td>
                      <td>
                        데이터 분석 기반 경기모니터링 및 조기경보 체계 구축
                      </td>
                    </tr>
                  </tbody>
                </table>
              </SwiperSlide>
              <SwiperSlide>
                <table>
                  <thead>
                    <tr>
                      <th>고객사</th>
                      <th>사업명</th>
                      <th>년도</th>
                      <th>주요내용</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>대구시</td>
                      <td>SmartTrade 구축</td>
                      <td>2016</td>
                      <td>대구시 대상 수출입 무역정보 서비스 제공</td>
                    </tr>
                    <tr>
                      <td>국방부</td>
                      <td>통합로그 분석시스템 구축</td>
                      <td>2016</td>
                      <td>
                        사이버 위협, 장애 모니터링 및 감지, 내부자 이상행위
                        분석을 위한 시스템 구축
                      </td>
                    </tr>
                    <tr>
                      <td>금융보안원</td>
                      <td>통합 보안관제 시스템 고도화</td>
                      <td>2016</td>
                      <td>90개 금융사 통합 차세대 보안관제 시스템 구축</td>
                    </tr>
                    <tr>
                      <td>삼성카드</td>
                      <td>고객경험 분석</td>
                      <td>2016</td>
                      <td>
                        카드, 금융신청 및 상품구매 등 주요 단계에서 고객경험
                        분석을 통한 만족도 제고
                      </td>
                    </tr>
                    <tr>
                      <td>롯데홈쇼핑</td>
                      <td>콜시스템 및 고객경험허브 구축</td>
                      <td>2016</td>
                      <td>
                        콜시스템 고도화 및 옴니채널 고객경험허브 시스템 구축
                      </td>
                    </tr>
                    <tr>
                      <td>르완다 RRA</td>
                      <td>국관세서비스 역량강화 사업</td>
                      <td>2016</td>
                      <td>
                        빅데이터 기반 SCM 구축 및 국관세 모니터링 / 이상징후
                        분석을 통한 세수 증대
                      </td>
                    </tr>
                    <tr>
                      <td>홈플러스</td>
                      <td>Click-stream 분석</td>
                      <td>2015</td>
                      <td>
                        멀티채널 고객 Click-stream 데이터 분석을 통한 실시간
                        마케팅 지원
                      </td>
                    </tr>
                    <tr>
                      <td>금융보안원</td>
                      <td>차세대 통합 보안관제 시스템</td>
                      <td>2015</td>
                      <td>
                        실시간 빅데이터 패킷 데이터 분석을 기반으로 한 공격
                        프로파일링 및 감지 모니터링
                      </td>
                    </tr>
                    <tr>
                      <td>삼성화재</td>
                      <td>홈페이지 웹로그 고도화</td>
                      <td>2015</td>
                      <td>
                        삼성화재 대표, 다이렉트 웹 사이트 및 RIA 고객 행동로그
                        분석
                      </td>
                    </tr>
                    <tr>
                      <td>KOSCOM</td>
                      <td>FDS 시스템 구축</td>
                      <td>2015</td>
                      <td>빅데이터 기반 FDS 시스템 구축</td>
                    </tr>
                    <tr>
                      <td>현대카드</td>
                      <td>고객 행동 분석</td>
                      <td>2015</td>
                      <td>고객 행동 분석 시스템 구축</td>
                    </tr>
                    <tr>
                      <td>인천글로벌캠퍼스</td>
                      <td>빅데이터 기반 ITSM 구축</td>
                      <td>2015</td>
                      <td>빅데이터 기반 자산관리 및 ITSM 구축</td>
                    </tr>
                    <tr>
                      <td>한국무역통계진흥원</td>
                      <td>빅데이터 기반 수출입정보 분석</td>
                      <td>2015</td>
                      <td>빅데이터 기반 수출입정보 분석 서비스 개발</td>
                    </tr>
                  </tbody>
                </table>
              </SwiperSlide>
              <SwiperSlide>
                <table>
                  <thead>
                    <tr>
                      <th>고객사</th>
                      <th>사업명</th>
                      <th>년도</th>
                      <th>주요내용</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>삼성증권</td>
                      <td>FDS 시스템 구축</td>
                      <td>2015</td>
                      <td>
                        빅데이터 기반 증권사 거래 패턴 분석 및 프로파일링을 통한
                        FDS 시스템 구축
                      </td>
                    </tr>
                    <tr>
                      <td>LF</td>
                      <td>e-CRM 시스템 구축</td>
                      <td>2015</td>
                      <td>
                        빅데이터 기반 실시간 고객 행동패턴 분석 및 이를 활용 한
                        e-CRM 시스템 구축
                      </td>
                    </tr>
                    <tr>
                      <td>NS홈쇼핑</td>
                      <td>통합 로그 분석 시스템 구축</td>
                      <td>2015</td>
                      <td>
                        보안, 시스템, CTI / IVR, 웹로그를 빅데이터 기반으로 통합
                        분석하는 플랫폼 구축
                      </td>
                    </tr>
                    <tr>
                      <td>삼성화재</td>
                      <td>직판 웹로그 분석 시스템 구축</td>
                      <td>2014</td>
                      <td>
                        삼성화재 애니카 다이렉트 웹 사이트 및 RIA 고객 행동로그
                        분석
                      </td>
                    </tr>
                    <tr>
                      <td>대한지적공사</td>
                      <td>LX 빅데이터 기반구축</td>
                      <td>2014</td>
                      <td>
                        대한지적공사 대내외 정형 / 비정형 데이터를 수집, 분석,
                        시각화 인프라 구축
                      </td>
                    </tr>
                    <tr>
                      <td>서울종합방재센터</td>
                      <td>종합방재 통계시스템 구축</td>
                      <td>2014</td>
                      <td>
                        서울시 소방, 방재 관련 데이터 수집, 저장, 집계, 분석하여
                        정형,
                        <br />
                        비정형 분석 기능을 제공하는 시스템 구축
                      </td>
                    </tr>
                    <tr>
                      <td>현대중공업</td>
                      <td>내구시험장 관리시스템 구축</td>
                      <td>2014</td>
                      <td>
                        내구시험장 발전소 센서 데이터를 수집, 저장, 분석할 수
                        있는 빅데이터 기반 구축
                      </td>
                    </tr>
                    <tr>
                      <td>삼성전자</td>
                      <td>SPIN 구축</td>
                      <td>2014</td>
                      <td>
                        판매한 프린터 운용, 품질 정보를 수집하여 분석할 수 있는
                        빅데이터 기반 구축
                      </td>
                    </tr>
                    <tr>
                      <td>현대중공업</td>
                      <td>모바일 RMS 구축</td>
                      <td>2013</td>
                      <td>
                        RMS 모바일 플랫폼 제공, 고장 패턴 추출 및 예지 분석할 수
                        있는 기반 구축
                      </td>
                    </tr>
                    <tr>
                      <td>현대중공업</td>
                      <td>RMS 구축</td>
                      <td>2013</td>
                      <td>
                        해외발전소 엔진 운영 데이터를 원격지에서 실시간으로
                        수집,모니터링, 분석 할 수 있는 시스템 구축
                      </td>
                    </tr>
                    <tr>
                      <td>현대중공업</td>
                      <td>빅데이터 기반 ESM</td>
                      <td>2013</td>
                      <td>
                        기업 내 장비 및 서버의 보안 데이터를 빅데이터 엔진을
                        통해 수집 분석하여 실시간 침입,침해 대응 할 수 있는
                        통합보안관제 시스템 구축
                      </td>
                    </tr>
                    <tr>
                      <td>한국무역정보통신</td>
                      <td>통합 서비스 대시보드</td>
                      <td>2013</td>
                      <td>
                        빅데이터 기반 서비스 모니터링 시스템 구축
                        <br />
                        (전자무역서비스, 공인전자문서보관소/인증서비스, 콜센터,
                        APM 대시보드 등)
                      </td>
                    </tr>
                  </tbody>
                </table>
              </SwiperSlide>
            </Swiper>
            <div className="prevBtn swiperBtn" ref={prevRef}>
              <Io.IoIosArrowBack />
            </div>
            <div className="nextBtn swiperBtn" ref={nextRef}>
              <Io.IoIosArrowForward />
            </div>
          </div>
        </div>

        <div className="wrap">
          <div className="contentArea bar">
            <div className="contentTitle" data-aos="fade-up" data-aos-duration="500">주요 사업실적_공공부문</div>
          </div>

          <table className="_table" data-aos="fade-up" data-aos-duration="1000">
            <thead>
              <tr>
                <th>고객사</th>
                <th>사업명</th>
                <th>년도</th>
                <th>주요내용</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>중소벤처기업부</td>
                <td>
                  보행데이터 기반의 파킨슨 및
                  <br />
                  낙상위험도 평가를 위한 스마트폰
                  <br />
                  탑재형 경량 AI Model 개발
                </td>
                <td>
                  2020 ~ 2021
                  <br />
                  (1년간)
                </td>
                <td>
                  [참여기업]
                  <br />
                  실시간 풋로거(인솔) 측정을 통한 AI 기반 파킨슨 질병 및<br />
                  낙상위험도 평가 스마트폰앱 개발
                </td>
              </tr>
              <tr>
                <td>산업통산자원부 사업</td>
                <td>
                  배송임무 성공률 98% 이상의
                  <br />
                  도서산간 드론 물류서비스 플랫폼
                  <br />
                  구축 및 상용화 실증
                </td>
                <td>
                  2019 ~ 2022
                  <br />
                  (4년간)
                </td>
                <td>
                  [주관기업]
                  <br />
                  클라우드 기반 중앙집중형 드론 배송 운영관리시스템 및 실증
                  데이터 분석을 위한 빅데이터 분석 플랫폼 개발
                  <br />
                  다부처 과기부, 산자부,
                </td>
              </tr>
              <tr>
                <td>국토부, 조달청</td>
                <td>
                  우편 배송 라스트 마일 업무 혁신을 위한
                  <br />
                  무인비행장치 운영 기술 개발
                </td>
                <td>
                  2019 ~ 2021
                  <br />
                  (24개월간)
                </td>
                <td>
                  [주관기업]
                  <br />
                  4G/5G 통신망 및 우체국 물류망을 이용하여 전국 도서․산간
                  <br />
                  중심으로 우편역무를 제공하는 우체국 전용 드론 배달시스템 및
                  기술 개발
                </td>
              </tr>
              <tr>
                <td>국방부 용역과제</td>
                <td>
                  국방의료정보체계와 연계한
                  <br />
                  의무물자 소요산정모델 개발
                </td>
                <td>
                  2019 ~ 2020
                  <br />
                  (2년간)
                </td>
                <td>
                  [연구용역]
                  <br />
                  의무물자 수요예측 모델 및 의무군수지원 소요산출 시스템 개발
                </td>
              </tr>
              <tr>
                <td>충청남도청 - 용역</td>
                <td>주소 기반 드론 배달 시험운영</td>
                <td>
                  2018 ~ 2019
                  <br />
                  (3개월간)
                </td>
                <td>주소 기반 드론 배달 서비스</td>
              </tr>
              <tr>
                <td>농진청 사업</td>
                <td>
                  반려동물 생애주기 기반 빅데이터를 통한
                  <br />
                  클라우드 기반 의료 추천시스템 개발
                </td>
                <td>
                  2018 ~ 2020
                  <br />
                  (3년간)
                </td>
                <td>
                  [참여기업]
                  <br />
                  SaaS 클라우드 컴퓨팅 기반의 지능형 추천 의료시스템 구축
                </td>
              </tr>
              <tr>
                <td>ETRI -용역 과제</td>
                <td>고령자 질환 실시간 모니터링 시스템 개발</td>
                <td>
                  2018 ~ 2019
                  <br />
                  (2년간)
                </td>
                <td>고령자 질환 생체신호 모니터링 시스템 개발</td>
              </tr>
              <tr>
                <td>NIPA과제</td>
                <td>
                  무역통관정보 지능화를 통한
                  <br />
                  자동분석 보고서 생성 서비스 개발
                </td>
                <td>
                  2018 ~ 2019
                  <br />
                  (2년간)
                </td>
                <td>
                  [주관기업]
                  <br />
                  高신뢰성 수출입 무역정보 지식화 서비스를 위한
                  <br />
                  AI 기술 및 빅데이터 정보 분석 플랫폼 개발
                </td>
              </tr>
              <tr>
                <td>국토부 공간 빅데이터</td>
                <td>
                  공간 빅데이터 일괄(Batch)
                  <br />
                  분석 컴포넌트 기술 개발
                </td>
                <td>
                  2017 ~ 2019
                  <br />
                  (3년간)
                </td>
                <td>
                  [참여기업]
                  <br />
                  저비용, 고성능, 고확장성의 공간 빅데이터 저장, 관리, 분석 및
                  서비스 제공을 위한
                  <br />
                  핵심기술과 이를 구현한 공간 빅데이터 관리, 분석 및 서비스
                  플랫폼 개발
                </td>
              </tr>
              <tr>
                <td>
                  구매조건부 신제품 개발사업
                  <br />
                  국내수요처 기업제안과제
                </td>
                <td>
                  빅 데이터 기반 컨택센터 실시간
                  <br />
                  고객경험 분석 어플라이언스 개발
                </td>
                <td>
                  2016 ~ 2018
                  <br />
                  (2년간)
                </td>
                <td>
                  [주관기업]
                  <br />빅 데이터 기술 기반 고객 행동 및 반응 데이터 수집 및
                  고객 경험 정보를 정량 분석화 하여
                  <br />
                  컨택센터 상담원 응대능력 향상 및 마케팅 활용을 위한
                  어플라이언스 기술 개발
                </td>
              </tr>
              <tr>
                <td>철도기술연구사업</td>
                <td>철도차량 고장진단 및 예지 기술 개발</td>
                <td>
                  2015 ~ 2020
                  <br />
                  (6년간)
                </td>
                <td>
                  [연구용역]
                  <br />
                  상태 데이터 관리기술 개발 및 철도부품 고장예지 기술 개발
                </td>
              </tr>
              <tr>
                <td>미래부 NIA 개발사업</td>
                <td>
                  빅데이터 통합 분석을 통한
                  <br />
                  민생소비지표 개발 사업
                </td>
                <td>
                  2016
                  <br />
                  (1년간)
                </td>
                <td>
                  [참여기업]
                  <br />
                  빅데이터 기반 민생소비지표 산출 및 모니터링 시스템 개발
                </td>
              </tr>
              <tr>
                <td>R&D기획지원사업</td>
                <td>
                  제조 생산성을 향상시키는 MES 연계형
                  <br />
                  빅데이터 분석 어플라이언스 국산화 개발
                </td>
                <td>
                  2014 ~ 2015
                  <br />
                  (2년간)
                </td>
                <td>
                  [주관기업]
                  <br />
                  MES에서 발생하는 정형 비정형 데이터 및 센서 데이터를
                  <br />
                  수집하여 분석할 수 있는 빅데이터 어플라이언스 개발
                </td>
              </tr>
              <tr>
                <td>산업융합원천기술개발 사업</td>
                <td>
                  기업 내 업무 및 협업 관리를 위한
                  <br />
                  빅데이터 기반 자동화된
                  <br />
                  프로세스 분석 제품 개발
                </td>
                <td>
                  2013 ~ 2015
                  <br />
                  (2년간)
                </td>
                <td>
                  [참여기업]
                  <br />
                  빅데이터 기반 데이터 분석 저장소 개발 (Hadoop Echo-system)
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment >
  );
}

export default Client;
