import React from "react";
import "view/style/css/voucher.css";
import Nav from "view/parts/nav/nav";

function voucher() {
    return (
        <React.Fragment>
            <div className="content-inseide solution en">
                <div className="wrap">
                    <div className="contentArea">
                        <div className="contentTitle">
                            <div data-aos="fade-up" data-aos-duration="500">Data Voucher</div>
                        </div>
                    </div>
                    <Nav />
                </div>
                <div className="wrap">
                    <div className="inner_center">
                        <div className="img_box center">
                            <a href="https://forms.gle/qFT3u6bbtkxAs7Cj6"
                                target="_blank"><img src="/images/voucher/voucher_icon_0322_re.png" alt="voucher_img"
                                    className="img-responsive center_img" /></a>
                        </div>
                        <br />
                        <div className="inner_section">
                            <h4><br />
                            </h4>
                            <h3>Data Voucher</h3>
                            <h5>
                                The voucher business is designed to overcome difficulties in creating<br />
                                an industrial vitalization ecosystem and utilizing technology.<br />
                                To help SMEs, ventures, startups, small business owners, etc.<br />
                                This is a project that supports the cost of using technology.<br />
                                <br />
                                Gaion has been recognized for its outstanding technology and abundant technology,<br />
                                Selected as an official supplier of AI vouchers.
                            </h5>
                            <br />
                            <br />
                            <br />
                            <br />
                            <h3>Business Overview</h3>
                            <h5>
                                Each stage of data construction, processing, and distribution is organically linked.<br />
                                Activating the data and AI industries by expanding transactions through data purchase and processing,<br />
                                Small and medium-sized venture companies, small business owners, and others who have difficulty in<br />
                                creating an ecosystem and utilizing data It is a project to support data purchase<br />
                                and processing vouchers for one-person creative companies and prospective start-ups.<br />
                                <br />
                                For companies that need data utilization,<br />
                                We support the cost of purchasing and processing data, which was a difficulty in using existing data.
                            </h5>
                            <div className="voucher_icon">
                                <ul>
                                    <li>
                                        <div className="voucher_cont">
                                            <div className="icon_box"><img src="/images/voucher/voucher_icon01.png"
                                                alt="voucher_icon" /></div>
                                            <p>Business name</p><span>Data Voucher Support Project</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="voucher_cont">
                                            <div className="icon_box"><img src="/images/voucher/voucher_icon02.png"
                                                alt="voucher_icon" /></div>
                                            <p>Total cost of business</p><span>A total of 850 billion won for 20~25 years.<br />
                                                ('About 89.4 billion won in 2023)</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="voucher_cont">
                                            <div className="icon_box"><img src="/images/voucher/voucher_icon03.png"
                                                alt="voucher_icon" /></div>
                                            <p>Scale of support</p><span>760 data purchases<br />
                                                250 general processing<br />
                                                Support for 1,000 cases of AI processing</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="voucher_cont">
                                            <div className="icon_box"><img src="/images/voucher/voucher_icon04.png"
                                                alt="voucher_icon" /></div>
                                            <p>Business promotion method</p><span>Provide data sales and processing services<br />
                                                Recruit suppliers and use vouchers afterwards<br />
                                                Support by screening and selecting accepting companies</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <h5>The data voucher demand company registration period is usually held at the beginning of each year.<br />
                                Data voucher support project support for demand companies, or related<br />
                                If you have any questions, please contact <span className="color">osm@gaion.kr</span>.
                            </h5>
                        </div>
                        <div className="inner_section">
                            <h3>Support classification</h3>
                            <h5 style={{ marginBottom: "0px" }}>
                                Data purchase/data processing (AI or general) can be supported by one option
                            </h5>
                            <div className="voucher_list01">
                                <ul>
                                    <li>
                                        <div className="voucher_list01_wrap">
                                            <div className="tit">Data processing (AI processing 60 million won / general processing 10 million won)</div>
                                            <div className="cont">
                                                Necessary for AI-based service and product development, big data analysis, and resolution of<br />
                                                social issues Building artificial intelligence learning datasets and supporting general processing costs<br />
                                                -<br />
                                                ※ In the case of <b>pseudonymization/anonymization (non-identification)</b> of data<br />
                                                <b>Considered as one of the data processing tasks</b> and supported
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="voucher_list01_wrap">
                                            <div className="tit">Data purchase (18 million won)</div>
                                            <div className="cont">
                                                Establishment of marketing strategies, development of business models, commercialization<br />
                                                of creative and challenging ideas, etc. Data purchase support for various data utilization purposes<br />
                                                -<br />
                                                Support is provided within KRW 18 million per purchase voucher,<br />
                                                Multiple types of data can be purchased within the grant
                                                <br />
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <h5>* Processing vouchers will be provided within 70 million won per case for the construction of datasets for AI development,<br />
                                and will be provided up to 45 million won for data processing for general big data analysis.<br />
                                * With the support of the corporate matching method, small and medium-sized enterprises will pay 20% of the private sector (but 2% in cash)<br />
                                * 20% of private charges for small business owners and one-person creative companies (all available in kind) / Preliminary start-ups are exempted from private charges.<br /><br />
                            </h5>
                            {/*지원내용 S*/}
                            <h3>Support contents</h3>
                            <h5>
                                We provide customized data services for different industries and scales.<br />
                                More details can be found through consultation.
                            </h5>
                            <div className="img_box">
                                <img src="/images/voucher/voucher_img04_3.png" alt="voucher_img"
                                    className="img-responsive center_img" />
                            </div>
                            <h6>GAION Data Purchasing Process</h6>
                            {/*Gird four Box S*/}
                            <div className="voucher_list02">
                                <ul>
                                    <li>
                                        <div className="voucher_list02_wrap">
                                            <div className="tit">01. Customer guidance/response</div>
                                            <div className="cont">
                                                <p>Voucher Information</p>
                                                <p>Inquiry customer response</p>
                                                <p>Q&amp;A response</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="voucher_list02_wrap">
                                            <div className="tit">02. Consulting/Consultation</div>
                                            <div className="cont">
                                                <p>Introduction of import and export data</p>
                                                <p>Service agreement</p>
                                                <p>Negotiate data scope</p>
                                                <p>Provided data information</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="voucher_list02_wrap">
                                            <div className="tit">03. Buy data</div>
                                            <div className="cont">
                                                <p>Check the data that demand companies want</p>
                                                <p>Write raw data files</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="voucher_list02_wrap">
                                            <div className="tit">04. Output FU</div>
                                            <div className="cont">
                                                <p>Delivery of results</p>
                                                <p>Output FU</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            {/*Gird four Box E*/}
                            <h6>Gaion Data Processing Process</h6>
                            {/*Gird four Box S*/}
                            <div className="voucher_list02">
                                <ul>
                                    <li>
                                        <div className="voucher_list02_wrap">
                                            <div className="tit">01. Customer guidance/response</div>
                                            <div className="cont">
                                                <p>Voucher Information</p>
                                                <p>Inquiry customer response</p>
                                                <p>Q&amp;A response</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="voucher_list02_wrap">
                                            <div className="tit">02. Consulting/Consultation</div>
                                            <div className="cont">
                                                <p>Discuss processing details</p>
                                                <p>Discuss processing scope</p>
                                                <p>Machining Process Consulting</p>
                                                <p>Introduction to processing techniques</p>
                                                <p>Technology consulting</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="voucher_list02_wrap">
                                            <div className="tit">03. Data Processing</div>
                                            <div className="cont">
                                                <p>Data processing according to consumer request</p>
                                                <p>Technical consultation</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="voucher_list02_wrap">
                                            <div className="tit">04. Output FU</div>
                                            <div className="cont">
                                                <p>Delivery of results</p>
                                                <p>Output FU</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            {/*Gird four Box E*/}
                            {/*지원내용 E*/}
                            <br />
                            <br />
                            <br />
                            <br />
                            {/*사업 개념 S*/}
                            <div className="inner_section">
                                <h3>Performance system</h3>
                                <h5>
                                    Data utilization can be supported by voucher method.<br />
                                    The demand company does not directly support the budget, but the necessary data,<br />
                                    You will receive support by receiving processing services from a pre-designated professional company.
                                </h5>
                                <div className="voucher_grid">
                                    <img src="/images/voucher/voucher_img01_1.png" alt="voucher_img" className="img-responsive" />
                                    <img src="/images/voucher/voucher_img02_1.png" alt="voucher_img" className="img-responsive" />
                                </div>
                            </div>
                            {/*사업 개념 E*/}
                            {/* 수행절차 S */}
                            <h3>Procedure</h3>
                            <div className="img_box">
                                <img src="/images/voucher/voucher_img03_2.png" alt="voucher_img"
                                    className="img-responsive center_img" />
                                <h4 className="center">Data voucher expert consultation: platform business division 02-6956-5941</h4>
                            </div>
                            {/* 수행절차 E */}
                        </div>
                    </div>
                </div>
            </div >
        </React.Fragment >
    );
}

export default voucher;
