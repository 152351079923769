import "view/style/css/intro.css";
import Nav from "view/parts/nav/nav";
import * as Io from "react-icons/io";
import React, { useRef } from "react";

/*swiper*/
import { Pagination, Navigation } from "swiper";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import { ArrowButton } from "view/component/arrowBtn";

function Client() {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + "</span>";
    },
  };

  return (
    <React.Fragment>
      <div className="content-inseide client en">
        <div className="wrap">
          <div className="contentArea" data-aos="fade-up" data-aos-duration="500">
            <div className="contentTitle">Client</div>
          </div>
          <Nav />
        </div>
        <div className="typing">
          <div className="_typing-title center" data-aos="fade-up" data-aos-duration="1000">
            More than 300 manufacturing/public, financial/security, distribution, AI/Start-UP, etc. with diverse fields and characteristics<br />
            We provide our customers with our own AI platform and solutions.
          </div>
          <div className="grayBox">
            <div className="imgScroll">
              <div className="wrap esonImage">
                <img src="/images/client_0.png" data-aos="fade-up" data-aos-duration="500" />
              </div>
            </div>
          </div>
        </div>
        {/*section 02 S*/}
        <div className="wrap">
          <div className="imgSwiper">
            <div className="wrap">
              <div className="typing">
                <div className="contentArea bar">
                  <div className="contentTitle" data-aos="fade-up" data-aos-duration="500">Partner</div>
                </div>
                <div className="_typing-title center" data-aos="fade-up" data-aos-duration="800">
                  <span className="color"> Through business agreements with the Korea Electronics and Telecommunications Research Institute (ETRI),<br />
                    the Korea International Trade Association, the Korea Import Association, and Shinhan Card.</span>
                  We have a strategic collaboration system to revitalize our business.
                </div>
              </div>
              <div className="swiperRelative">
                <Swiper
                  spaceBetween={20}
                  slidesPerView={1}
                  speed={1000}
                  loop={false}
                  modules={[Pagination]}
                  pagination={{
                    type: "progressbar",
                    clickable: true,
                    el: '.mobile-rolling01',
                  }}
                  breakpoints={{
                    768: {
                      slidesPerView: 2,  //브라우저가 768보다 클 때
                      spaceBetween: 20,
                    },
                    1024: {
                      slidesPerView: 5,  //브라우저가 1024보다 클 때
                      spaceBetween: 20,
                    },
                  }}
                >
                  <SwiperSlide><img src="/images/client_1.png" /></SwiperSlide>
                  <SwiperSlide><img src="/images/client_2.png" /></SwiperSlide>
                  <SwiperSlide><img src="/images/client_3.png" /></SwiperSlide>
                  <SwiperSlide><img src="/images/client_4.png" /></SwiperSlide>
                  <SwiperSlide><img src="/images/client_5.png" /></SwiperSlide>
                </Swiper>
                < div className="swiper-pagination mobile-rolling01" />
              </div>
              <div className="_typing-text center" data-aos="fade-up" data-aos-duration="800" style={{ marginTop: "50px", fontSize: '22px' }}>
                Effective performance innovation through cutting-edge technologies such as AI, big data, and drones,<br />
                It's possible with Gaion.<br />
                <div className="bigText otherColor">
                  Get free counseling today!
                </div>
              </div>
              <div className="center">
                <ArrowButton title={"상담 문의하기"} link={{ link: "/company/contact", state: { index: 2, prev: 1 } }} />
              </div>
            </div>
          </div>
        </div>
        {/*section 02 E*/}
        <div className="wrap">
          <div className="contentArea bar">
            <div className="contentTitle" data-aos="fade-up" data-aos-duration="500">Major business performance_private sector</div>
          </div>
          <div className="swiperBox">
            <Swiper
              onInit={(swiper) => {
                swiper.params.navigation.prevEl = prevRef.current;
                swiper.params.navigation.nextEl = nextRef.current;
                swiper.navigation.init();
                swiper.navigation.update();
              }}
              pagination={pagination}
              modules={[Pagination, Navigation]}
              effect={"fade"}
              spaceBetween={0}
              slidesPerView={1}
              speed={300}
              loop={false}
              className="changeBg"
            >
              <SwiperSlide>
                <table>
                  <thead>
                    <tr>
                      <th>Customer Company </th>
                      <th>Business name </th>
                      <th>Year</th>
                      <th>Highlights</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Toss Securities</td>
                      <td>Building Integrated Security Control</td>
                      <td>2020</td>
                      <td>Build security controls based on security log analysis</td>
                    </tr>
                    <tr>
                      <td>KB Securities</td>
                      <td>Establishment of integrated operation control</td>
                      <td>2020</td>
                      <td>
                        Failures and predictions based on system and business log analysis
                        build
                      </td>
                    </tr>
                    <tr>
                      <td>Yuanta Securities</td>
                      <td>Establishment of integrated operation control</td>
                      <td>2020</td>
                      <td>Build security controls based on security log analysis</td>
                    </tr>
                    <tr>
                      <td>SKBB</td>
                      <td>Building Integrated Infrastructure Operation Control</td>
                      <td>2020</td>
                      <td>
                        Server, DB, Network Equipment Business System Failure and Prediction System
                        build
                      </td>
                    </tr>
                    <tr>
                      <td>Bank of Korea</td>
                      <td>Project to expand cyber threat analysis devices</td>
                      <td>2020</td>
                      <td>Bank of Korea Cyber Safety Center Advancement Project</td>
                    </tr>
                    <tr>
                      <td>Korea Housing Finance Corporation</td>
                      <td>New technology-based next-generation information system phase 2 project</td>
                      <td>2020</td>
                      <td>Korea Housing Finance Corporation SIEM Construction Project</td>
                    </tr>
                    <tr>
                      <td>Amore Pacific</td>
                      <td> Overseas document leakage detection business</td>
                      <td>2020</td>
                      <td>AmorePacific Overseas Document Leak Detection Project</td>
                    </tr>
                    <tr>
                      <td>Samsung Electronics Seo Nam-ah</td>
                      <td>Samsung Electronics Seo Nam-ah General Spunk 2nd Project</td>
                      <td>2020</td>
                      <td>Samsung Electronics Seo Nam-ah General Spunk 2nd Project</td>
                    </tr>
                    <tr>
                      <td>Gyeongnam Bank</td>
                      <td>Integrated Security Control Solution Construction Project</td>
                      <td>2020</td>
                      <td>Integrated Security Control Solution Construction Project</td>
                    </tr>
                    <tr>
                      <td>Lotte Information and Communication</td>
                      <td>Building an Integrated Security Control Platform</td>
                      <td>2019</td>
                      <td>Building an Integrated Security Control Platform</td>
                    </tr>
                    <tr>
                      <td>NS Home Shopping</td>
                      <td>Building a Digital Contact Center</td>
                      <td>2019</td>
                      <td>Building a Digital Contact Center</td>
                    </tr>
                    <tr>
                      <td>NC Soft</td>
                      <td>Building an Integrated Monitoring System</td>
                      <td>2019</td>
                      <td>Expanded Unified Monitoring System Deployment</td>
                    </tr>
                    <tr>
                      <td>Woori Bank</td>
                      <td>Next Generation Security Control System</td>
                      <td>2018</td>
                      <td>Building the Next Generation Security Control System</td>
                    </tr>
                  </tbody>
                </table>
              </SwiperSlide>
              <SwiperSlide>
                <table>
                  <thead>
                    <tr>
                      <th>Customer Company </th>
                      <th>Business name </th>
                      <th>Year</th>
                      <th>Highlights</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Busan Bank</td>
                      <td>Customer Behavior Analysis System 2nd</td>
                      <td>2018</td>
                      <td>Building SUM Bank App Customer Behavior Analysis System Business Expansion</td>
                    </tr>
                    <tr>
                      <td>SBS</td>
                      <td>Gorilla Data Analysis</td>
                      <td>2018</td>
                      <td>Internet Radio Gorilla App Customer Data Analysis</td>
                    </tr>
                    <tr>
                      <td>SGI Seoul Guarantee Insurance</td>
                      <td>Rebuild Integrated Security Management</td>
                      <td>2018</td>
                      <td>Rebuild Integrated Security Management System</td>
                    </tr>
                    <tr>
                      <td>KB Securities</td>
                      <td>Real-time Event Analysis Platform</td>
                      <td>2018</td>
                      <td>Building a Real-Time Event Analysis Platform</td>
                    </tr>
                    <tr>
                      <td>Korea Southern Power Co., Ltd.</td>
                      <td>Integrated Security Log Control System</td>
                      <td>2018</td>
                      <td>Deploying an integrated enterprise security log control system</td>
                    </tr>
                    <tr>
                      <td>CJ O Shopping</td>
                      <td>Building a New LiveLog</td>
                      <td>2017</td>
                      <td>
                        LiveLog material for real-time customer experience analysis and marketing utilization
                        build
                      </td>
                    </tr>
                    <tr>
                      <td>Woori Bank</td>
                      <td>Next Generation Security Control System</td>
                      <td>2017</td>
                      <td>Building the Next Generation Security Control System</td>
                    </tr>
                    <tr>
                      <td>Samsung Electric </td>
                      <td>Unstructured Log File Analysis System</td>
                      <td>2017</td>
                      <td>Building a Facility Unstructured Log File Analysis System</td>
                    </tr>
                    <tr>
                      <td>SK Broadband</td>
                      <td>CEI Data Analysis</td>
                      <td>2017</td>
                      <td>Building a CEI Data Analysis Platform</td>
                    </tr>
                    <tr>
                      <td>Dongbu Securities</td>
                      <td>CEI Data Analysis</td>
                      <td>2016</td>
                      <td>Deploying an integrated monitoring system for applications</td>
                    </tr>
                    <tr>
                      <td>CJ O Shopping</td>
                      <td>Building an Integrated Monitoring System</td>
                      <td>2016</td>
                      <td>
                        Integration of LiveLog Structural Improvement Consulting and Data Collection System
                      </td>
                    </tr>
                    <tr>
                      <td>SK Telecom</td>
                      <td>Deploying Swing Infrastructure Monitoring</td>
                      <td>2016</td>
                      <td>Swing Build Next Generation System Infrastructure Monitoring System</td>
                    </tr>
                    <tr>
                      <td>NIA</td>
                      <td>Establishment of Economic Monitoring and Early Warning System</td>
                      <td>2016</td>
                      <td>
                        Establishment of Data Analysis-Based Economic Monitoring and Early Warning System
                      </td>
                    </tr>
                  </tbody>
                </table>
              </SwiperSlide>
              <SwiperSlide>
                <table>
                  <thead>
                    <tr>
                      <th>Customer Company </th>
                      <th>Business name </th>
                      <th>Year</th>
                      <th>Highlights</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Daegu City</td>
                      <td>SmartTrade 구축</td>
                      <td>2016</td>
                      <td>Provision of import and export trade information services for Daegu City</td>
                    </tr>
                    <tr>
                      <td>Defense Department</td>
                      <td>Building an Integrated Log Analysis System</td>
                      <td>2016</td>
                      <td>
                        Cyber threats, fault monitoring and detection, insider anomalies
                        Building a System for Analysis
                      </td>
                    </tr>
                    <tr>
                      <td>Financial Security Agency</td>
                      <td>Advanced Integrated Security Control System</td>
                      <td>2016</td>
                      <td>Establishment of an integrated next-generation security control system of 90 financial companies</td>
                    </tr>
                    <tr>
                      <td>Samsung Card</td>
                      <td>Customer Experience Analysis</td>
                      <td>2016</td>
                      <td>
                        Customer experience at key stages such as card, financial application and product purchase
                        Improve satisfaction through analysis
                      </td>
                    </tr>
                    <tr>
                      <td>Lotte Home Shopping</td>
                      <td>Calling system and customer experience hub construction</td>
                      <td>2016</td>
                      <td>
                        Advanced Call System and Establishment of Omni Channel Customer Experience Hub System
                      </td>
                    </tr>
                    <tr>
                      <td>Rwanda RRA</td>
                      <td>National Customs Service Capacity Enhancement Project</td>
                      <td>2016</td>
                      <td>
                        Establishment of SCM based on big data and monitoring of national tariffs / abnormalities
                        Increased tax revenue through analysis
                      </td>
                    </tr>
                    <tr>
                      <td>Home Plus </td>
                      <td>Click-stream 분석</td>
                      <td>2015</td>
                      <td>
                        Real-time with multi-channel customer click-stream data analysis
                        Marketing Support
                      </td>
                    </tr>
                    <tr>
                      <td>Financial Security Agency</td>
                      <td>Next Generation Integrated Security Control System</td>
                      <td>2015</td>
                      <td>
                        Attack based on real-time big data packet data analysis
                        Profiling and Sensing Monitoring
                      </td>
                    </tr>
                    <tr>
                      <td>Samsung Fire & Marine Insurance</td>
                      <td>Advanced Home Page Web Logs</td>
                      <td>2015</td>
                      <td>
                        Samsung Fire & Marine Insurance CEO, Direct website and RIA customer behavior log
                        Analysis
                      </td>
                    </tr>
                    <tr>
                      <td>KOSCOM</td>
                      <td>Building an FDS System</td>
                      <td>2015</td>
                      <td>Building a Big Data-Based FDS System</td>
                    </tr>
                    <tr>
                      <td>Hyundai Card</td>
                      <td>Customer Behavior Analysis</td>
                      <td>2015</td>
                      <td>Building a Customer Behavior Analysis System</td>
                    </tr>
                    <tr>
                      <td>Incheon Global Campus</td>
                      <td>Deploying ITSM Based on Big Data</td>
                      <td>2015</td>
                      <td>Build Big Data-Based Asset Management and ITSM</td>
                    </tr>
                    <tr>
                      <td>Korea Trade Statistics Agency</td>
                      <td>Analyzing Import and Export Information Based on Big Data</td>
                      <td>2015</td>
                      <td>Development of big data-based import and export information analysis service</td>
                    </tr>
                  </tbody>
                </table>
              </SwiperSlide>
              <SwiperSlide>
                <table>
                  <thead>
                    <tr>
                      <th>Customer Company </th>
                      <th>Business name </th>
                      <th>Year</th>
                      <th>Highlights</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Samsung Securities</td>
                      <td>Building an FDS System</td>
                      <td>2015</td>
                      <td>
                        Through big data-based securities firm transaction pattern analysis and profiling,
                        Building an FDS System
                      </td>
                    </tr>
                    <tr>
                      <td>LF</td>
                      <td>E-CRM System Construction</td>
                      <td>2015</td>
                      <td>
                        Real-time customer behavior pattern analysis based on big data and utilizing it
                        e-CRM system construction
                      </td>
                    </tr>
                    <tr>
                      <td>NS Home Shopping</td>
                      <td>Building an Integrated Log Analysis System</td>
                      <td>2015</td>
                      <td>
                        Integrate security, systems, CTI/IVR, weblogs on a big data basis
                        Building a platform to analyze
                      </td>
                    </tr>
                    <tr>
                      <td>Samsung Fire & Marine Insurance</td>
                      <td>Building a Direct Web Log Analysis System</td>
                      <td>2014</td>
                      <td>
                        Samsung Fire & Marine Insurance's Anycar Direct website and RIA customer behavior log.
                        Analysis
                      </td>
                    </tr>
                    <tr>
                      <td>Daehan Intellectual Property Corporation</td>
                      <td>LX Building a Big Data Foundation</td>
                      <td>2014</td>
                      <td>
                        Collect and analyze internal and external structured and unstructured data of Korea Intellectual Property Corporation,
                        Building a Visualization Infrastructure
                      </td>
                    </tr>
                    <tr>
                      <td>Seoul General Disaster Prevention Center</td>
                      <td>Building a comprehensive disaster prevention statistics system</td>
                      <td>2014</td>
                      <td>
                        Collect, store, aggregate, and analyze data related to fire and disaster prevention in Seoul.
                        Formal,
                        <br />
                        Build systems that provide unstructured analytics
                      </td>
                    </tr>
                    <tr>
                      <td>Hyundai Heavy Industries</td>
                      <td> Construction of a test site management system for durations</td>
                      <td>2014</td>
                      <td>
                        Ability to collect, store and analyze plant sensor data at a durability test site
                        Building a Big Data Foundation
                      </td>
                    </tr>
                    <tr>
                      <td>Samsung Electronics</td>
                      <td>Deploying SPIN</td>
                      <td>2014</td>
                      <td>
                        Collect and analyze printer operation and quality information sold
                        Building a Big Data Foundation
                      </td>
                    </tr>
                    <tr>
                      <td>Hyundai Heavy Industries</td>
                      <td>Deploying Mobile RMS</td>
                      <td>2013</td>
                      <td>
                        Provides RMS mobile platform, can extract failure patterns, and analyze predictions
                        Build the foundation you have
                      </td>
                    </tr>
                    <tr>
                      <td>Hyundai Heavy Industries</td>
                      <td>RMS Deployment</td>
                      <td>2013</td>
                      <td>
                        Overseas power plant engine operation data in real-time from remote locations
                        Build systems for collection, monitoring, and analysis
                      </td>
                    </tr>
                    <tr>
                      <td>Hyundai Heavy Industries</td>
                      <td>Big Data-Based ESM</td>
                      <td>2013</td>
                      <td>
                        Security data from equipment and servers in the enterprise can be used to drive big data engines.
                        Collect and analyze to respond to real-time intrusion and infringement
                        Establishment of Integrated Security Control System
                      </td>
                    </tr>
                    <tr>
                      <td>Korea Trade Information Communication</td>
                      <td>Integrated Services Dashboard</td>
                      <td>2013</td>
                      <td>
                        Building a Big Data-Based Service Monitoring System
                        <br />
                        (Electronic Trade Service, Authorized Electronic Document Archive/Certification Service, Call Center,
                        APM Dashboard, etc.)
                      </td>
                    </tr>
                  </tbody>
                </table>
              </SwiperSlide>
            </Swiper>
            <div className="prevBtn swiperBtn" ref={prevRef}>
              <Io.IoIosArrowBack />
            </div>
            <div className="nextBtn swiperBtn" ref={nextRef}>
              <Io.IoIosArrowForward />
            </div>
          </div>
        </div>
        <div className="wrap">
          <div className="contentArea bar">
            <div className="contentTitle" data-aos="fade-up" data-aos-duration="500">Major business performance_public sector</div>
          </div>
          <table className="_table" data-aos="fade-up" data-aos-duration="1000">
            <thead>
              <tr>
                <th>Customer Company </th>
                <th>Business name </th>
                <th>Year</th>
                <th>Highlights</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Small and Medium Venture Business Department</td>
                <td>
                  Parkinson's based on walking data and
                  <br />
                  Smartphone for fall risk assessment
                  <br />
                  Development of Lightweight AI Model on Mount
                </td>
                <td>
                  2020 ~ 2021
                  <br />
                  (For a year)
                </td>
                <td>
                  [Participating companies]
                  <br />
                  AI-based Parkinson's Disease with Real-Time Footlogger (Insole) Measurements and<br />
                  Development of Smartphone App for Fall Risk Assessment
                </td>
              </tr>
              <tr>
                <td>Ministry of Trade, Industry and Energy Project</td>
                <td>
                  Delivery mission success rate of 98% or more
                  <br />
                  Inter-Island Drone Distribution Service Platform
                  <br />
                  Demonstrate deployment and commercialization
                </td>
                <td>
                  2019 ~ 2022
                  <br />
                  (For 4 years)
                </td>
                <td>
                  [The host company]
                  <br />
                  Cloud-based Centralized Drone Delivery Operation Management System and Demonstration
                  Development of Big Data Analysis Platform for Data Analysis
                  <br />
                  Ministry of Science and Technology, Ministry of Commerce, Industry and Energy,
                </td>
              </tr>
              <tr>
                <td>Ministry of Land, Infrastructure and Transport</td>
                <td>
                  Mail delivery Last mile for business innovation
                  <br />
                  Development of unmanned aerial vehicle operation technology
                </td>
                <td>
                  2019 ~ 2021
                  <br />
                  (24 months)
                </td>
                <td>
                  [The host company]
                  <br />
                  Using 4G/5G communication network and post office logistics network, islands and mountains nationwide
                  <br />
                  A drone delivery system exclusively for post offices that provides postal services,
                  technological development
                </td>
              </tr>
              <tr>
                <td>Ministry of Defense Service Tasks</td>
                <td>
                  In conjunction with the Defense Medical Information System,
                  <br />
                  Development of a Model for Estimating Required Materials for Compulsory Materials
                </td>
                <td>
                  2019 ~ 2020
                  <br />
                  (For 2 years)
                </td>
                <td>
                  [Research service]
                  <br />
                  Development of Demand Forecasting Model for Mandatory Materials and Required Calculation System for Supporting Mandatory Gunners
                </td>
              </tr>
              <tr>
                <td>Chungcheongnam-do Office - Service</td>
                <td>Address-based drone delivery test operation</td>
                <td>
                  2018 ~ 2019
                  <br />
                  (For 3 months)
                </td>
                <td>Address-based drone delivery service</td>
              </tr>
              <tr>
                <td>Nongjincheong Business</td>
                <td>
                  Through big data based on the life cycle of pets,
                  <br />
                  Development of Cloud-Based Medical Recommendation System
                </td>
                <td>
                  2018 ~ 2020
                  <br />
                  (3 years)
                </td>
                <td>
                  [Participating companies]
                  <br />
                  Building Intelligent Recommended Medical Systems Based on SaaS Cloud Computing
                </td>
              </tr>
              <tr>
                <td>ETRI - Service Tasks</td>
                <td>Development of a real-time monitoring system for diseases of the elderly</td>
                <td>
                  2018 ~ 2019
                  <br />
                  (For 2 years)
                </td>
                <td>Development of bio-signal monitoring system for diseases of the elderly</td>
              </tr>
              <tr>
                <td>NIPA Task</td>
                <td>
                  Through the Intelligence of Trade Customs Information
                  <br />
                  Development of automated analysis report generation services
                </td>
                <td>
                  2018 ~ 2019
                  <br />
                  (For 2 years)
                </td>
                <td>
                  [The host company]
                  <br />
                  Highly Reliable Import and Export Trade Information Intellectualization Service
                  <br />
                  Development of AI technology and big data information analysis platform
                </td>
              </tr>
              2149 / 5000발음듣기복사하기필기인식기번역하기자동완성자동완성
              영어열기/닫기 아이콘
              <tr>
                <td>Land Department Space Big Data</td>
                <td>
                  Batch of spatial big data
                  <br />
                  Development of analytical component technology
                </td>
                <td>
                  2017 ~ 2019
                  <br />
                  (3 years)
                </td>
                <td>
                  [Participating companies]
                  <br />
                  Low cost, high performance, scalable space for storing, managing, analyzing, and
                  for service delivery
                  <br />
                  Core technologies and spatial big data management, analysis, and services that implement them
                  Platform development
                </td>
              </tr>
              <tr>
                <td>
                  New Product Development Project with Purchase Conditions
                  <br />
                  Corporate Proposal Task for Domestic Demand Customers
                </td>
                <td>
                  Big Data-Driven Contact Center Live
                  <br />
                  Development of Customer Experience Analysis Appliances
                </td>
                <td>
                  2016 ~ 2018
                  <br />
                  (For 2 years)
                </td>
                <td>
                  [The host company]
                  <br /> Collect customer behavior and response data based on big data technology and
                  Quantify customer experience information.
                  <br />
                  To improve the responsiveness of contact center counselors and utilize marketing
                  Appliance technology development
                </td>
              </tr>
              <tr>
                <td>Railway Technology Research Project</td>
                <td>Development of fault diagnosis and prediction technology for railway vehicles</td>
                <td>
                  2015 ~ 2020
                  <br />
                  (6 years)
                </td>
                <td>
                  [Research service]
                  <br />
                  Development of State Data Management Technology and Development of Failure Prediction Technology for Railway Parts
                </td>
              </tr>
              <tr>
                <td>Future Department NIA Development Project</td>
                <td>
                  Big Data Integration Analysis
                  <br />
                  Development of Consumer Indicators for the People's Livelihood
                </td>
                <td>
                  2016
                  <br />
                  (For a year)
                </td>
                <td>
                  [Participating companies]
                  <br />
                  Development of a system for calculating and monitoring the consumption index of people's livelihood based on
                </td>
              </tr>
              <tr>
                <td>R&D Planning Support Project</td>
                <td>
                  MES-linked to improve manufacturing productivity
                  <br />
                  Development of Localization of Big Data Analysis Appliances
                </td>
                <td>
                  2014 ~ 2015
                  <br />
                  (For 2 years)
                </td>
                <td>
                  [The host company]
                  <br />
                  The structured unstructured data and sensor data generated by the MES
                  <br />
                  Develop big data appliances that can be collected and analyzed
                </td>
              </tr>
              <tr>
                <td>Industrial Convergence Source Technology Development Project</td>
                <td>
                  For business and collaborative management within the enterprise
                  <br />
                  Big Data-Driven Automated
                  <br />
                  Process Analysis Product Development
                </td>
                <td>
                  2013 ~ 2015
                  <br />
                  (For 2 years)
                </td>
                <td>
                  [Participating companies]
                  <br />
                  Develop big data-driven data analysis repository (Hadoop Echo-system)
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment >
  );
}

export default Client;
