import "view/style/css/main.css";
import React, { useState, useEffect } from "react";
import ReactFullpage from "@fullpage/react-fullpage";
import cx from "classnames";
import { Link } from "react-router-dom";
/*swiper*/
import { EffectFade, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
/*swiper*/

/*react Icons S*/
import * as Icons from "react-icons/vsc";
/*react Icons E*/
import { Footer } from "view/parts/footer/footer";

import axios from "axios";
import { useMutation } from "react-query";
import { localDatetimeRenderer, doEllipsis } from "view/utils";
import sanitize from "sanitize-html";
import { useNavigate } from "react-router-dom";

import classNames from "classnames";
import { Modal } from "view/parts/modal/modal";
import { Privacy } from "view/parts/main/privacy";

function Main() {
  const navigate = useNavigate();
  const [isFull, setFull] = useState(null);
  const [noticeList, setNoticeList] = useState(undefined);
  const afterLoad = (origin, destination, direction) => {};

  // const [swiper, setSwiper] = useState();
  const [isSw, setSw] = useState();
  const [open, setOpen] = useState(false);
  const [swiperIndex, setSwiperIndex] = useState(0);
  const [swiperLength, setSwiperLength] = useState(0);
  const [stopProgress, setStopProgress] = useState(false);
  const getNoticeList = async (req) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/serviceCenter/gaionNotice/${req.page}`,
      req
    );
    return response;
  };
  const mutation = useMutation({
    mutationFn: (req) => getNoticeList(req),
    onSuccess: (result) => {
      setNoticeList(result.data.value);
    },
  });

  useEffect(() => {
    mutation.mutate({ page: 0, value: "4" });
    return () => {
      window.fullpage_api.destroy();
    };
  }, []);

  const MainLoding = (e) => {
    e.autoplay?.stop();
    setStopProgress(true);

    setTimeout(() => {
      e.autoplay?.start();
      setStopProgress(false);
    }, 2000);
  };

  const SwiperActive = (e) => {
    setStopProgress(!stopProgress);
    if (stopProgress == true) {
      e.autoplay?.start();
      return;
    }
    if (stopProgress == false) {
      e.autoplay?.stop();
      return;
    }
  };

  return (
    <React.Fragment>
      <Modal
        className={classNames("modal_wrap", { on: open })}
        title={"개인정보 처리방침"}
        open={open}
        width={1400}
        setOpen={setOpen}
        body={<Privacy />}
      />
      <ReactFullpage
        controlsProps={{ className: "slide-navigation" }}
        licenseKey={process.env.REACT_APP_FULLPAGE_LICENSE}
        scrollingSpeed={1000} /* Options here */
        navigation={true}
        navigationPosition={"left"}
        navigationTooltips={[
          " INTRO",
          " STRENGTH",
          " BUSINESS",
          " PROCESS",
          " NOTICE",
        ]}
        showActiveTooltip={true}
        afterLoad={afterLoad}
        responsiveWidth={1000}
        scrollOverflow={true}
        render={({ state, fullpageApi }) => {
          setFull(window.fullpage_api);
          return (
            <React.Fragment>
              <ReactFullpage.Wrapper>
                <div className="section section01">
                  <Swiper
                    modules={[EffectFade, Autoplay]}
                    spaceBetween={0}
                    slidesPerView={1}
                    speed={0}
                    autoplay={{
                      delay: 5000,
                      disableOnInteraction: false,
                      pauseOnMouseEnter: false,
                    }}
                    onSwiper={(swiper) => {
                      MainLoding(swiper);
                      setSw(swiper);
                    }}
                    allowTouchMove={false}
                    loop={true}
                    onSlideChange={(sw) => {
                      setSwiperLength(sw.slides.length - 2);
                      setSwiperIndex(sw.realIndex);
                    }}
                    className="changeBg"
                  >
                    <SwiperSlide>
                      <video data-keepplaying autoPlay loop muted playsInline>
                        <source
                          src="/images/main/intro_1.mp4"
                          type="video/mp4"
                          className="video"
                        />
                      </video>
                      <div className="videoBg"></div>
                      <div className="mainArea">
                        <div className="_maintop">
                          <div className="_mainIcon">
                            <img src="/images/main/people_icon.gif" />
                            <div className="_mainIcon-circle"></div>
                          </div>
                          <hr />
                          <div className="_mainLogo">
                            <img src="/images/main/logo.png" />
                            <div className="_mainLogoAnimation1"></div>
                          </div>
                          <hr />
                          <div className="_mainIcon">
                            <img src="/images/main/ai_icon.gif" />
                            <div className="_mainIcon-circle"></div>
                          </div>
                        </div>

                        <div className="_mainBottom">
                          <div className="_mainBottom-title center-animation">
                            <span>Link</span> between people & AI
                          </div>
                          <div className="_mainBottom-content center-animation">
                            가이온은 사람과 AI 기술을 연결하고 있습니다.
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <video data-keepplaying autoPlay loop muted playsInline>
                        <source
                          src="/images/main/intro_2.mp4"
                          type="video/mp4"
                          className="video"
                        />
                      </video>
                      <div className="videoBg"></div>
                      <div className="wrap">
                        {/* content Area S */}
                        <div className="swiperTxt">
                          <div className="_swiper-title">
                            기술 혁신과의 <span>연결</span>,<br />
                            가이온과 함께 현실이 됩니다.
                          </div>
                          <hr />
                          <div className="_swiper-txt">
                            멀게만 느껴지는 AI, 빅데이터, 드론 등 첨단 기술을
                            <br />
                            기업과 니즈 맞춤형으로 만나보세요.
                          </div>
                          {/* arrow Area S */}
                          <div className="swiperBot">
                            <Link to="/company/company">
                              <div className="_swiperBot-text">자세히보기</div>
                              <div className="mainArrow">
                                <div className="_mainArw-bar"></div>
                                <div className="_mainArw-cicle">
                                  <div className="_mainArw-arrow"></div>
                                </div>
                              </div>
                            </Link>
                          </div>
                          {/* arrow Area E */}
                        </div>
                        {/* content Area E */}
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <video data-keepplaying autoPlay loop muted playsInline>
                        <source
                          src="/images/main/intro_3.mp4"
                          type="video/mp4"
                          className="video"
                        />
                      </video>
                      <div className="videoBg"></div>
                      <div
                        className="wrap"
                        style={{ position: "relative", zIndex: "3" }}
                      >
                        {/* content Area S */}
                        <div className="swiperTxt">
                          <div className="_swiper-title">
                            정부 사업과의 <span>연결</span>,<br />
                            믿을 수 있는 지원 사업 파트너
                          </div>
                          <hr />
                          <div className="_swiper-txt">
                            가이온은 신뢰성과 기술력을 인정받아 국가 데이터
                            바우처,
                            <br />
                            AI 바우처 사업의 공식 공급기관으로 4년 연속
                            선정되었습니다.
                          </div>
                          {/* arrow Area S */}
                          <div className="swiperBot">
                            <Link to="/company/company">
                              <div className="_swiperBot-text">자세히보기</div>
                              <div className="mainArrow">
                                <div className="_mainArw-bar"></div>
                                <div className="_mainArw-cicle">
                                  <div className="_mainArw-arrow"></div>
                                </div>
                              </div>
                            </Link>
                            <img
                              src="/images/companyLogo.png"
                              className="mNone"
                            />
                          </div>
                          {/* arrow Area E */}
                        </div>
                        {/* content Area E */}
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div
                        className="videoBg"
                        style={{
                          background:
                            'url("/images/main/intro_4.png") no-repeat center center',
                          backgroundSize: "cover",
                        }}
                      ></div>
                      <div className="mainArea">
                        <div
                          className="_mainBottom"
                          style={{ marginTop: "400px" }}
                        >
                          <div
                            className="_mainBottom-content center-animation"
                            style={{ textAlign: "center" }}
                          >
                            빅데이터 플랫폼과 연계하여 자동 티켓 분류 및 병합을
                            통해
                          </div>
                          <div
                            className="_mainBottom-content center-animation"
                            style={{ textAlign: "center" }}
                          >
                            이벤트 발생량을 획기적으로 절감시키는 보안 관제
                            고도화 솔루션 센트릭스(SentriX)가
                          </div>
                          <div
                            className="_mainBottom-content center-animation"
                            style={{ textAlign: "center" }}
                          >
                            마침내
                            <span style={{ fontSize: "40px" }}>
                              {" "}
                              " GS 인증 1등급 "{" "}
                            </span>
                            을 획득하였습니다.
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                  <div className="swiper-progress-container">
                    {new Array(swiperLength).fill(0).map((_, index) => (
                      <React.Fragment key={index}>
                        <div
                          className={cx("swiper-progress-circle", {
                            startAnimation: swiperIndex == index,
                            stop: stopProgress,
                          })}
                        >
                          <div
                            className={cx("swiper-progress-bg", {
                              startAnimation: swiperIndex == index,
                              stop: stopProgress,
                            })}
                          ></div>
                        </div>
                        <div
                          className={cx("swiper-progress", {
                            startAnimation: swiperIndex == index,
                            stop: stopProgress,
                          })}
                          key={index}
                        >
                          <div
                            className={cx("progress", {
                              startAnimation:
                                swiperIndex == index && stopProgress == false,
                              stop: stopProgress,
                              //restart: reStartProgress == true && swiperIndex == index
                            })}
                          ></div>
                        </div>
                        <div
                          className={cx("swiper-progress-num", {
                            startAnimation: swiperIndex == index,
                            stop: stopProgress == true,
                          })}
                        >
                          0{index + 1}
                        </div>
                      </React.Fragment>
                    ))}
                    <div
                      className="swiperPlay"
                      onClick={() => {
                        SwiperActive(isSw);
                      }}
                    >
                      {stopProgress == true && (
                        <React.Fragment>
                          <Icons.VscDebugStart />
                        </React.Fragment>
                      )}
                      {stopProgress == false && (
                        <React.Fragment>
                          <Icons.VscDebugPause />
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </div>
                <div className="section section02">
                  <div className="wrap">
                    <div className="mainTitle">
                      <div className="_main-title">
                        <span className="color">우수한 기술력</span>을 바탕으로
                        비즈니스 성과의 <span className="color">극대화</span>를
                        돕습니다.
                      </div>
                    </div>
                    <div className="grid two">
                      <div className="_grid-item">
                        <div className="_item-content">
                          <div className="_content-title color">
                            인정 받은 신뢰성
                          </div>
                          <div className="_content-hr"></div>
                          <div className="_content-text">
                            삼성, 아워홈, 우리은행 등 500개 이상의 고객사
                            <br />
                            전자통신연구원, Splunk, 신한카드 등 든든한 파트너사
                            <br />
                            국가 AI 및 빅데이터 바우처 공급기관
                          </div>
                        </div>
                        <div className="_item-icon">
                          <img src="/images/main/section_02_01.gif" />
                        </div>
                      </div>
                      <div className="_grid-item">
                        <div className="_item-content">
                          <div className="_content-title color">
                            뛰어난 전문성
                          </div>
                          <div className="_content-hr"></div>
                          <div className="_content-text">
                            11개의 자체개발 특허기술 보유
                            <br />
                            34개의 국가 특허기술 이전(ETRI)
                            <br />
                            100명 이상의 내부 AI 및 빅데이터 전문가
                          </div>
                        </div>
                        <div className="_item-icon">
                          <img src="/images/main/section_02_02.gif" />
                        </div>
                      </div>
                      <div className="_grid-item">
                        <div className="_item-content">
                          <div className="_content-title color">
                            풍부한 노하우
                          </div>
                          <div className="_content-hr"></div>
                          <div className="_content-text">
                            빅데이터, AI 등 기술 기업 중 최장 수준인 16년의 업력
                            <br />
                            글로벌 리딩 테크 기업과의 MOU 확장
                            <br />
                            제조, 금융, 디지털 마케팅, 보안 등 넓은 기술 적응력
                          </div>
                        </div>
                        <div className="_item-icon">
                          <img src="/images/main/section_02_03.gif" />
                        </div>
                      </div>
                      <div className="_grid-item">
                        <div className="_item-content">
                          <div className="_content-title color">
                            끝없는 도전
                          </div>
                          <div className="_content-hr"></div>
                          <div className="_content-text">
                            AI 기반의 보안 체계, 수출 지원, 드론 인프라 등<br />
                            사람을 이롭게 할 기술 및 사업영역 개척
                            <br />
                            100개 이상의 국가 프로젝트 참여 및 주도
                          </div>
                        </div>
                        <div className="_item-icon">
                          <img src="/images/main/section_02_04.gif" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bottom_logo">
                    <img src="images/main/companylogo.png" />
                  </div>
                  <div className="bg"></div>
                </div>
                <div className="section section03">
                  <div className="wrap">
                    <div className="mainTitle">
                      <div className="_main-title center">
                        대한민국 No.1 AI & 빅데이터 컨시어지(Concierge), 가이온
                      </div>
                      <div className="_main-content center">
                        기업에 맞춤화 된 빅데이터, AI, 드론 등 플랫폼과 솔루션을
                        통합해
                        <br />
                        효과적으로 제공할 수 있는 기업은 오직{" "}
                        <span className="colorGaion bold">가이온</span> 뿐
                        입니다.
                      </div>
                    </div>
                    <div className="grid four">
                      {/* Ai Platforms S */}
                      <div className="_grid-four-item">
                        <div className="_content-title">
                          AI
                          <br />
                          Platforms
                        </div>
                        <div className="_content-hr"></div>
                        <div className="_content-text">
                          AI 기반의 범용적
                          <br />
                          기술 환경 구축
                        </div>
                        {/* arrow Area S */}
                        <Link to="/platform/ai">
                          <div className="swiperBot">
                            <div className="_swiperBot-text">자세히보기</div>
                            <div className="mainArrow">
                              <div className="_mainArw-bar"></div>
                              <div className="_mainArw-cicle">
                                <div className="_mainArw-arrow"></div>
                              </div>
                            </div>
                          </div>
                        </Link>
                        {/* arrow Area E */}
                      </div>
                      {/* Ai Platforms E */}

                      {/* Ai Solutions S */}
                      <div className="_grid-four-item">
                        <div className="_content-title">
                          AI
                          <br />
                          Solutions
                        </div>
                        <div className="_content-hr"></div>
                        <div className="_content-text">
                          AI와 빅데이터를 활용한
                          <br />
                          최적의 솔루션 제공
                        </div>
                        {/* arrow Area S */}
                        <Link to="/bigdata/ads">
                          <div className="swiperBot">
                            <div className="_swiperBot-text">자세히보기</div>
                            <div className="mainArrow">
                              <div className="_mainArw-bar"></div>
                              <div className="_mainArw-cicle">
                                <div className="_mainArw-arrow"></div>
                              </div>
                            </div>
                          </div>
                        </Link>
                        {/* arrow Area E */}
                      </div>
                      {/* Ai Solutions E */}

                      {/* Ai trade S */}
                      <div className="_grid-four-item">
                        <div className="_content-title">
                          AI
                          <br />
                          Trade
                        </div>
                        <div className="_content-hr"></div>
                        <div className="_content-text">
                          AI와 빅데이터 기반의
                          <br />
                          중소기업 수출입 지원
                        </div>
                        {/* arrow Area S */}
                        <Link to="/weTrade/weTrade">
                          <div className="swiperBot">
                            <div className="_swiperBot-text">자세히보기</div>
                            <div className="mainArrow">
                              <div className="_mainArw-bar"></div>
                              <div className="_mainArw-cicle">
                                <div className="_mainArw-arrow"></div>
                              </div>
                            </div>
                          </div>
                        </Link>
                        {/* arrow Area E */}
                      </div>
                      {/* Ai trade E */}
                      {/* Ai Logistics S */}
                      <div className="_grid-four-item">
                        <div className="_content-title">
                          AI Logistics
                          <br />
                          &Mobility
                        </div>
                        <div className="_content-hr"></div>
                        <div className="_content-text">
                          효과적 드론 활용을 위한
                          <br />
                          AI 기반 기체 및 SW 개발
                        </div>
                        {/* arrow Area S */}
                        <Link to="/dron/dron">
                          <div className="swiperBot">
                            <div className="_swiperBot-text">자세히보기</div>
                            <div className="mainArrow">
                              <div className="_mainArw-bar"></div>
                              <div className="_mainArw-cicle">
                                <div className="_mainArw-arrow"></div>
                              </div>
                            </div>
                          </div>
                        </Link>
                        {/* arrow Area E */}
                      </div>
                      {/* Ai Logistics E */}
                    </div>
                  </div>
                  <div className="bg"></div>
                </div>
                <div className="section section04">
                  <div className="wrap">
                    <div className="mainTitle">
                      <div className="_main-title center">
                        시장 분석부터 개발, 활성화까지 완벽하게 이어지는
                        <br />
                        가이온만의{" "}
                        <span className="color">‘AI 기술 연결 서비스’</span>를
                        지금 바로 만나보세요.
                      </div>
                    </div>
                    <div className="grid special">
                      <div className="_special-item">
                        <div className="_special-area">
                          <div className="_item-btn">
                            <img src="/images/main/section_04_01.gif" />
                          </div>
                          <div className="_item-text">Analyze</div>
                        </div>
                        <div className="_item-hr">
                          <div className="hr"></div>
                        </div>
                        <div className="_special-area">
                          <div className="_item-btn">
                            <img src="/images/main/section_04_02.gif" />
                          </div>
                          <div className="_item-text">Design</div>
                        </div>
                        <div className="_item-hr">
                          <div className="hr"></div>
                        </div>
                        <div className="_special-area">
                          <div className="_item-btn">
                            <img src="/images/main/section_04_03.gif" />
                          </div>
                          <div className="_item-text">Develop</div>
                        </div>
                        <div className="_item-hr">
                          <div className="hr"></div>
                        </div>
                        <div className="_special-area">
                          <div className="_item-btn">
                            <img src="/images/main/section_04_04.gif" />
                          </div>
                          <div className="_item-text">Setup</div>
                        </div>
                        <div className="_item-hr">
                          <div className="hr"></div>
                        </div>
                        <div className="_special-area">
                          <div className="_item-btn">
                            <img src="/images/main/section_04_05.gif" />
                          </div>
                          <div className="_item-text">Follow-up</div>
                        </div>
                        <div className="_item-hr">
                          <div className="hr"></div>
                        </div>
                      </div>
                      <div className="_special-item">
                        <video
                          data-keepplaying
                          autoPlay
                          loop
                          muted
                          playsInline
                          style={{ width: "100%" }}
                        >
                          <source
                            src="/images/section_04_video.mp4"
                            type="video/mp4"
                          />
                        </video>
                      </div>
                    </div>
                  </div>
                  <div className="bg"></div>
                </div>
                <div className="section section05">
                  <div className="wrap">
                    <div className="mainTitle">
                      <div className="_main-title center">
                        지금 이 순간에도 가이온은
                        <br />
                        <span className="color">새로운 도전</span>과{" "}
                        <span className="color">혁신</span>을 향해 도약하고
                        있습니다.
                      </div>
                    </div>
                    <div className="grid four">
                      {noticeList &&
                        noticeList.content.map((element, index) => {
                          if (index < 4)
                            return (
                              <div className="_grid-four-item" key={index}>
                                <div className="_grid-four-top">
                                  <div className="_grid-four-type">공지</div>
                                  <div className="_grid-four-date">
                                    {localDatetimeRenderer(
                                      element.created_date,
                                      "dateFormat"
                                    )}
                                  </div>
                                </div>
                                <div className="_content-title">
                                  {doEllipsis(element.announcement_title, 30)}
                                </div>
                                <div className="_content-hr"></div>
                                <div className="_content-text">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: sanitize(
                                        element.announcement_content,
                                        {
                                          allowedTags: ["p"],
                                          allowedAttributes: false,
                                        }
                                      ),
                                    }}
                                  />
                                </div>
                                {/* arrow Area S */}
                                <div className="swiperBot">
                                  <div className="_swiperBot-text">
                                    자세히보기
                                  </div>
                                  <div
                                    className="mainArrow"
                                    onClick={() =>
                                      navigate(`/company/notice/detail`, {
                                        state: { item: element, index: 3 },
                                      })
                                    }
                                  >
                                    <div className="_mainArw-bar"></div>
                                    <div className="_mainArw-cicle">
                                      <div className="_mainArw-arrow"></div>
                                    </div>
                                  </div>
                                </div>
                                {/* arrow Area E */}
                              </div>
                            );
                        })}
                    </div>
                  </div>
                  <div className="bg"></div>
                </div>
                <div className="section footer fp-auto-height">
                  <Footer MainModal={setOpen} locale={"kr"} />
                </div>
              </ReactFullpage.Wrapper>
            </React.Fragment>
          );
        }}
      />
    </React.Fragment>
  );
}

export default Main;
